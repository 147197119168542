import { useEffect, useState } from "react";
import { Avatar, Card, Col, Divider, Layout, Row, Typography, Radio, Input } from "antd";
import { Content } from "antd/es/layout/layout";

import { PhoneTwoTone, MailTwoTone } from "@ant-design/icons";

const { Header, Sider } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;
const { Search } = Input;

const contactsDemoData = [
    {
        name: 'Jane Doe',
        email: 'janedoe@somecompany.com',
        phone: '555-555-5555',
        address: '123 Main St, Springfield, IL 62701',
        company: 'Some Company',
        title: 'Account Executive',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=1'
    },
    {
        name: 'John Doe',
        email: 'johndoe@anothercompany.com',
        phone: '425-651-8389',
        address: '456 Elm St, Seattle, WA 98101',
        company: 'Another Company',
        title: 'Cheif Marketing Officer',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=2'
    },
    {
        name: 'Charles Smith',
        email: 'charley@yoleda.com',
        phone: '232-679-1625',
        address: '789 Oak St, Portland, OR 97201',
        company: 'Yoleda',
        title: 'Chief Technology Officer',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=3'
    },
    {
        name: 'Jimmy Johnson',
        email: 'jimmyjohn@fugaru.com',
        phone: '784-667-3928',
        address: '101 Pine St, San Francisco, CA 94101',
        company: 'Fugaru',
        title: 'Cheif Financial Officer',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=4'
    },
    {
        name: 'Alex Johnson',
        email: 'alexjohnson@kloomda.net',
        phone: '123-456-7890',
        address: '555 Cedar St, Denver, CO 80201',
        company: 'Kloomda',
        title: 'Cheif Executive Officer',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=5'
    },
    {
        name: 'Shane Hayes',
        email: 'shane@hayesworks.io',
        phone: '987-654-3210',
        address: '222 Maple St, Boston, MA 02101',
        company: 'Hayes Works',
        title: 'Cheif Operating Officer',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=6'
    },
    {
        name: 'Ross Rodriguez',
        email: 'ross@homeservices.net',
        phone: '625-123-4567',
        address: '333 Walnut St, Miami, FL 33101',
        company: 'Home Services',
        title: 'Marketing Director',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=7'
    },
    {
        name: 'Craig Lee',
        email: 'lee@homeservices.net',
        phone: '625-123-4567',
        address: '333 Walnut St, Miami, FL 33101',
        company: 'Corpath',
        title: 'Marketing Lead',
        pic: 'https://api.dicebear.com/7.x/miniavs/svg?seed=8'
    },
];

const AddressBook = () => {
    const [contacts, setContacts] = useState(contactsDemoData);
    const [selectedContact, setSelectedContact] = useState(contactsDemoData[0]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setContacts(contactsDemoData);
    }, []);

    useEffect(() => {
        filterContacts();
    }, [searchTerm]);

    const filterContacts = () => {
        const filteredContacts = contactsDemoData.filter(contact => {
            return contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                contact.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
                contact.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
                contact.title.toLowerCase().includes(searchTerm.toLowerCase());
        });
        setContacts(filteredContacts);
    };

    const handleContactClick = (contact) => {
        setSelectedContact(contact);
    };

    return (
        <Layout style={{ height: '97vh' }}>
            <Header style={{ backgroundColor: 'white' }}>
                <Title level={1} style={{ color: 'black', margin: 0 }}>Address Book</Title>
            </Header>
            <Layout style={{ }}>
                <Content 
                    style={{ 
                        padding: '20px', 
                        flex: '2 1 0', 
                        overflowY: 'auto',
                        backgroundColor: 'white',
                    }}
                >
                    <>
                    <Title level={2}>My Contacts</Title>
                    <Search
                        placeholder="Search contacts"
                        style={{ marginBottom: 16}}
                        onSearch={searchTerm => setSearchTerm(searchTerm)}
                        onChange={e => setSearchTerm(e.target.value)}
                        enterButton
                    />
                    {contacts.map((contact, index) => (
                        <ContactItemListView 
                            key={index} 
                            contact={contact} 
                            index={index} 
                            onClick={() => handleContactClick(contact)} 
                        />
                    ))}
                    </>
                </Content>
                <Sider 
                    width={400}
                    style={{ 
                        backgroundColor: 'white', 
                        padding: '20px', 
                        flex: '1 1 600px' ,
                        width: '100%',
                    }} 
                >
                    <ContactItemSiderView contact={selectedContact} />
                </Sider>
            </Layout>
        </Layout>
    );
};

const ContactItemSiderView = ({ contact }) => {
    const [selectedSiderContent, setSelectedSiderContent] = useState('contact');

    const contactInfo = (
        <>
            <div>{contact.title}</div>
            <div style={{marginBottom: 8}}>@{contact.company}</div>
            <div><i>{contact.address}</i></div>
            <Divider />
            <div>
                <PhoneTwoTone style={{marginRight: 8}} />{contact.phone}
            </div>
            <div>
                <MailTwoTone style={{marginRight: 8}} />{contact.email}
            </div>
        </>
    );

    const notes = (
        <>
            <Text type='secondary'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Nullam nec purus vel nunc ultrices condimentum. 
                Nulla facilisi. Donec nec lacinia nibh. 
                Praesent nec semper mauris. 
                Nulla facilisi. Donec nec lacinia nibh. 
                Praesent nec semper mauris.
            </Text>
        </>
    );

    const contactHistory = (
        <>
        <Text type="secondary" style={{ whiteSpace: 'pre-wrap' }}>
            2021-01-01: Called to discuss new project{'\n'}
            2021-01-02: Sent follow-up email{'\n'}
            2021-01-03: Scheduled follow-up meeting{'\n'}
            2021-01-04: Follow-up meeting
        </Text>
        </>
    );

    const contentSelector = (
        <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
            <Radio.Group 
                style={{ width: '100%' }}
                defaultValue="contact" buttonStyle="solid" onChange={(e) => setSelectedSiderContent(e.target.value)}>
                <Radio.Button value="contact" style={{ width: '33%', textAlign: 'center' }}>
                    Contact
                </Radio.Button>
                <Radio.Button value="notes" style={{ width: '33%', textAlign: 'center'}}>
                    Notes
                </Radio.Button>
                <Radio.Button value="history" style={{ width: '33%', textAlign: 'center'}}>
                    History
                </Radio.Button>
            </Radio.Group>
        </div>
    );

    return (
        <Card
            hoverable
            cover={<img alt="example" src={contact.pic} />}
            style={{ 
                width: '100%',
                borderRadius: 10, 
                borderColor: 'lightgray', 
                borderWidth: 'thin',
             }}
        >
            {contentSelector}
            <Meta
                title={contact.name}
                description={selectedSiderContent === 'contact' ? contactInfo : selectedSiderContent === 'notes' ? notes : contactHistory}
            />
        </Card>
    );
};

const ContactItemListView = ({ contact, index, onClick }) => {
    return (
        <Card 
            hoverable
            size="small" 
            onClick={onClick}
            style={{ 
                marginBottom: 16, 
                width: '100%',
                borderRadius: 10, 
                borderColor: 'lightgray', 
                borderWidth: 'thin',
            }}
        >
            <Row gutter={16} align="middle">
                <Col flex="none">
                    <Avatar size="large" src={contact.pic}>
                        {contact.name.charAt(0)}
                    </Avatar>
                </Col>
                <Col flex="200px">
                    <Meta 
                        title={contact.name} 
                        description={
                            <>
                                <div>{contact.title}</div>
                                <div>@{contact.company}</div>
                            </>
                        } 
                    />
                </Col>
                <Col flex="none">
                    <div>
                        <Text>
                            <PhoneTwoTone style={{marginRight: 8}} />{contact.phone}
                        </Text>
                    </div>
                    <div>
                        <Text>
                            <MailTwoTone style={{marginRight: 8}} />{contact.email}
                        </Text>
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

export default AddressBook;