import { Button, Dropdown, Empty, Form, Input, Menu, Radio, Select, Switch, Typography } from "antd";
import { lightenColor } from "../../../app/utils";
import { useEffect, useState } from "react";
import TrackingCategoryMenu from "./TrackingCategoryMenu";
import TrackingSubcategoryMenu from "./TrackingSubcategoryMenu";

const { Title } = Typography;

const unitLabels = {
    times: 'Times',
    hours: 'Hours',
    minutes: 'Minutes',
};

const frequencyLabels = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
};

const ModifyTrackingItems = ({ loading, trackingItems, categories, categoryColors, highlightedTrackingItems, setHighlightedTrackingItems, onEdit }) => {
    const [editItem, setEditItem] = useState(null);
    const [toggleGoal, setToggleGoal] = useState(false);

    useEffect(() => {
        if(!trackingItems.length > 0 || loading) {
            return;
        }

        const lastIndex = highlightedTrackingItems.length - 1;
        if(highlightedTrackingItems.length > 1) {
            setHighlightedTrackingItems([highlightedTrackingItems[lastIndex]]);
        } else if(highlightedTrackingItems.length > 0) {
            const highlightedEditItem = trackingItems.find(item => item.id === highlightedTrackingItems[0]);
            if(!highlightedEditItem) {
                setEditItem(null);
                return;
            }

            setEditItem(highlightedEditItem);
            setToggleGoal(highlightedEditItem.goal !== null);
        } else {
            setEditItem(null);
        }
    }, [highlightedTrackingItems, trackingItems]);

    const handleEdit = () => {
        onEdit({
            ...editItem, 
            goal: toggleGoal ? String(editItem.goal) : null // convert goal to string if it's not null
        });
    };

    const handleToggleGoal = () => {
        if(toggleGoal) {
            setEditItem({...editItem, goal: null, goalUnit: null, goalFrequency: null});
        } else {
            setEditItem({...editItem, goal: '1', goalUnit: 'times', goalFrequency: 'weekly'});
        }
        setToggleGoal(!toggleGoal);
    };

    const categoryMenu = (
        <Menu>
            {categories.map(category => (
                <Menu.Item key={category.id}>
                    <a onClick={() => setEditItem({...editItem, categoryId: category.id})}>{category.name}</a>
                </Menu.Item>
            ))}
        </Menu>
    );

    const subcategoryMenu = (
        categories && editItem?.categoryId ? 
        <Menu>
            {categories.find(cat => cat.id === editItem.categoryId)?.subcategories.map(subcategory => (
                <Menu.Item key={subcategory}>
                    <a onClick={() => setEditItem({...editItem, subcategory})}>{subcategory}</a>
                </Menu.Item>
            ))}
        </Menu>
        : <Menu>
            <Menu.Item key="none">
                <a>None</a>
            </Menu.Item>
        </Menu>
    );

    const unitMenu = (
        <Menu>
            <Menu.Item key="times">
                <a onClick={() => setEditItem({...editItem, goalUnit: 'times'})}>Times</a>
            </Menu.Item>
            <Menu.Item key="hours">
                <a onClick={() => setEditItem({...editItem, goalUnit: 'hours'})}>Hours</a>
            </Menu.Item>
            <Menu.Item key="minutes">
                <a onClick={() => setEditItem({...editItem, goalUnit: 'minutes'})}>Minutes</a>
            </Menu.Item>
        </Menu>
    );

    const frequencyMenu = (
        <Menu>
            <Menu.Item key="daily">
                <a onClick={() => setEditItem({...editItem, goalFrequency: 'daily'})}>Daily</a>
            </Menu.Item>
            <Menu.Item key="weekly">
                <a onClick={() => setEditItem({...editItem, goalFrequency: 'weekly'})}>Weekly</a>
            </Menu.Item>
            <Menu.Item key="monthly">
                <a onClick={() => setEditItem({...editItem, goalFrequency: 'monthly'})}>Monthly</a>
            </Menu.Item>
        </Menu>
    );

    const editForm = (
        editItem ? 
        <Form layout="vertical" onFinish={handleEdit}>
            <Title level={4} style={{marginBottom: '20px', display: 'inline-block', width: '100%'}}>
                <span style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{flexGrow: '1', marginBottom: '0px'}}>Edit Tracking Item</p>
                    <Button type="primary" htmlType="submit" 
                        style={{
                            
                    }}>
                        Update
                    </Button>
                </span>
            </Title>
            <Form.Item label="Name" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
                <Input value={editItem.name} onChange={(e) => setEditItem({...editItem, name: e.target.value})} />
            </Form.Item>
            <div style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '15px' }}>
                <p>Set Goal</p>
                <Switch checked={toggleGoal} onChange={handleToggleGoal} />
            </div>
            { toggleGoal && 
                <div>
                    <Form.Item label="Goal" style={{ display: 'inline-block', width: '30%' }}>
                        <Input type="number" value={editItem.goal || 1} onChange={(e) => setEditItem({...editItem, goal: e.target.value})} />
                    </Form.Item>
                    <Form.Item label="Unit" style={{ display: 'inline-block', width: '30%' }}>
                        <Dropdown overlay={unitMenu} trigger={['click']}>
                            <Button>{unitLabels[editItem.goalUnit] || 'times'}</Button>
                        </Dropdown>
                    </Form.Item>
                    <Form.Item label="Frequency" style={{ display: 'inline-block', width: '30%'}}>
                        <Dropdown overlay={frequencyMenu} trigger={['click']}>
                            <Button>{frequencyLabels[editItem.goalFrequency] || 'weekly'}</Button>
                        </Dropdown>
                    </Form.Item>
                </div>
            }
            <Form.Item label="Category" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
                <TrackingCategoryMenu
                    categories={categories}
                    categoryColors={categoryColors}
                    selectedCategoryId={editItem.categoryId}
                    onSelect={(category) => setEditItem({...editItem, categoryId: category.id, subcategory: category.subcategories[0] || ''})}
                />
            </Form.Item>
            <Form.Item label="Subcategory" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
                <TrackingSubcategoryMenu
                    categories={categories}
                    categoryColors={categoryColors}
                    selectedCategoryId={editItem.categoryId}
                    selectedSubcategory={editItem.subcategory}
                    onSelect={(subcategory) => setEditItem({...editItem, subcategory})}
                />
            </Form.Item>
        </Form> : <Empty description="No item selected" />
    );

    const editFormHorizontal = (
        editItem && <>
        <Form layout="vertical" onFinish={() => handleEdit(editItem)} style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Form.Item label="Name" style={{ flex: '1' }}>
                <Input value={editItem.name} />
            </Form.Item>
            <Form.Item label="Goal" style={{ width: '60px' }}>
                {
                    editItem.type === 'boolean' ? (
                        <Select value={editItem.value} style={{ width: '100%' }}>
                            <Select.Option value={true}>True</Select.Option>
                            <Select.Option value={false}>False</Select.Option>
                        </Select>
                    ) : (
                        <Input type="number" value={editItem.value} style={{ width: '100%' }} />
                    )
                }
            </Form.Item>
            <Form.Item label="Unit" style={{ flex: '.5' }}>
                <Input value={editItem.unit} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Frequency" style={{ flex: '.5' }}>
                <Dropdown overlay={frequencyMenu} trigger={['click']}>
                    <Button style={{ width: '100%' }}>{editItem.goalFrequency}</Button>
                </Dropdown>
            </Form.Item>
            <Form.Item label="Category" style={{ flex: '.8' }}>
                <Dropdown overlay={categoryMenu} trigger={['click']}>
                    <Button style={{width: '100%', backgroundColor: categoryColors[editItem.category]}}>{editItem.category}</Button>
                </Dropdown>
            </Form.Item>
            <Form.Item label="Subcategory" style={{ flex: '.8' }}>
                <Dropdown overlay={subcategoryMenu} trigger={['click']}>
                    <Button style={{ width: '100%', backgroundColor: lightenColor(categoryColors[editItem.category]) }}>{editItem.subcategory}</Button>
                </Dropdown>
            </Form.Item>
            <Form.Item style={{ flex: '0 0 auto', alignSelf: 'flex-end' }}>
                <Button type="primary" htmlType="submit">Edit</Button>
            </Form.Item>
        </Form>
        </>
    );
    
    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <Title level={4}>Modify Tracking Item</Title>
            <div style={{
                border: '2px solid grey',
                backgroundColor: lightenColor('#f0f0f0', 3),
                padding: '16px',
                paddingBottom: '0px',
                borderRadius: '8px',
                marginBottom: '16px',
                height: '100%',
                flexGrowth: '1',
            }}>
                {editForm}
            </div>
        </div>
    )
};

export default ModifyTrackingItems;