import { Button, Dropdown, Menu } from "antd";
import { menuItemStyle as defaultStyle } from "../../../app/utils";
    
const TrackingCategoryMenu = ({ categories, categoryColors, selectedCategoryId, onSelect, menuItemStyle = defaultStyle }) => {
    const menu = (
        <Menu>
            {categories.map(category => (
                <Menu.Item key={category.id} style={menuItemStyle(categoryColors[category.id])}>
                    <a onClick={() => onSelect(category)}>
                        {category.name}
                    </a>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button
                style={selectedCategoryId ? menuItemStyle(categoryColors[selectedCategoryId]) : {}}
            >
                {categories.find(cat => cat.id === selectedCategoryId)?.name || 'Category'}
            </Button>
        </Dropdown>
    );
};

export default TrackingCategoryMenu;