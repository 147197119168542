import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Spin, Button, Tooltip as AntdTooltip, Checkbox } from 'antd';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend } from 'recharts';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const { Title } = Typography;

const WeeklyDashboard = ({ sleepData, activityData, taskData }) => {
  const [loading, setLoading] = useState(true);
  const [weeklyData, setWeeklyData] = useState([]);
  const [showWeekends, setShowWeekends] = useState(true);
  const [taskTypeFilters, setTaskTypeFilters] = useState({
    admin: true,
    meeting: true,
    recharge: true,
    other: true,
  });

  useEffect(() => {
    if (sleepData.length && activityData.length && taskData.length) {
      processWeeklyData(sleepData, activityData, taskData);
      setLoading(false);
    }
  }, [sleepData, activityData, taskData]);

  const taskTypeColors = {
    recharge: '#d4edda', // green for recharge
    meeting: '#cce5ff', // blue for meetings
    admin: '#fff3cd', // yellow for admin tasks
    other: '#f8f9fa', // neutral for other
  };  

  const formatSleepDuration = (hours) => {
    const totalMinutes = hours * 60;
    const hoursValue = Math.floor(totalMinutes / 60);
    const minutesValue = totalMinutes % 60;
    return `${hoursValue}h ${minutesValue.toFixed(0)}m`;
  };

  const normalizeValue = (value, max, min = 0, scale = 1) => {
    return (value - min) / (max - min) * 100 * scale;
  };

  const processWeeklyData = (sleepData, activityData, taskData) => {
    const combinedData = [];
    const maxSleep = Math.max(...sleepData.map(d => d.totalSleep || 0));
    const minSleep = Math.min(...sleepData.map(d => d.totalSleep || 0));
    const maxSteps = Math.max(...activityData.map(d => d.totalSteps || 0));
    const minSteps = Math.min(...activityData.map(d => d.totalSteps || 0));
    const maxCalories = Math.max(...activityData.map(d => d.totalCalories || 0));
    const minCalories = Math.min(...activityData.map(d => d.totalCalories || 0));
    const maxTasks = 10; // Setting a common max value for tasks and meetings to normalize other metrics
    const minTasks = 0;

    // Scaling factors to help insights stand out
    const sleepFactor = 1.75;
    const stepsFactor = 1.5;
    const caloriesFactor = 1.25;

    console.log(`Max and Min Values:`);
    console.log(`Sleep: max=${maxSleep}, min=${minSleep}`);
    console.log(`Steps: max=${maxSteps}, min=${minSteps}`);
    console.log(`Calories: max=${maxCalories}, min=${minCalories}`);
    console.log(`Tasks: max=${maxTasks}, min=${minTasks}`);

    for (let i = 0; i < 7; i++) {
      const date = dayjs().subtract(1, 'week').startOf('week').add(i, 'day').add(1, 'day'); // Start week on Monday
      const dayOfWeek = date.format('dddd');

      const sleep = sleepData.find(d => dayjs(d.date).isSame(date, 'day')) || {};
      const activity = activityData.find(d => dayjs(d.date).isSame(date, 'day')) || {};
      const tasks = taskData.filter(t => dayjs(t.startTime).isSame(date, 'day'));

      const completedTasks = tasks.filter(t => t.completed).length;
      const meetings = tasks.filter(t => t.type === 'meeting').length;

      combinedData.push({
        dayOfWeek,
        date: date.format('YYYY-MM-DD'),
        totalSleep: normalizeValue(sleep.totalSleep || 0, maxSleep, minSleep, sleepFactor) * (maxTasks / 100),
        totalSteps: normalizeValue(activity.totalSteps || 0, maxSteps, minSteps, stepsFactor) * (maxTasks / 100),
        totalCalories: normalizeValue(activity.totalCalories || 0, maxCalories, minCalories, caloriesFactor) * (maxTasks / 100),
        tasks,
        completedTasks,
        incompleteTasks: tasks.length - completedTasks,
        meetings,
        rawTotalSleep: sleep.totalSleep || 0,
        rawTotalSteps: activity.totalSteps || 0,
        rawTotalCalories: activity.totalCalories || 0,
        rawCompletedTasks: completedTasks,
        rawMeetings: meetings,
      });
    }

    setWeeklyData(combinedData);
  };

  const getBackgroundColor = (task) => {
    if (task.type === 'admin') {
      return task.completed ? '#d4edda' : '#f8d7da';
    }
    if (task.type === 'meeting') {
      return '#cce5ff';
    }
    if (task.type === 'recharge') {
      return '#fff3cd';
    }
    return '#f8f9fa'; // Neutral, default color
  };
  

  const filteredData = showWeekends ? weeklyData : weeklyData.slice(0, 5); // Exclude Saturday and Sunday if showWeekends is false

  const filteredTasks = (tasks) => {
    return tasks.filter(task => taskTypeFilters[task.type]);
  };

  return (
    <div style={{padding: '20px'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={2}>Weekly Review Dashboard</Title>
        <Button type={showWeekends ? 'primary' : 'default'} onClick={() => setShowWeekends(prev => !prev)}>
          {showWeekends ? 'Hide Weekends' : 'Show Weekends'}
        </Button>
      </div>
      {loading ? (
        <Spin />
      ) : (
        <>
          {/* Graph */}
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={24}>
              <Card title="Weekly Overview">
                <div style={{ width: '100%', height: 350 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={filteredData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="dayOfWeek" />
                      <YAxis label={{ value: 'Productivity & Wellness (normalized)', angle: -90, position: 'insideLeftBottom' }} />
                      <RechartsTooltip 
                        formatter={(value, name, entry) => {
                            if (name === "Total Sleep (hours)") {
                              return [formatSleepDuration(entry.payload.rawTotalSleep), name];
                            }
                            if (name === "Tasks Completed") {
                              return [entry.payload.rawCompletedTasks, name];
                            }
                            if (name === "Incomplete") {
                              return [entry.payload.incompleteTasks, name];
                            }
                            if (name === "Total Steps") {
                                // format numbers to show commas if large
                                return [entry.payload.rawTotalSteps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), name];
                            }
                            if (name === "Total Calories") {
                                // format numbers to show commas if large
                                return [entry.payload.rawTotalCalories.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), name];
                            }
                            const rawValue = entry.payload[`raw${name.replace(/ /g, '')}`];
                            return [rawValue, name];
                        }}
                        />
                      <Legend />
                      <Line type="monotone" name="Total Sleep (hours)" dataKey="totalSleep" stroke="#8884d8" />
                      <Line type="monotone" name="Total Steps" dataKey="totalSteps" stroke="#ffa500" />
                      <Line type="monotone" name="Total Calories" dataKey="totalCalories" stroke="#ffc658" />
                      <Line type="monotone" name="Tasks Completed" dataKey="completedTasks" stroke="#28a745" />
                      <Line type="monotone" name="Incomplete" dataKey="incompleteTasks" stroke="#dc3545" />
                      <Line type="monotone" name="Meetings" dataKey="meetings" stroke="#007bff" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Task Type Filters */}
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            margin: '5px',
            padding: '10px', 
            border: '1px solid #d9d9d9', 
            borderRadius: '8px',
            backgroundColor: '#f5f5f5',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
            <span style={{ marginRight: '10px', fontWeight: 'bold' }}>Filter by task type:</span>
            <Checkbox.Group
                options={[
                {
                    label: (
                    <span style={{
                        display: 'inline-block',
                        padding: '5px 10px',
                        backgroundColor: taskTypeColors.admin,
                        borderRadius: '4px',
                        color: '#000',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}>
                        Admin
                    </span>
                    ),
                    value: 'admin'
                },
                {
                    label: (
                    <span style={{
                        display: 'inline-block',
                        padding: '5px 10px',
                        backgroundColor: taskTypeColors.meeting,
                        borderRadius: '4px',
                        color: '#000',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}>
                        Meeting
                    </span>
                    ),
                    value: 'meeting'
                },
                {
                    label: (
                    <span style={{
                        display: 'inline-block',
                        padding: '5px 10px',
                        backgroundColor: taskTypeColors.recharge,
                        borderRadius: '4px',
                        color: '#000',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}>
                        Recharge
                    </span>
                    ),
                    value: 'recharge'
                },
                {
                    label: (
                    <span style={{
                        display: 'inline-block',
                        padding: '5px 10px',
                        backgroundColor: taskTypeColors.other,
                        borderRadius: '4px',
                        color: '#000',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}>
                        Other
                    </span>
                    ),
                    value: 'other'
                },
                ]}
                value={Object.keys(taskTypeFilters).filter(key => taskTypeFilters[key])}
                onChange={checkedValues => {
                const newFilters = { ...taskTypeFilters };
                Object.keys(newFilters).forEach(key => {
                    newFilters[key] = checkedValues.includes(key);
                });
                setTaskTypeFilters(newFilters);
                }}
            />
        </div>


          {/* Task List */}
          <Row gutter={16} style={{ marginTop: 16, display: 'flex', flexWrap: 'nowrap' }}>
            {filteredData.map(day => (
              <Col key={day.date} style={{ flex: '1 1 0', minWidth: '14.28%' }}> {/* Ensure each column is equal */}
                <Card title={day.dayOfWeek} style={{ height: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {filteredTasks(day.tasks).map(task => (
                        <AntdTooltip title={task.title} key={task.id}>
                            <div
                                key={task.id}
                                style={{
                                flex: '0 0 auto',
                                margin: '2px',
                                padding: '3px',
                                background: task.completed === true ? taskTypeColors[task.type] : '#f8d7da',
                                borderRadius: '4px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                boxSizing: 'border-box', // Ensure padding and border are included in the width
                                width: '100%', // Ensure the task card takes full width of its parent column
                                }}
                            >
                                {task.title}
                            </div>
                      </AntdTooltip>
                    ))}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

WeeklyDashboard.propTypes = {
  sleepData: PropTypes.array.isRequired,
  activityData: PropTypes.array.isRequired,
  taskData: PropTypes.array.isRequired,
};

export default WeeklyDashboard;
