import { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, DatePicker, Row, Col } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const EventModal = ({
  isVisible,
  onCancel,
  onSubmit,
  event,
  isEditMode = false,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');

  useEffect(() => {
    if (isEditMode && event) {
      form.setFieldsValue({
        title: event?.title,
        description: event?.description,
        location: event?.location,
        date: event?.date ? dayjs(event.date) : null,
        startTime: event?.start ? dayjs(event.start) : null,
        endTime: event?.end ? dayjs(event.end) : null,
        link: event?.meetingLink,
      });
    } else {
      form.resetFields();
    }
  }, [isEditMode, event, form]);

  useEffect(() => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const match = form.getFieldValue('link')?.match(urlRegex);
    if (match) {
      setLink(match[0]);
    } else {
      setLink('');
    }
  }, [form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Convert dates to a string in the expected format if they exist
      if (values.date) {
        values.date = dayjs(values.date).format('YYYY-MM-DD');
      }
      if (values.startTime) {
        values.startTime = dayjs(values.startTime).format('HH:mm');
      }
      if (values.endTime) {
        values.endTime = dayjs(values.endTime).format('HH:mm');
      }
      await onSubmit(isEditMode ? { ...event, ...values } : values);
      form.resetFields();
      onCancel();
    } catch (error) {
      console.error(`Error ${isEditMode ? 'updating' : 'creating'} the event:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleJoinMeeting = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <Modal
      title={isEditMode ? 'Edit Event' : 'Create New Event'}
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        name={isEditMode ? 'editEventForm' : 'createEventForm'}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[40, 16]}>
          <Col span={24}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter the title!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label="Description">
              <Input.TextArea style={{ height: '135px' }} onChange={() => form.validateFields(['description'])} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="location"
              label="location"
              rules={[{ required: false, message: 'Please enter the location!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[{ required: true, message: 'Please select the start time!' }]}
            >
              <DatePicker
                format="(ddd) MMMM Do, h:mm A"
                showTime={{ format: 'hh:mm A' }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[{ required: true, message: 'Please select the end time!' }]}
            >
              <DatePicker
                format="(ddd) MMMM Do, h:mm A"
                showTime={{ format: 'hh:mm A' }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="link" label="Meeting Link">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {isEditMode ? 'Save' : 'Create Event'}
            </Button>
            {link && (
              <Button type="link" onClick={handleJoinMeeting} style={{ marginLeft: 10 }}>
                Join Meeting
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EventModal;
