import { createContext, useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { DataStore } from 'aws-amplify';
import { DailyTrackingEntry, TrackingCategory, TrackingItem } from '../../../models';
import { demoTrackingItems, demoTrackingCategories, fetchDailyTrackingEntries } from '../mocks/demoTrackingItems';

const TrackingContext = createContext();

export const TrackingProvider = ({ children }) => {
    const { userId } = useSelector((state) => state.app);
    const [trackingItems, setTrackingItems] = useState([]);
    const [trackingCategories, setTrackingCategories] = useState([]);
    const [trackingEntries, setTrackingEntries] = useState([]);
    const [todaysTrackingEntry, setTodaysTrackingEntry] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isDemo, setIsDemo] = useState(true);

    const generateIdForDemo = (length = 8) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const trackingSubcategories = trackingCategories.reduce((acc, category) => {
        return [...acc, ...category.subcategories];
    }, []);

    const categoryIdToColorMap = trackingCategories.reduce((acc, category) => {
        acc[category.id] = category.color;
        return acc;
    }, {});

    const categoryIdToSubcategoryMap = trackingCategories.reduce((acc, category) => {
        acc[category.id] = category.subcategories;
        return acc;
    }, {});

    const subcategoryToCategoryMap = trackingCategories.reduce((acc, category) => {
        category.subcategories.forEach(subcategory => {
            acc[subcategory] = category;
        });
        return acc;
    }, {});

    useEffect(() => {
        const fetchData = async () => {
            await fetchTrackingItems();
            await fetchTrackingCategories();

            const today = new Date().toISOString();
            const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
            await fetchTrackingEntries(lastMonth, today);
        };
        fetchData();
    }, [userId, isDemo]);

    const execute = async (fn, errorPrefix) => {
        try {
            setLoading(true);
            await fn();
        } catch (err) {
            setError(err);
            console.error(`${errorPrefix}:`, err);
        } finally {
            setLoading(false);
        }
    };

    /****************************************************************************************************
     ___                   
    |_ _| |_ ___ _ __ ___  ___ 
    | || __/ _ \ '_ ` _ \/ __|
    | || ||  __/ | | | | \__ \
    |___|\__\___|_| |_| |_|___/
     ****************************************************************************************************/

    const fetchTrackingItems = async () => {
        if(isDemo || !userId) {
            execute(() => setTrackingItems(demoTrackingItems), 'Error fetching demo tracking items');
            console.log('demoTrackingItems', demoTrackingItems);
            return;
        } 

        const fetchItems = async () => {
            const loadedItems = await DataStore.query(TrackingItem, (c) => c.userId.eq(userId));
            setTrackingItems(loadedItems);
            console.log('loadedItems', loadedItems);
        };

        await execute(fetchItems, 'Error fetching tracking items');
    };

    const addTrackingItem = async (item) => {
        if(isDemo || !userId) {
            const updatedItems = [...trackingItems, { ...item, userId, id: generateIdForDemo() }];
            execute(() => setTrackingItems(updatedItems), 'Error adding demo tracking item');
            return;
        }

        const addItem = async (item) => {
            await DataStore.save(new TrackingItem(
                { ...item, userId, lastLogged: (new Date()).toISOString()}));
            
            // Update today's tracking entry to include the new item
            if (todaysTrackingEntry) {
                console.log('todaysTrackingEntry', todaysTrackingEntry);
                const updatedItems = [...todaysTrackingEntry.items, { trackingItemId: item.id, value: '0' }];
                console.log('updating todaysTrackingEntry', updatedItems);
                await saveTrackingEntry({ ...todaysTrackingEntry, items: updatedItems });
            }

            // Fetch updated items after the save to get the new item id
            await fetchTrackingItems();
        };

        await execute(() => addItem(item), 'Error adding tracking item');
    };

    const updateTrackingItem = async (item) => {
        if(isDemo) {
            const updatedItems = trackingItems.map(i => {
                if (i.id === item.id) {
                    return item;
                }
                return i;
            });
            execute(() => setTrackingItems(updatedItems), 'Error updating demo tracking item');
            return;
        }

        const updateItem = async (item) => {
            // Get fresh item to avoid stale data issue in saves
            const originalItem = await DataStore.query(TrackingItem, item.id);
            if (!originalItem) {
                throw new Error(`Item with id ${item.id} not found`);
            }

            // Use the copyOf method to create an updated instance
            await DataStore.save(
                TrackingItem.copyOf(originalItem, (updated) => {
                    Object.assign(updated, item);
                    updated.goal = item.goal ? item.goal+'' : null; // Convert goal to string
                    updated.id = originalItem.id;
                    updated.userId = originalItem.userId;
                    updated.createdAt = originalItem.createdAt;
                    updated.updatedAt = null; // Set updatedAt to null to create a new revision
                })
            );

            // Fetch updated items after the save
            await fetchTrackingItems();
        };

        await execute(() => updateItem(item), 'Error updating tracking item');
    };

    const deleteTrackingItem = async (item) => {
        if(isDemo) {
            const updatedItems = trackingItems.filter(i => i.id !== item.id);
            execute(() => setTrackingItems(updatedItems), 'Error deleting demo tracking item');
            return;
        }

        const deleteItem = async (item) => {
            console.log('deleting item', item);
            await DataStore.delete(item);
            await fetchTrackingItems();

            // Update today's tracking entry to remove the new item
            if (todaysTrackingEntry) {
                const updatedItems = [...todaysTrackingEntry?.items].filter(i => i.trackingItemId !== item.id);
                console.log('updating todaysTrackingEntry', updatedItems);
                await saveTrackingEntry({ ...todaysTrackingEntry, items: updatedItems });
            }
        };

        await execute(() => deleteItem(item), 'Error deleting tracking item');
    };

    /****************************************************************************************************
     ____      _                        _           
    / ___|__ _| |_ ___  __ _  ___  _ __(_) ___  ___ 
    | |   / _` | __/ _ \/ _` |/ _ \| '__| |/ _ \/ __|
    | |__| (_| | ||  __/ (_| | (_) | |  | |  __/\__ \
    \____\__,_|\__\___|\__, |\___/|_|  |_|\___||___/
                        |___/                        
    ****************************************************************************************************/

    const fetchTrackingCategories = async () => {
        if(isDemo || !userId) {
            execute(() => setTrackingCategories(demoTrackingCategories), 'Error fetching demo tracking categories');
            console.log('demoTrackingCategories', demoTrackingCategories);
            return;
        }

        const fetchCategories = async () => {
            const loadedCategories = await DataStore.query(TrackingCategory, (c) => c.userId.eq(userId));
            setTrackingCategories(loadedCategories);
            console.log('loadedCategories', loadedCategories);
        };

        await execute(fetchCategories, 'Error fetching tracking categories');
    };

    const addTrackingCategory = async (categoryName, color, subcategories = []) => {
        if(isDemo || !userId) {
            const updatedCategories = [...trackingCategories,
                {
                    id: generateIdForDemo(),
                    userId: 'demo',
                    name: categoryName,
                    color,
                    subcategories
                }
            ];
            execute(() => setTrackingCategories(updatedCategories), 'Error adding demo tracking category');
            return;
        }

        const addCategory = async () => {
            await DataStore.save(new TrackingCategory({ 
                name: categoryName, 
                userId,
                color,
                subcategories
            }));
            await fetchTrackingCategories();
        };

        await execute(addCategory, 'Error adding tracking category');
    };

    const updateTrackingCategory = async (category) => {
        if(isDemo) {
            const updatedCategories = trackingCategories.map(c => {
                if (c.id === category.id) {
                    return category;
                }
                return c;
            });
            execute(() => setTrackingCategories(updatedCategories), 'Error updating demo tracking category');
            return;
        }

        const updateCategory = async () => {
            // Get fresh category to avoid stale data issue in saves
            const originalCategory = await DataStore.query(TrackingCategory, category.id);
            if (!originalCategory) {
                throw new Error(`Category with id ${category.id} not found`);
            }

            // Use the copyOf method to create an updated instance
            await DataStore.save(
                TrackingCategory.copyOf(originalCategory, (updated) => {
                    Object.assign(updated, category);
                    updated.id = originalCategory.id;
                    updated.userId = originalCategory.userId;
                    updated.createdAt = originalCategory.createdAt;
                    updated.updatedAt = null; // Set updatedAt to null to create a new revision
                })
            );

            // Fetch updated categories after the save
            await fetchTrackingCategories();
        }

        await execute(updateCategory, 'Error updating tracking category');
    };

    const deleteTrackingCategory = async (category) => {
        if(isDemo) {
            const updatedCategories = trackingCategories.filter(c => c.id !== category.id);
            execute(() => setTrackingCategories(updatedCategories), 'Error deleting demo tracking category');
            return;
        }

        const deleteCategory = async () => {
            await DataStore.delete(category);
            await fetchTrackingCategories();
        };

        await execute(deleteCategory, 'Error deleting tracking category');
    };

    const addTrackingSubcategory = async (categoryId, subcategory) => {
        // Convert categoryId to string if it's not already
        const categoryIdString = String(categoryId);

        if(isDemo) {
            const updatedCategories = trackingCategories.map(category => {
                if (category.id === categoryIdString) {
                    return { ...category, subcategories: [...category.subcategories, subcategory] };
                }
                return category;
            });
            execute(() => setTrackingCategories(updatedCategories), 'Error adding demo tracking subcategory');
            return;
        }

        const addSubcategory = async () => {
            // Get fresh category to avoid stale data issue in saves
            const category = await DataStore.query(TrackingCategory, categoryIdString);
            if (!category) {
                throw new Error(`Category with id ${categoryIdString} not found`);
            }
        
            await DataStore.save(
                TrackingCategory.copyOf(category, (updated) => {
                    updated.subcategories = [...category.subcategories, subcategory];
                })
            );
            await fetchTrackingCategories();
        };

        await execute(addSubcategory, 'Error adding tracking subcategory');
    }

    const deleteTrackingSubcategory = async (categoryId, subcategory) => {
        if(isDemo) {
            const updatedCategories = trackingCategories.map(category => {
                if (category.id === categoryId) {
                    return { ...category, subcategories: category.subcategories.filter(s => s !== subcategory) };
                }
                return category;
            });
            execute(() => setTrackingCategories(updatedCategories), 'Error deleting demo tracking subcategory');
            return;
        }

        const deleteSubcategory = async () => {
            const category = await DataStore.query(TrackingCategory, categoryId);
            if (!category) {
                throw new Error(`Category with id ${categoryId} not found`);
            }
        
            // Use the copyOf method correctly to create an updated instance
            await DataStore.save(
                TrackingCategory.copyOf(category, (updated) => {
                    updated.subcategories = category.subcategories.filter(s => s !== subcategory);
                })
            );
        
            // Fetch updated categories after the save
            await fetchTrackingCategories();
        };

        await execute(deleteSubcategory, 'Error deleting tracking subcategory');
    }

    /****************************************************************************************************
     _____       _        _           
    | ____|_ __ | |_ _ __(_) ___  ___ 
    |  _| | '_ \| __| '__| |/ _ \/ __|
    | |___| | | | |_| |  | |  __/\__ \
    |_____|_| |_|\__|_|  |_|\___||___/
    ****************************************************************************************************/

    const fetchTrackingEntries = async (start, end) => {
        if(isDemo || !userId) {
            execute(() => {
                const generatedEntries = fetchDailyTrackingEntries(start, end);
                console.log('demoTrackingEntries', generatedEntries);
                setTrackingEntries(generatedEntries);
            });
            return;
        }

        const loadEntries = async (start, end) => {
            const loadedEntries = await DataStore.query(DailyTrackingEntry, 
                (entry) => entry.and(entry => [
                    entry.userId.eq(userId),
                    entry.date.between(start, end),
                ])
            );
            console.log('loadedEntries', loadedEntries);
            // Transform the data
            const transformedEntries = loadedEntries.map(entry => entry.items);

            setTrackingEntries(transformedEntries);
            // setTrackingEntries(loadedEntries[it] || []);
    
            // Set today's entry if it exists
            const today = new Date().toISOString().split('T')[0];
            let todayEntry = loadedEntries.find(entry => entry.date.startsWith(today));
            if(todayEntry) {
                setTodaysTrackingEntry(todayEntry);
            }
        };
    
        await execute(() => loadEntries(start, end), 'Error loading tracking entries');
    };  

    const loadEntries = async (start, end) => {
        // First, get the tracking items map
        const trackingItemsMap = await getTrackingItemsMap();

        // Query the DataStore and transform the data in one go
        const transformedEntries = await DataStore.query(DailyTrackingEntry, 
            (entry) => entry.and(entry => [
                entry.userId.eq(userId),
                entry.date.between(start, end),
            ])
        ).then(entries => {
            return entries.reduce((acc, entry) => {
                const dateKey = new Date(entry.date).toISOString().split('T')[0];
                acc[dateKey] = entry.items.map(item => ({
                    trackingItemId: item.trackingItemId,
                    name: trackingItemsMap[item.trackingItemId] || 'Unknown Item',
                    value: parseInt(item.value, 10)
                }));
                return acc;
            }, {});
        });

        return transformedEntries;
    };

    const getTrackingItemsMap = async () => {
        try {
            // Query all tracking items from the DataStore
            const trackingItems = await DataStore.query(TrackingItem);

            // Create a map of trackingItemId to name
            const trackingItemsMap = trackingItems.reduce((acc, item) => {
                acc[item.id] = item.name;
                return acc;
            }, {});

            return trackingItemsMap;
        } catch (error) {
            console.error('Error fetching tracking items:', error);
            return {}; // Return an empty object in case of error
        }
    };

    const saveTrackingEntry = async (entry) => {
        if(isDemo || !userId) {
            execute(() => setTrackingEntries(trackingEntries.map(e => (e.id === entry.id ? entry : e))
                ,'Error saving demo tracking entry'));
            return;
        }

        const saveEntry = async (entry) => {
            // Check if a DailyTrackingEntry already exists for this day and the current user
            const entryDate = entry.date?.split('T')[0]; // Format as YYYY-MM-DD
            console.log(`saveTrackingEntry for ${entryDate}`, entry);
            const existingEntries = await DataStore.query(DailyTrackingEntry, (entry) =>
                entry.and(entry => [
                    entry.userId.eq(userId),
                    entry.date.beginsWith(entryDate),
                ])
            );

            console.log(`existingEntries for ${entryDate}`, existingEntries);
    
            // This should not happen...
            if(existingEntries.length > 1) {
                console.warn(`Multiple entries found for ${entryDate}: `, existingEntries);
            }

            if (existingEntries.length > 0) {
                // If an entry exists, update it
                const original = await DataStore.query(DailyTrackingEntry, existingEntries[0].id);
                await DataStore.save(
                    DailyTrackingEntry.copyOf(original, (updated) => {
                        Object.assign(updated, entry);
                        updated.trackingItemId =uuidv4();
                        updated.id = original.id;
                        updated.userId = original.userId;
                        updated.createdAt = original.createdAt;
                        updated.updatedAt = null; // Reset updatedAt for a new revision
                    })
                );
                setTrackingEntries(trackingEntries.map(e => (e.id === entry.id ? entry : e)));
            } else {
                // If no entry exists, create a new one
                const trackingItemId = uuidv4();
                await DataStore.save(
                    new DailyTrackingEntry({
                        userId,
                        date: new Date().toISOString(),
                        items: entry.items,
                        trackingItemId
                    })
                );
                setTrackingEntries([...trackingEntries, {...entry, trackingItemId}]);
            }
    
            // Update today's entry if it was saved
            const todaysDate = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
            if(entry.date.startsWith(todaysDate)) {
                console.log('updating todaysTrackingEntry', entry);
                setTodaysTrackingEntry(entry);
            }
        };
    
        await execute(() => saveEntry(entry), 'Error saving tracking entry');
    };  

    const deleteTrackingEntry = async (entry) => {
        if(isDemo || !userId) {
            execute(() => setTrackingEntries(trackingEntries.filter(e => e.id !== entry.id)), 
                'Error deleting demo tracking entry');
            return;
        }

        const deleteEntry = async (entry) => {
            await DataStore.delete(entry);
            // no need to reload entries, just remove the deleted entry from state
            setTrackingEntries(trackingEntries.filter(e => e.id !== entry.id));
        };

        await execute(() => deleteEntry(entry), 'Error deleting tracking entry');
    };
    
    return (
        <TrackingContext.Provider 
            value={{
                loading, error, isDemo, setIsDemo,
                trackingItems, addTrackingItem, updateTrackingItem, deleteTrackingItem,
                trackingCategories, addTrackingCategory, updateTrackingCategory, deleteTrackingCategory, 
                trackingSubcategories, addTrackingSubcategory, deleteTrackingSubcategory,
                trackingEntries, todaysTrackingEntry, fetchTrackingEntries, saveTrackingEntry, deleteTrackingEntry,
                categoryIdToColorMap, categoryIdToSubcategoryMap, subcategoryToCategoryMap, loadEntries
            }}
        >
            {children}
        </TrackingContext.Provider>
    );
};

export const useTracking = () => {
    const context = useContext(TrackingContext);
    if (!context) {
        throw new Error('useTracking must be used within a TrackingProvider');
    }
    return context;
};
