import React, { useState } from 'react';
import PropTypes from 'prop-types';  // Import PropTypes for validation
import { Dropdown, Switch } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTheme } from '../../../app/utils';

const DropdownWrapper = styled(Dropdown)`
  font-family: Arial, sans-serif;
`;

const TriggerLink = styled.a`
  display: flex;
  align-items: center;
  color: #1890ff;
  font-weight: bold;
  cursor: pointer;
  
  &:hover {
    color: #40a9ff;
  }
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: ${({ visible }) => (visible ? '#f0f5ff' : 'white')};
  
  &:hover {
    background: #e6f7ff;
  }
`;

const ColumnTitle = styled.span`
  flex: 1;
  color: ${({ visible }) => (visible ? '#595959' : '#bfbfbf')};
  transition: color 0.3s;
`;

const StyledSwitch = styled(Switch)`
  &&& {
    margin-left: auto;
    width: 20px;
  }
`;

const ColumnSelector = ({ columns = [], toggleColumnVisibility }) => {  // Set default value for columns
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  const handleSwitchChange = (checked, key) => {
    toggleColumnVisibility(key);
  };

  const menuItems = columns
    .filter(column => !column.fixed)
    .map(column => ({
      key: column.key,
      label: (
        <MenuItem visible={column.visible} onClick={(e) => e.stopPropagation()}>
          <ColumnTitle visible={column.visible}>{column.title}</ColumnTitle>
          <StyledSwitch
            checked={column.visible}
            onChange={(checked) => handleSwitchChange(checked, column.key)}
            style={{
              backgroundColor: theme.buttonBackgroundColor,
              color: theme.buttonTextColor,
            }}
          />
        </MenuItem>
      ),
    }));

  const menu = {
    items: menuItems,
    onClick: (e) => e.domEvent.stopPropagation(),
  };

  return (
    <DropdownWrapper 
      menu={menu} 
      trigger={['click']} 
      open={open}
      onOpenChange={handleOpenChange}
    >
      <TriggerLink 
        onClick={(e) => e.preventDefault()}
        style={{
              color: theme.buttonBackgroundColor,
            }}>
        <SettingOutlined style={{marginRight: '5px'}}/> <span>Columns</span>
      </TriggerLink>
    </DropdownWrapper>
  );
};

// Add PropTypes to validate props
ColumnSelector.propTypes = {
  columns: PropTypes.array.isRequired,
  toggleColumnVisibility: PropTypes.func.isRequired,
};

export default ColumnSelector;
