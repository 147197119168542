import React, { useState, useEffect, useRef } from 'react';
import { Card, Input, Button, List, Spin } from 'antd';
import { SendOutlined, UserOutlined, RobotOutlined } from '@ant-design/icons';
import demoChatData from './mocks/demoChatData.json'
import './ChatWindow.css';
import { setMessages, setIsLoading } from '../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import type {Dispatch} from 'redux'
import type {ActionType} from '../../store/actions/index'
import type {AppDispatch, RootState} from '../../store/index'


export interface AssistantMessage {
  text: string;
  sender: 'user' | 'assistant' | string // TODO (eo): remove type string once you remove dummy JSON import
}

const ChatWindow = () => {
  const [input, setInput] = useState('');
  const chatListRef = useRef<HTMLDivElement>(null);
  const { theme, userId, messages, isLoading, isDemoMode, userIdToken } = useSelector((state: RootState) => state.app);

  const dispatch: AppDispatch = useDispatch();

  // Function to fetch the conversation history
  const fetchConversationHistory = async () => {
    console.log('Demo mode enabled? ', isDemoMode);
    if (isDemoMode) {
      dispatch(setIsLoading(false));
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/assistant/messages`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userIdToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const conversationHistory = data.message.map((msg: any) => ({
          text: msg.content[0].text.value,
          sender: msg.role === 'assistant' ? 'assistant' : 'user',
        }));
        dispatch(setMessages(conversationHistory));
      } else {
        console.error(
          'Error fetching conversation history:',
          response.statusText
        );
      }
    } catch (error) {
      console.error('Error fetching conversation history:', error);
    } finally {
      console.log('Finally block entered - history fetched');
      dispatch(setIsLoading(false));
    }
  };

  // Load demo chat data when the component mounts
  useEffect(() => {
    if(isDemoMode) {
      console.log('Loading demo chat data...');

      dispatch(setMessages(demoChatData.messages));
      return;
    }
  }, []);

  // Fetch conversation history when the component mounts
  useEffect(() => {
    dispatch(setIsLoading(true));
    fetchConversationHistory();
  }, [userId]);

  // Scroll to the bottom of the chat list whenever messages change
  useEffect(() => {
    if (chatListRef && chatListRef?.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newMessages = [...messages, { text: input, sender: 'user' }];
    dispatch(setMessages(newMessages));
    setInput('');

    dispatch(setIsLoading(true));

    try {
      // Call backend API to process the new user message
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/assistant/hook`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userIdToken}`,
          },
          body: JSON.stringify({ text: input, userId }),
        }
      );
      const responseData = await response.json();

      // Add the assistant's response to the messages array
      dispatch(setMessages([
        ...newMessages,
        { text: responseData.message, sender: 'assistant' },
      ]));
    } catch (error) {
      console.error('Error fetching response:', error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <Card title="Chat with Eva" className="chat-card" style={{backgroundColor: theme?.chatCardBackgroundColor}}>
      <div className="chat-list-container" ref={chatListRef}>
        <List
          dataSource={messages}
          renderItem={(message, index) => (
            <List.Item
              className={`chat-list-item ${
                message.sender === 'user'
                  ? 'user-container'
                  : 'assistant-container'
              }`}
              key={index}
            >
              {/* Display user and assistant icons based on the message sender */}
              {message.sender === 'user' ? (
                <UserOutlined
                  className="chat-icon"
                  style={{ color: theme.chatUserTextColor, backgroundColor: theme.chatUserBackgroundColor}}
                />
              ) : (
                <RobotOutlined
                  className="chat-icon"
                  style={{ color: theme.chatRobotTextColor, backgroundColor: theme.chatRobotBackgroundColor}}
                />
              )}
              {/* Display the message in a bubble */}
              <div
                className={`chat-bubble ${
                  message.sender === 'user' ? 'user-bubble' : 'assistant-bubble'
                }`}
                style={{
                  backgroundColor: message.sender === 'user'? theme.chatUserBubbleBgColor: theme.chatRobotBubbleBgColor,
                  color: message.sender === 'user'? theme.chatUserBubbleTextColor: theme.chatRobotBubbleTextColor
                }}
              >
                {message.text}
              </div>
            </List.Item>
          )}
          footer={
            isLoading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            ) : null
          }
        />
      </div>

      {/* Chat form */}
      <form onSubmit={handleSubmit} className="chat-form">
        <Input
          placeholder="Type your message here..."
          value={input}
          onChange={handleInputChange}
          className="chat-input"
        />
        <Button
          type="primary"
          htmlType="submit"
          icon={<SendOutlined />}
          className="chat-button"
          disabled={input.trim() === ''}
        >
          Send
        </Button>
      </form>
    </Card>
  );
};

export default ChatWindow;
