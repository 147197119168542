import React from "react";
import MessageBox from "./MessageBox";
import classes from "./coach-styles/CoachMain.module.css";
import LeftPane from "./LeftPane";
import NewCoachPane from "./NewCoachPane";
import type { Thread } from "./coach-utils/corpath-types";



function CoachMain() {
  const [chosenThread, setChosenThread] = React.useState<Thread | null>(null);
  const [refreshThisThreadId, setRefreshThisThreadId] =
    React.useState<string>("");
  const [threads, setThreads] = React.useState<Thread[]>([]);
  const [searchedThreads, setSearchedThreads] = React.useState<Thread[] | null>(
    []
  );
  const [initLoad, setInitLoad] = React.useState<boolean>(true);

  React.useEffect(() => {
    console.log("Threads updated!")
    console.log(threads);
    // on first thread load, DON'T update chosenThread to first
    if (threads.length === 0) {
      setChosenThread(null);
      return;
    }
    if (initLoad) {
      setInitLoad(false);
    }
    setChosenThread(threads[0]);
  }, [threads]); // not an error (linter?)



  return (
    <div className={classes.container}>
      <LeftPane
        refreshThisThreadId={refreshThisThreadId}
        setRefreshThisThreadId={setRefreshThisThreadId}
        chosenThread={chosenThread}
        searchedIsNull={searchedThreads === null}
        setChosenThread={setChosenThread}
        threads={searchedThreads?.length ? searchedThreads : threads}
        setThreads={setThreads}
        setSearchedThreads={setSearchedThreads}
        />
      {chosenThread ? (
        <MessageBox
        isSearched={searchedThreads?.length !== 0}
        chosenThread={chosenThread}
        setRefreshThisThreadId={setRefreshThisThreadId}
        />
      ) : (
        <NewCoachPane setThreads={setThreads} />
      )}
    </div>

  );
}

export default CoachMain;
