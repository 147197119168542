import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Button, Modal, Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Task, TaskStatus, Priority, EnergyImpact } from '../../../models';
import { fetchTasks, setTasks as setTasksAction } from '../../../store/actions';
import TaskModal from './TaskModal';
import { DataStore } from 'aws-amplify';
import { useTheme } from '../../../app/utils';

const { Title, Text } = Typography;

const KanbanBoard = ({ tasks: initialTasks, setTasks: setInitialTasks, loading }) => {
  const dispatch = useDispatch();
  const { userId, isDemoMode } = useSelector((state) => state.app);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [tasks, setTasks] = useState(initialTasks);
  const [columnOrder, setColumnOrder] = useState(['NOT_STARTED', 'IN_PROGRESS', 'BLOCKED', 'COMPLETED']);

  const theme = useTheme();

  useEffect(() => {
    setTasks(initialTasks);
  }, [initialTasks]);

  const columns = useMemo(() => columnOrder.map(id => ({
    id,
    title: id.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
  })), [columnOrder]);

  const groupedTasks = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id] = tasks.filter(task => task.status === column.id) || [];
      return acc;
    }, {});
  }, [tasks, columns]);

  const onDragEnd = useCallback(async (result) => {
    const { source, destination, draggableId, type } = result;
    console.log('onDragEnd called with:', { source, destination, draggableId, type });

    if (!destination) {
      console.log('No destination, drag cancelled');
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      console.log('Dropped in same position, no action needed');
      return;
    }

    if (type === 'COLUMN') {
      const newColumnOrder = Array.from(columnOrder);
      const [reorderedColumn] = newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, reorderedColumn);
      
      console.log('Column reordered:', newColumnOrder);
      setColumnOrder(newColumnOrder);
      return;
    }

    if (type === 'TASK') {
      const updatedTasks = [...tasks];
      const taskIndex = updatedTasks.findIndex(task => task.id === draggableId);
      if (taskIndex === -1) {
        console.error('Task not found:', draggableId);
        return;
      }

      const taskToUpdate = updatedTasks[taskIndex];
      const updatedTask = {
        ...taskToUpdate,
        status: destination.droppableId
      };

      updatedTasks[taskIndex] = updatedTask;
      setTasks(updatedTasks);
      setInitialTasks(updatedTasks);

      console.log('Task updated:', updatedTask);

      if (!isDemoMode) {
        try {
          const originalTask = await DataStore.query(Task, draggableId);
          if (originalTask) {
            await DataStore.save(
              Task.copyOf(originalTask, (updated) => {
                updated.status = destination.droppableId;
              })
            );
            dispatch(fetchTasks(userId));
          }
        } catch (error) {
          console.error('Error updating task:', error);
        }
      }
    }
  }, [tasks, setTasks, setInitialTasks, isDemoMode, dispatch, userId, columnOrder]);

  const updateTask = useCallback(async (updatedTask) => {
    console.log('Updating task:', updatedTask);

    if (isDemoMode) {
      console.info('Demo mode: Database task update disabled');
      const updatedTasks = tasks.map((t) => t.id === updatedTask.id ? { ...t, ...updatedTask } : t);
      setTasks(updatedTasks);
      setInitialTasks(updatedTasks);
      dispatch(setTasksAction(updatedTasks));
      return;
    }

    try {
      const originalTask = await DataStore.query(Task, updatedTask.id);
      if (!originalTask) throw new Error(`Task with id ${updatedTask.id} not found`);

      await DataStore.save(Task.copyOf(originalTask, (updated) => {
        Object.assign(updated, updatedTask);
        updated.id = originalTask.id;
        updated.userId = originalTask.userId;
        updated.createdAt = originalTask.createdAt;
        updated.updatedAt = new Date().toISOString();
      }));

      dispatch(fetchTasks(userId));
    } catch (error) {
      console.error(`Error updating task: ${error}`);
    } finally {
      setIsTaskModalOpen(false);
    }
  }, [isDemoMode, dispatch, setTasks, setInitialTasks, tasks, userId]);

  const handleEditTask = useCallback((task) => {
    console.log('Editing task:', task);
    setSelectedTask(task);
    setIsTaskModalOpen(true);
  }, []);

  const handleDeleteTask = useCallback((task) => {
    console.log('Deleting task:', task);
    setTaskToDelete(task);
    setIsDeleteModalVisible(true);
  }, []);

  const confirmDeleteTask = useCallback(async () => {
    if (!taskToDelete) return;

    console.log('Confirming delete for task:', taskToDelete);

    try {
      const taskToDeleteFromDB = await DataStore.query(Task, taskToDelete.id);
      await DataStore.delete(taskToDeleteFromDB);
      dispatch(fetchTasks(userId));
    } catch (error) {
      console.error(`Error deleting task: ${error}`);
    } finally {
      setIsDeleteModalVisible(false);
      setTaskToDelete(null);
    }
  }, [taskToDelete, dispatch, userId]);
  

  const StyledCard = styled(Card)`
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: ${theme.cardBackgroundColor};
    color: ${theme.textColor};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: grab;

    .ant-card-body {
      padding: 16px;
      // cursor: move;
    }

    .ant-card-meta-title {
      margin-bottom: 10px;
    }

    .ant-card-meta-description {
      font-size: 14px;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    &:active {
      cursor: grabbing;
    }
  `;

  const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  `;

  const StyledButton = styled(Button)`
    margin-left: 8px;
    background-color: ${theme.buttonBackgroundColor};
    color: ${theme.buttonTextColor};
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
      background-color: ${theme.buttonHoverBackgroundColor};
    }
  `;

  const ColumnTitle = styled(Title)`
    color: ${theme.textColor};
    margin-bottom: 20px !important;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  `;


  const BoardContainer = styled.div`
    display: flex;
    height: calc(100vh - 100px);
    padding: 20px;
    overflow-x: auto;
    background-color: ${theme.boardBackgroundColor};
    transition: background-color 0.3s;

    &:hover {
      background-color: ${theme.boardHoverBackgroundColor};
    }
  `;

  const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.backgroundColor};
    border-radius: 12px;
    width: 320px;
    height: 100%;
    margin-right: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;

    &:hover {
      background-color: ${theme.hoverBackgroundColor};
    }
  `;

  const TaskList = styled.div`
    padding: 20px;
    flex-grow: 1;
    overflow-y: auto;
  `;

const renderTask = useCallback((task, index) => (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StyledCard>
            <Card.Meta
              title={<Text strong>{task.title}</Text>}
              description={
                <>
                  <Text type="secondary">Priority: {task.priority}</Text>
                  <br />
                  <Text type="secondary">Due: {new Date(task.dueDate).toLocaleDateString()}</Text>
                </>
              }
            />
            <ButtonGroup>
              <StyledButton icon={<EditOutlined />} size="small" onClick={(e) => { e.stopPropagation(); handleEditTask(task); }} />
              <StyledButton icon={<DeleteOutlined />} size="small" onClick={(e) => { e.stopPropagation(); handleDeleteTask(task); }} />
            </ButtonGroup>
          </StyledCard>
        </div>
      )}
    </Draggable>
  ), [handleEditTask, handleDeleteTask]);

  const renderColumn = useCallback((column, index) => (
    <Draggable key={column.id} draggableId={column.id} index={index}>
      {(provided) => (
        <ColumnContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <ColumnTitle level={4} {...provided.dragHandleProps}>{column.title}</ColumnTitle>
          <Droppable droppableId={column.id} type="TASK">
            {(provided) => (
              <TaskList
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {groupedTasks[column.id].map(renderTask)}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
        </ColumnContainer>
      )}
    </Draggable>
  ), [groupedTasks, renderTask]);

  const memoizedColumns = useMemo(() => columns.map(renderColumn), [columns, renderColumn]);

  const onDragStart = useCallback((start) => {
  console.info('Drag started:', start);
}, []);

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="board" type="COLUMN" direction="horizontal">
        {(provided) => (
          <BoardContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {memoizedColumns}
            {provided.placeholder}
          </BoardContainer>
        )}
      </Droppable>

      <TaskModal
        isVisible={isTaskModalOpen}
        onCancel={() => setIsTaskModalOpen(false)}
        onSubmit={updateTask}
        task={selectedTask}
        isEditMode
      />

      <Modal
        title="Confirm Deletion"
        open={isDeleteModalVisible}
        onOk={confirmDeleteTask}
        onCancel={() => setIsDeleteModalVisible(false)}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this task?</p>
      </Modal>
    </DragDropContext>
  );
};

export default React.memo(KanbanBoard);