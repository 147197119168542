import { useState } from "react";
import { Button, ColorPicker, Flex, Input } from "antd";
import tinycolor from "tinycolor2";

const AddTrackingCategory = ({presets, onAdd }) => {
    const [newCategory, setNewCategory] = useState('');
    const [color, setColor] = useState('#rgb(22, 119, 255)');

    const handleAdd = () => {
        if(!newCategory || !newCategory.trim()) {
            return;
        }
        console.log('Adding category', newCategory, color);
        onAdd(newCategory, color);
        setNewCategory('');
    }

    const convertToRGB = (color) => {
        return tinycolor(color.metaColor ? color.metaColor : color).toRgbString();
    }
    
    return (
        <Flex>
            <ColorPicker presets={presets} value={color} onChange={(color) => setColor(convertToRGB(color))} />
            <Input value={newCategory} onChange={(e) => setNewCategory(e.target.value)} onPressEnter={handleAdd}
                placeholder="Enter new category name" />
            <Button type="primary" onClick={handleAdd} >Add</Button>
        </Flex>
    );
}

export default AddTrackingCategory;
