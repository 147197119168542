//@ts-nocheck
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Auth } from 'aws-amplify';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useDispatch, useSelector } from 'react-redux';
import { Task } from '../../models';
import { DataStore } from 'aws-amplify';
import axios from 'axios';
import { fetchTasks, setTasks, fetchEvents, fetchCalendars, toggleCalendarVisibility } from '../../store/actions';
import TaskModal from '../tasks/components/TaskModal';
import EventModal from '../tasks/components/EventModal';
import CalendarList from './components/CalendarList';
import type {RootState, AppDispatch} from '../../store/index'
// import type {Dispatch} from 'redux'
// import type {ActionType} from '../../store/actions/index'

const Calendar = () => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  });

  const [weekendsVisible, setWeekendsVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // State to track the event being edited or viewed
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);
  const { userId, tasks, events, calendars  } = useSelector((state: RootState) => state.app);
  const dispatch: AppDispatch = useDispatch();

  // Keep track of the last known time range from the calendar
  const timeRangeRef = useRef<{timeMin: string | null; timeMax: string | null}>({ timeMin: null, timeMax: null });

  function handleWeekendsToggle() {
    setWeekendsVisible(!weekendsVisible);
  }
  function handleDateSelect(selectInfo: any) {
    let title = prompt('Please enter a new title for your event');
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      try {
        calendarApi.addEvent({
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      } catch (error) {
        console.error('Failed to add event:', error);
      }
    }
  }

  function handleEventClick(clickInfo: any) {
  const clickedEvent = combinedEventsAndTasks.find(event => event.id === clickInfo.event.id) || tasks.find(task => task.id === clickInfo.event.id);

  if (clickedEvent) {
     setSelectedEvent(clickedEvent);
    if (clickedEvent.extendedProps?.type === 'task') {
      setIsTaskModalVisible(true);
    } else {
      setIsEventModalVisible(true);
    }
  }
}
// @ts-ignore -- Auth.user is private class property
  const isDemoMode = Auth.user.attributes.sub === '73ec5ccf-f055-4a23-9607-79a1f5e10484';

   const taskStatusColors = useMemo(() => ({
    NOT_STARTED: '#007bff',  // Blue
    IN_PROGRESS: '#0dcaf0',  // Cyan
    COMPLETED: '#198754',    // Green
    BLOCKED: '#dc3545',      // Red
    PENDING_APPROVAL: '#ffc107', // Yellow
  }), []);



const tasksToEvents = useCallback((tasks: Task[]) => {
  return tasks.map((task: Task) => {
      const backgroundColor = taskStatusColors[task.status] || 'gray';
      const startDate = new Date(task.start); // Parse the start date
    const taskEndDate = new Date(startDate.getTime() + task.duration * 60000); // Add the duration in minutes
    const taskEnd = taskEndDate.toISOString();     // Convert back to ISO string to get the correct date timestamp format
    const start = new Date(task.start);
    const durationInMs = task.duration * 60 * 1000;

    // Calculate end time by adding duration to start time
    const end = new Date(start.getTime() + durationInMs);

    return {
      ...task,
      id: task.id,
      title: task.title,
      start: task.start,
      end: taskEnd ,
      allDay: false,
      backgroundColor,
      borderColor: 'black',
      textColor: 'white',
      extendedProps: {
        description: task.description,
        status: task.status,
        type: 'task',
      },
    };
  });
}, [taskStatusColors]);



  useEffect(() => {
    dispatch(fetchCalendars());
    dispatch(fetchTasks(userId ?? ''));
  }, [dispatch, userId]);

  useEffect(() => {
    if (timeRangeRef.current.timeMin && timeRangeRef.current.timeMax) {
      dispatch(fetchEvents(timeRangeRef.current.timeMin, timeRangeRef.current.timeMax));
    }
  }, [calendars, dispatch]);

  const combinedEventsAndTasks = useMemo(() => [
    ...events,
    ...tasksToEvents(tasks),
  ], [events, tasks, tasksToEvents]);

  const handleDatesSet = (info: {startStr: string; endStr: string;}) => {
    const timeMin = info.startStr;
    const timeMax = info.endStr;

    // Store the current time range
    timeRangeRef.current = { timeMin, timeMax };
    dispatch(fetchEvents(timeMin, timeMax));
  };

  const updateTask = async (updatedTask: Task) => {
    if (isDemoMode) {
      console.info('Demo mode: Database task update disabled');
      dispatch(setTasks(
        tasks.map((t) => {
          if (t.id === updatedTask.id) {
            return { ...t, ...updatedTask };
          }
          return t;
        })
      ));
      return;
    }

    try {
      const originalTask = await DataStore.query(Task, updatedTask.id);
      if (!originalTask) {
        throw new Error(`Task with id ${updatedTask.id} not found`);
      }

      await DataStore.save(
        Task.copyOf(originalTask, (updated) => {
          Object.assign(updated, updatedTask);
          // updated.id = originalTask.id;
          updated.userId = originalTask.userId;
          // updated.createdAt = originalTask.createdAt;
          // updated.updatedAt = null; // Set updatedAt to null to create a new revision
        })
      );

      dispatch(fetchTasks(userId ?? '')); // Refresh tasks after update
    } catch (error) {
      console.error(`Error updating task: ${error}`);
    }
  };

const handleFormSubmit = (values) => {
  // find selected event in tasksToEvents and check if it is a task
  const selected = combinedEventsAndTasks.find((event) => event.id === selectedEvent?.id);

    if (selected?.extendedProps?.type === 'task') {
      const updatedTask = { ...selectedEvent, ...values };
      updateTask(updatedTask);
    } else {
      // Implement event update logic later if we want to update events
    }
    setIsEventModalVisible(false);
    dispatch(fetchTasks(userId));

    // Fetch events using the last known time range
    const { timeMin, timeMax } = timeRangeRef.current;
    if (timeMin && timeMax) {
      dispatch(fetchEvents(timeMin, timeMax));
    }
  };

  const handleCancel = () => {
    setIsEventModalVisible(false);
    setIsTaskModalVisible(false);
  };

  const handleToggleCalendar = (calendarId) => {
    dispatch(toggleCalendarVisibility(calendarId));
    // Fetch events again with the updated calendar visibility
    const { timeMin, timeMax } = timeRangeRef.current;
    if (timeMin && timeMax) {
      dispatch(fetchEvents(timeMin, timeMax));
    }
};

  return (
    <div
      className="calendar"
      style={{
        height: '100vh',
      }}
    >
      <CalendarList
        calendars={calendars}
        onToggleCalendar={handleToggleCalendar}
      />
      <label>
        <input
          type="checkbox"
          checked={weekendsVisible}
          onChange={handleWeekendsToggle}
        ></input>
        show weekends
      </label>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        height="90vh"
        initialView="timeGridWeek"
        firstDay="1"
        scrollTime="08:00:00"
        slotMinTime="00:00:00"
        slotMaxTime="23:59:59"
        businessHours={true}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={weekendsVisible}
        initialEvents={events}
        initialDate={isDemoMode ? '2024-05-01' : undefined}
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        events={combinedEventsAndTasks} // fetch events data
        // events={currentEvents} // fetch events data
        eventAdd={async function (info) {
          const calendarId = null; // TODO: add support for multiple calendars
          const event = info.event.toPlainObject();
          try {
            await api.post('/calendar/events/create', {
              calendarId,
              userId,
              event,
              provider: 'google',
            }); // TODO: add support for multiple providers
          } catch (error) {
            const errorMessage = `Failed to add event for user ${userId}: ${error}`;
            console.error(errorMessage);
            console.log('Reverting to previous state');
            info.revert(); // Revert to the previous state
            throw new Error(errorMessage);
          }
        }}
        eventChange={async function (info) {
          const calendarId = null;
          const eventId = info.event.id;
          const event = info.event.toPlainObject();
          try {
            await api.post('/calendar/events/update', {
              calendarId,
              userId,
              event,
              provider: 'google',
              eventId,
            });
            const { timeMin, timeMax } = timeRangeRef.current;
            if (timeMin && timeMax) {
              dispatch(fetchEvents(timeMin, timeMax));
            }
          } catch (error) {
            const errorMessage = `Failed to update event with eventId ${eventId} for userId: ${userId}: ${error}`;
            console.error(errorMessage);
            info.revert(); // Revert to the previous state
            throw new Error(errorMessage);
          }
        }}
        eventRemove={async function (info) {
          const calendarId = null; // TODO: add support for multiple calendars
          const eventId = info.event.id;
          try {
            await api.post('/calendar/events/delete', {
              calendarId,
              userId,
              eventId,
              provider: 'google',
            });
            const { timeMin, timeMax } = timeRangeRef.current;
            if (timeMin && timeMax) {
              dispatch(fetchEvents(timeMin, timeMax));
            }
          } catch (error) {
            const errorMessage = `Failed to delete event with id ${eventId} for userId: ${userId}: ${error}`;
            console.error(errorMessage);
            info.revert(); // Revert to the previous state
            throw new Error(errorMessage);
          }
        }}
        datesSet={handleDatesSet}
      />
     {isEventModalVisible && selectedEvent && selectedEvent.extendedProps?.type !== 'task' && (
        <EventModal
          isVisible={isEventModalVisible}
          onCancel={handleCancel}
          onSubmit={handleFormSubmit}
          event={selectedEvent}
          isEditMode={true}
        />
      )}

      {isTaskModalVisible && selectedEvent && selectedEvent.extendedProps?.type === 'task' && (
        <TaskModal
          isVisible={isTaskModalVisible}
          onCancel={handleCancel}
          onSubmit={handleFormSubmit}
          task={selectedEvent}
          isEditMode={true}
        />
      )}

    </div>
  );
};

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default Calendar;
