import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dropdown, Menu, Modal, Input, Table, Card, Row, Col, Checkbox, Tag, InputNumber, Select, Divider, Typography, Tooltip as AntTooltip, Button, Switch } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartTooltip, Legend, ResponsiveContainer } from 'recharts';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import DragableBodyRow from './components/DragableBodyRow';
import { sortTasks, formatStatusName, getTaskSizeColor, getTaskStatusColor } from '../tasks/taskUtils';
import DependencyGraph from './components/DependencyGraph';
import { DataStore } from '@aws-amplify/datastore';
import { ProjectPlan, Task, TaskStatus } from "../../models";
import TextArea from 'antd/es/input/TextArea';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import TaskModal from '../tasks/components/TaskModal';

const { Option } = Select;
const { Text } = Typography;

const ProjectTracker = () => {
  const [showCompletedTasks, setShowCompletedTasks] = useState(true);
  const [projectPlan, setProjectPlan] = useState([]);
  const [taskGroups, setTaskGroups] = useState([]);
  const [newTaskTitle, setNewTaskTitle] = useState({});
  const [sizeToDays, setSizeToDays] = useState({
    XS: { min: 1, max: 1 },
    S: { min: 1, max: 2 },
    M: { min: 2, max: 3 },
    L: { min: 4, max: 7 },
    XL: { min: 8, max: 14 }
  });
  const [estimateView, setEstimateView] = useState('weeks');
  const [weekDays, setWeekDays] = useState(5);
  const [selectedTaskGroupIds, setSelectedTaskGroups] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [threads, setThreads] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [taskEditModalVisible, setTaskEditModalVisibile] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [newTaskGroupName, setNewTaskGroupName] = useState('');

  // TODO: Implement highlightedTasks w/Redux
  const highlightedTasks = [];
  const taskRefs = useRef({});
  const { userId } = useSelector(state => state.app);
  const { projectId } = useParams();

  const defaultSizeEstimate = "S";

  useEffect(() => {
    fetchProjectPlan();
  }, []);

  // Hide all completed tasks when the showCompletedTasks switch is toggled
  useEffect(() => {
    fetchProjectPlan();
  }, [showCompletedTasks]);


  const openTaskEditModal = (task) => () => {
    setSelectedTask(task);
    setTaskEditModalVisibile(true);
  };

  const createSizeToDaysMapping = (sizeEstimates) => {
    // Convert the array to an object for easier lookup if this hasn't been done earlier
    const sizeToDays = sizeEstimates.reduce((acc, size) => {
      acc[size.name] = { min: size.min, max: size.max };
      return acc;
    }, {});
    console.log('Size to Days Mapping: ', sizeToDays);
    setSizeToDays(sizeToDays);
  };

  const calculateTaskGroupTotalSize = (tasks, sizeEstimates) => {
    // Create a copy of the size estimates array and sort it by max value
    const sortedSizeEstimates = [...(sizeEstimates || [])].sort((a, b) => a.max - b.max);
    const smallestTaskSize = sortedSizeEstimates[0]?.name || defaultSizeEstimate;
    const largestTaskSize = sortedSizeEstimates[sortedSizeEstimates.length - 1]?.name || defaultSizeEstimate;

    // If there are no tasks, return the smallest size estimate
    if(tasks.length === 0){
      return smallestTaskSize;
    }

    // Sum up the maximum days from size estimates associated with each task
    const totalDays = tasks.reduce((sum, task) => {
      return sum + task.maxSizeEstimate;
    }, 0);

    // Determine the size category based on the total days
    const matchingSize = sizeEstimates?.find(size => totalDays <= size.max);
    if (matchingSize) {
      return matchingSize.name;
    }

    // If the total days exceed the largest size estimate, return the largest size
    return largestTaskSize;
  };

  const getTaskEstimateInDays = (sizeEstimate, minMax) => {
    switch (minMax) {
      case 'min':
        return sizeToDays[sizeEstimate]?.min || 0;
      case 'max':
        return sizeToDays[sizeEstimate]?.max || 0;
      default:
        return 0;
    }
  }

  const fetchProjectPlan = async () => {
    const plan = await DataStore.query(ProjectPlan, projectId);
    setProjectPlan(plan);
    createSizeToDaysMapping(plan.sizeEstimates);

    const projectTaskGroups = plan.taskGroups || [];
    if(projectTaskGroups?.length === 0) {
      setTaskGroups([]);
      return;
    }

    // Fetch all tasks for this project
    const projectTasks = await DataStore.query(Task, (t) =>
      t.projectId.eq(plan.id)
    );

    if(!projectTasks || projectTasks.length === 0) {
      setTaskGroups(projectTaskGroups);
      return;
    }

    // Set all task groups as selected on initial load
    if(isInitialLoad) {
      setSelectedTaskGroups(projectTaskGroups.map(taskGroup => taskGroup.id));
      setIsInitialLoad(false);
    }

    // Group tasks by taskGroupId
    const groupedTasks = projectTasks.reduce((acc, task) => {
      if (!acc[task.taskGroupId]) {
        acc[task.taskGroupId] = [];
      }
      acc[task.taskGroupId].push(task);
      return acc;
    }, {});

    // Attach corresponding tasks to each task group
    const taskGroupsWithTasks = projectTaskGroups.map(taskGroup => {
      const taskGroupTasks = groupedTasks[taskGroup.id] || [];
      const taskGroupTasksWithEstimates = taskGroupTasks
        .filter(task => ( showCompletedTasks || task.status !== TaskStatus.COMPLETED ))
        .map(task => (
        { ...task,
          maxSizeEstimate: getTaskEstimateInDays(task.sizeEstimate, 'max'),
          minSizeEstimate: getTaskEstimateInDays(task.sizeEstimate, 'min'),
          size: task.status === TaskStatus.COMPLETED ? 0 : getTaskEstimateInDays(task.sizeEstimate, 'max')
        }));
      const totalSize = calculateTaskGroupTotalSize(taskGroupTasksWithEstimates, plan.sizeEstimates);
      const sortedByPriority = sortTasks(taskGroupTasksWithEstimates, 'priority', 'ascend', true);
      const sortedByStatus = sortTasks(sortedByPriority, 'status', 'ascend');
      return {
        ...taskGroup,
        tasks: sortedByStatus,
        size: totalSize
      };
    });

    // Get any tasks that are not assigned to a task group from the project
    // and create a task group to contain them called 'Unassigned'
    const groupedTasksTaskGroupIds = Object.keys(groupedTasks);
    const unassignedTaskGroupIds = groupedTasksTaskGroupIds.filter(id => !projectTaskGroups.find(group => group.id === id));
    const unassignedTasks = unassignedTaskGroupIds.reduce((acc, id) => {
      return acc.concat(groupedTasks[id]);
    }, []);
    let unassignedTaskGroup = {
      id: 'Unassigned',
      name: 'Unassigned',
      tasks: unassignedTasks.map(task => (
        { ...task,
          maxSizeEstimate: getTaskEstimateInDays(task.sizeEstimate, 'max'),
          minSizeEstimate: getTaskEstimateInDays(task.sizeEstimate, 'min'),
          currentSize: task.status === TaskStatus.COMPLETED ? 0 : getTaskEstimateInDays(task.sizeEstimate, 'max')
        })),
      size: calculateTaskGroupTotalSize(unassignedTasks, plan.sizeEstimates)
    };
    console.log('unassignedTaskGroup: ', unassignedTaskGroup);
    if(unassignedTasks.length > 0) {
      taskGroupsWithTasks.push(unassignedTaskGroup);
    }

    console.log('taskGroupsWithTasks: ', taskGroupsWithTasks);
    setTaskGroups(taskGroupsWithTasks);
  }

  const moveRow = useCallback((dragIndex, hoverIndex) => {
    const dragRow = taskGroups[dragIndex];
    const reorderedTaskGroups = update(taskGroups, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRow],
      ],
    });
    setTaskGroups(reorderedTaskGroups);
    updateTaskGroupOrder(reorderedTaskGroups);
  }, [taskGroups]);

  const handleCancel = () => {
    setTaskEditModalVisibile(false);
    //setNewTaskGroupName(''); // Optionally clear the input when modal is closed
  };

  const handleSetThreads = (value) => {
    setThreads(value);
  };

  const handleAddTask = async (taskGroupId, title) => {
    if (!title) return; // Do nothing if the description is empty

    // Create a new Task object
    const newTask = {
      title: title,
      userId: userId,
      status: TaskStatus.DRAFT, // DRAFT tasks will show in project planning but not in task list until project is started and all tasks move to NOT_STARTED state
      sizeEstimate: defaultSizeEstimate,
      projectId: projectPlan.id,
      taskGroupId: taskGroupId, // Associate with the task group
    };

    try {
      // Save the new task to DataStore
      const savedTask = await DataStore.save(new Task(newTask));
      console.log('Task saved:', savedTask);

      const updatedTaskIds = [...taskGroups.find(group => group.id === taskGroupId).taskIds, savedTask.id];

      // Get updated task group with the new task
      const updatedTaskGroups = projectPlan.taskGroups.map(group => {
        if (group.id === taskGroupId) {
          return {
            ...group,
            taskIds: updatedTaskIds
          };
        }
        return group;
      });

      // Fetch the most recent project plan to avoid stale data & weird loading issues
      const mostRecentProjectPlan = await DataStore.query(ProjectPlan, projectPlan.id);

      // Update the taskgroup in the Project Plan and save the updated project plan
      await DataStore.save(ProjectPlan.copyOf(mostRecentProjectPlan, updated => {
        updated.taskGroups = updatedTaskGroups;
      }));

      // Update local state to reflect the change
      fetchProjectPlan();

      // Reset the input field
      setNewTaskTitle(prev => ({ ...prev, [taskGroupId]: '' }));
    } catch (error) {
      console.error('Failed to add task:', error);
    }
  };

  const handleEditTask = async (task) => {
    try {
      // Fetch the most recent version of the task to prevent weird loading issues
      const mostRecentTask = await DataStore.query(Task, task.id);
      if (!mostRecentTask) {
        console.error('Task not found:', task);
        return;
      }

      // Update the task with the new title
      const updatedTask = Task.copyOf(mostRecentTask, updated => {
        Object.assign(updated, task);  // Merge task into updated
        updated.id = mostRecentTask.id; // Ensure the ID is not changed
        updated.userId = mostRecentTask.userId; // Ensure the userId is not changed
        updated._version = mostRecentTask._version; // Ensure the version is updated
        updated.updatedAt = null; // Clear the updatedAt field to force a new timestamp
      })
      console.log('Updated Task:', updatedTask);
      await DataStore.save(updatedTask);

      // Fetch the updated project plan to reset the state
      fetchProjectPlan();
    } catch (error) {
      console.error('Failed to update task:', error);
    }
  };

  const handleUpdateTaskSize = async (task, size) => {
    try {
      // get the most recent version of the task to prevent weird loading issues
      const mostRecentTask = await DataStore.query(Task, task.id);
      if (!mostRecentTask) {
        console.error('Task not found:', task);
        return;
      }

      // Update the task sizeEstimate
      await DataStore.save(Task.copyOf(mostRecentTask, updated => {
        updated.sizeEstimate = size;
        updated.updatedAt = null; // Clear the updatedAt field to force a new timestamp
      }));

      // Fetch the updated project plan to reset the state
      fetchProjectPlan();
    } catch (error) {
      console.error('Failed to update task size:', error);
    }
  };

  const handleDeleteTask = async (task) => {
    try {
      // Delete the task from DataStore
      const toDelete = await DataStore.query(Task, task.id);
      await DataStore.delete(toDelete);
      fetchProjectPlan();
    } catch (error) {
      console.error('Failed to delete task:', error);
    } finally {

    }
  };

  const handleAddTaskGroup = async () => {
    if (!newTaskGroupName) return; // Do nothing if the newTaskGroupName is empty

    try {
      // Create a new task group object with a generated ID and the provided name
      const newTaskGroup = {
        id: Date.now().toString(), // Simple unique ID generator, consider a more robust method
        name: newTaskGroupName,
        taskIds: [] // Initialize with no tasks
      };

      // Fetch the latest state of the project plan
      const mostRecentProjectPlan = await DataStore.query(ProjectPlan, projectPlan.id);

      // Append the new task group to the existing task groups
      const updatedTaskGroups = [...mostRecentProjectPlan.taskGroups, newTaskGroup];

      // Save the updated project plan with the new task group added
      await DataStore.save(ProjectPlan.copyOf(mostRecentProjectPlan, updated => {
        updated.taskGroups = updatedTaskGroups;
      }));

      // Optionally, refetch or update local state to reflect changes
      fetchProjectPlan();
      setNewTaskGroupName(''); // Clear the input field
      setTaskEditModalVisibile(false);
    } catch (error) {
      console.error('Failed to add task group:', error);
    }
  };

  const updateTaskGroupOrder = async (reorderedTaskGroups) => {
    try {
      // Fetch the most recent version of the project plan to prevent weird loading issues
      const mostRecentProjectPlan = await DataStore.query(ProjectPlan, projectPlan.id);
      if (!mostRecentProjectPlan) {
        console.error('Project Plan not found:', projectPlan);
        return;
      }

      // remove generated fields from task groups (size, tasks, etc.)
      const updatedTaskGroups = reorderedTaskGroups.map(group => (
        {
          id: group.id,
          name: group.name,
          taskIds: group.tasks.map(task => task.id)
        }));

      // update task group order
      await DataStore.save(ProjectPlan.copyOf(mostRecentProjectPlan, updated => {
        // Update the task group order and clear the generated tasks array
        updated.taskGroups = updatedTaskGroups;
        updated.updatedAt = null; // Clear the updatedAt field to force a new timestamp
      }));
    } catch (error) {
      console.error('Failed to update task group:', error);
    }
  };

  const handleDeleteTaskGroup = async (taskGroup) => {
    try {
      const mostRecentProjectPlan = await DataStore.query(ProjectPlan, projectPlan.id);
      const updatedTaskGroups = mostRecentProjectPlan.taskGroups.filter(group => group.id !== taskGroup.id);
      await DataStore.save(ProjectPlan.copyOf(mostRecentProjectPlan, updated => {
        updated.taskGroups = updatedTaskGroups;
      }));
      // delete all associated tasks
      try {
        await Promise.all(
          taskGroup.taskIds.map(
            taskId => handleDeleteTask({ id: taskId })
          ));
      } catch (error) {
        console.error(`[CLEANUP_NEEDED] Failed to delete tasks from deleted task group ${JSON.stringify(taskGroup)}`, error);
      }
      fetchProjectPlan();
    } catch (error) {
      console.error('Failed to delete task group:', error);
    }
  };

  const handleSizeDaysChange = (size, type, value) => {
    setSizeToDays(prev => ({
      ...prev,
      [size]: { ...prev[size], [type]: value },
    }));
  };

  const handleCheckboxChange = (taskGroupId, checked) => {
    setSelectedTaskGroups(prev =>
      checked ? [...prev, taskGroupId] : prev.filter(key => key !== taskGroupId)
    );
  };

  const handleExpandRow = (key) => {
    setExpandedRowKeys(expandedRowKeys.includes(key)
      ? expandedRowKeys.filter(k => k !== key)
      : [...expandedRowKeys, key]);
  };

  const getTotalTime = () => {
    // Skip calculation if no task groups are selected
    if(selectedTaskGroupIds.length === 0) {
      return { min: 0, max: 0 };
    }

    // Calculate the min total time based on the selected task groups
    const totalMin = selectedTaskGroupIds.reduce((sum, selectedTaskGroupdId) => {
      const taskGroup = taskGroups.find(taskGroup => taskGroup.id === selectedTaskGroupdId);
      return sum + (taskGroup?.tasks?.filter(task => task.status !== TaskStatus.COMPLETED)
                                      .reduce((taskSum, task) => taskSum + task.minSizeEstimate, 0) || 0);
    }, 0);

    // Calculate the max total time based on the selected task groups
    const totalMax = selectedTaskGroupIds.reduce((sum, key) => {
      const taskGroup = taskGroups.find(taskGroup => taskGroup.id === key);
      return sum + (taskGroup?.tasks?.filter(task => task.status !== TaskStatus.COMPLETED)
                                      .reduce((taskSum, task) => taskSum + task.maxSizeEstimate, 0) || 0);
    }, 0);

    return { min: totalMin, max: totalMax };
  };

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'checkbox',
      render: (text, record) => (
        <Checkbox
          onChange={(e) => handleCheckboxChange(record.id, e.target.checked)}
          checked={selectedTaskGroupIds.includes(record.id)}
        />
      ),
    },
    {
      title: 'Feature',
      dataIndex: 'name',
      key: 'feature',
      render: (text, record) => (
        <span>
          {text}
          <DeleteOutlined style={{paddingLeft: 8}} onClick={() => handleDeleteTaskGroup(record)} />
        </span>
      ),
    },
    {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        render: size => (
            <AntTooltip title={`${getTaskEstimateInDays(size, 'min')} - ${getTaskEstimateInDays(size, 'max')} days`}>
                <Tag color={getTaskSizeColor(size)}>
                    {size}
                </Tag>
            </AntTooltip>
        ),
    },
    {
      title: 'Tasks',
      dataIndex: 'tasks',
      key: 'tasks',
      render: (tasks, record) => (
        <div>
          {tasks?.map((task, index) =>
          {
            // Create a menu for the dropdown
            const sizeMenu = (
              <Menu onClick={(e) => handleUpdateTaskSize(task, e.key)}>
                {Object.keys(sizeToDays).map(size => (
                  <Menu.Item key={size}>{size}</Menu.Item>
                ))}
              </Menu>
            );

            return (
              <div
                  style={{
                      display: 'flex',
                      flexDirection: 'row',
                      color: getTaskStatusColor(task.status),
                      backgroundColor: highlightedTasks.includes(task.id) ? '#FFF8DC' : 'transparent',   // Highlight task if it's selected in the tooltip
                      borderRadius: '5px',
                      marginBottom: '2px',
                      textDecoration: task.status === 'COMPLETED' ? 'line-through' : 'none',
                      fontWeight: (task.status === 'BLOCKED' || task.status === 'QUEUED' || task.status === 'IN_PROGRESS') ? 'bold' : 'normal',
                  }}
                  ref={el => taskRefs.current[task.title] = el}
              >
                <AntTooltip title={task.status === TaskStatus.COMPLETED ? '0 days' : `${task.minSizeEstimate}-${task.maxSizeEstimate} days`}>
                  <Dropdown overlay={sizeMenu} trigger={['click']}>
                    <Tag color={getTaskSizeColor(task.sizeEstimate)} style={{cursor: 'pointer'}}>
                      {task.sizeEstimate} <DownOutlined />
                    </Tag>
                  </Dropdown>
                </AntTooltip>
                <AntTooltip title={formatStatusName(task?.status || '')}>
                  <div onClick={openTaskEditModal(task)} style={{ cursor: 'pointer' }}>
                    {task.title}
                  </div>
                </AntTooltip>
                <DeleteOutlined style={{paddingLeft: 8}} onClick={() => handleDeleteTask(task)} />
              </div>
            );
          })}

          <Row gutter={10} align="middle">
            <Col flex="auto">
              <TextArea
                rows={1}
                value={newTaskTitle[record.id] || ''}
                onChange={(e) => setNewTaskTitle({ ...newTaskTitle, [record.id]: e.target.value })}
                onPressEnter={() => handleAddTask(record.id, newTaskTitle[record.id])}
                placeholder="Enter new task name"
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => handleAddTask(record.id, newTaskTitle[record.id])}
              >
                Add Task
              </Button>
            </Col>
          </Row>

        </div>
      ),
    },
    {
      title: 'Total Estimate',
      key: 'estimate',
      render: (text, record) => {
        const totalMin = record.tasks?.reduce((sum, task) => sum + getLiveTaskSize(task, 'min'), 0) || 0;
        const totalMax = record.tasks?.reduce((sum, task) => sum + getLiveTaskSize(task, 'max'), 0) || 0;
        return `${formatTime(totalMin, estimateView)} - ${formatTime(totalMax, estimateView)}`;
      },
    },
  ];

  const getLiveTaskSize = (task, minMax) => {
    if (task.status === TaskStatus.COMPLETED) {
      return 0;
    }
    return getTaskEstimateInDays(task.sizeEstimate, minMax);
  };

  const formatTime = (time, unit) => {
    if (unit === 'days') return `${time} days`;
    if (unit === 'weeks') return `${Math.ceil(time / weekDays)} weeks`;
    return `${Math.ceil(time / 20)} months`;
  };

  const formatTotalTime = (totalTime) => {
    const totalWeeks = Math.ceil(totalTime / weekDays);
    const months = Math.floor(totalWeeks / 4);
    const weeks = totalWeeks % 4;
    return `${months} months, ${weeks} weeks\n(${totalWeeks} weeks)`;
  };

  const totalTime = getTotalTime();
  const isDaysView = estimateView === 'days';
  const selectedTaskGroups = taskGroups.filter(taskGroup => selectedTaskGroupIds.includes(taskGroup.id));

  const { dailyData, weeklyData, totalTimelineDays } =
    calculateTimelineData(selectedTaskGroups, getTaskEstimateInDays, weekDays, threads);

  return (
    <DndProvider backend={HTML5Backend}>
        <div style={{ padding: '0px', maxHeight: '100vh', overflowY: 'auto' }}>
            <div style={{ position: 'sticky', top: 0, background: '#fff', padding: '10px', borderBottom: '1px solid #ddd', zIndex: 1000 }}>

            {/* Sticky header */}
            <Row justify="space-between" align="middle">
                <Col>
                <span style={{ fontSize: '24px'}}><b>Estimated Time to Complete:</b> {formatTotalTime(totalTime.max)} </span>
                </Col>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Col style={{backgroundColor: '#f0f0f0', padding: '5px 10px 5px 0px', marginRight: '5px', borderRadius: '5px'}}>
                        <InputNumber min={1} value={threads} onChange={handleSetThreads} style={{ width: '50px', marginLeft: '10px' }} /> Threads
                    </Col>
                    <Divider type="vertical" />
                    <Col style={{backgroundColor: '#f0f0f0', padding: '5px 10px 5px 0px', marginRight: '5px', borderRadius: '5px'}}>
                        <InputNumber min={1} value={weekDays} onChange={setWeekDays} style={{ width: '50px', marginLeft: '10px' }} /> Days / Week
                    </Col>
                    <Divider type="vertical" />
                    <Col style={{backgroundColor: '#f0f0f0', padding: '5px 10px', marginRight: '5px', borderRadius: '5px'}}>
                    Display in <Select value={estimateView} onChange={setEstimateView} style={{ width: '100px' }}>
                        <Option value="days">Days</Option>
                        <Option value="weeks">Weeks</Option>
                    </Select>
                    </Col>
                </span>
            </Row>
            </div>

            {/* Timeline View */}
            <Row gutter={[16, 16]} style={{ margin: '20px' }}>
                <Col span={6}>
                    {/* Size Estimates Table that allows users to modify size estimate ranges */}
                    <Card title="Size Estimates" style={{ marginTop: '10px' }}>
                    {Object.keys(sizeToDays).map(size => (
                        <div key={size} style={{ marginBottom: '10px' }}>
                        <Tag color={getTaskSizeColor(size)}>{size}</Tag>
                        <InputNumber
                            min={0}
                            value={getTaskEstimateInDays(size, 'min')}
                            onChange={(value) => handleSizeDaysChange(size, 'min', value)}
                            style={{ width: '50px', marginLeft: '10px' }}
                        /> -
                        <InputNumber
                            min={0}
                            value={getTaskEstimateInDays(size, 'max')}
                            onChange={(value) => handleSizeDaysChange(size, 'max', value)}
                            style={{ width: '50px', marginLeft: '10px' }}
                        /> days
                        </div>
                    ))}
                    </Card>
                </Col>
                <Col span={18}>
                    {/* Timeline Overview that displays the number of tasks and features completed each day/week/etc. */}
                    <Card title="Timeline Overview">
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={isDaysView ? dailyData : weeklyData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                {isDaysView ?
                                    <XAxis
                                        dataKey='day'
                                        label={{ value: 'Days', position: 'insideBottomLeft', offset: -10 }}
                                        ticks={Array.from({ length: Math.ceil(totalTimelineDays / weekDays) }, (_, i) => (i + 1) * weekDays)}
                                    /> :

                                    <XAxis dataKey='week' label={{ value: 'Weeks', position: 'insideBottomLeft', offset: -10 }} />
                                }
                                <YAxis label={{ value: 'Tasks & Features Completed', angle: -90, position: 'insideBottomLeft' }} />
                                <RechartTooltip
                                    content={<CustomTooltip />}
                                />
                                <Legend />
                                <Line type="monotone" dataKey="featuresCompleted" name="Features Completed" stroke="#82ca9d" />
                                <Line type="monotone" dataKey="tasksCompleted" name="Tasks Completed" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>

            {/* Project Plan Table */}
            <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card title={`Project Plan (${selectedTaskGroups.length} Features)`}
                  extra={<div>
                          <p style={{ display: 'inline', marginRight: '10px' }}>Show Completed Tasks</p>
                          <Switch checked={showCompletedTasks} onChange={setShowCompletedTasks} />
                        </div>}
                  style={{ marginTop: '10px' }}
                >
                    <Table
                      rowKey="id"
                      columns={columns}
                      dataSource={taskGroups}
                      pagination={false}
                      expandable={{
                          expandedRowKeys,
                          onExpand: (expanded, record) => handleExpandRow(record.id),
                          expandedRowRender: record => (
                            record.tasks.length > 0 ?
                            <DependencyGraph
                                tasks={record.tasks}
                                defaultParallelThreads={threads}
                            /> :
                            <Text type="secondary">Add tasks to enable detailed feature-level planning.</Text>
                          ),
                      }}
                      components={{
                          body: {
                              row: DragableBodyRow,
                          },
                      }}
                      onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                    />
                    <Row gutter={10} align="middle">
                      <Col flex="auto">
                        <TextArea
                          rows={1}
                          value={newTaskGroupName}
                          onChange={(e) => setNewTaskGroupName(e.target.value)}
                          onPressEnter={() => handleAddTaskGroup()}
                          placeholder="Enter new feature name"
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => handleAddTaskGroup()}
                          block
                          style={{ marginTop: 16 }}
                        >
                          Add Feature
                        </Button>
                      </Col>
                      </Row>
                </Card>
            </Col>
            </Row>
            {/* Modal for adding new task group */}
            <Modal
              title="Add New Feature"
              visible={taskEditModalVisible}
              onOk={handleAddTaskGroup}
              onCancel={handleCancel}
              okText="Add"
            >
              <Input
                placeholder="Enter feature name"
                value={newTaskGroupName}
                onChange={(e) => setNewTaskGroupName(e.target.value)}
                onPressEnter={handleAddTaskGroup}
              />
            </Modal>
            {/* Modal for editing tasks */}
            <TaskModal
              isVisible={taskEditModalVisible}
              onCancel={() => setTaskEditModalVisibile(false)}
              onSubmit={handleEditTask}
              task={selectedTask}
              isEditMode={true}
              isDraftMode={true}
            />
        </div>
        </DndProvider>
  );
};

const calculateTimelineData = (selectedTaskGroups, getSizeFromEstimate, weekDays, threads) => {
  if(!selectedTaskGroups || selectedTaskGroups.length === 0) {
    return { dailyData: [], weeklyData: [], totalTimelineDays: 0 };
  }
    // calculate the total number of days to complete the project
    const daysToCompleteProject = selectedTaskGroups.reduce((sum, taskGroup) => {
      return sum + taskGroup.tasks?.reduce((taskSum, task) => {
        return taskSum + task.maxSizeEstimate;
      }, 0);
    }
    , 0) || 0;

    // Check if daysToCompleteProject is valid and if not (i.e. a new project), return an empty timeline
    if (daysToCompleteProject <= 0) {
      console.log('No days to complete the project calculated, possibly no tasks with valid size estimates.');
      return { dailyData: [], weeklyData: [], totalTimelineDays: 0 };
    }

    // instantiate the timelineData with the length of the project
    let dailyData = new Array(daysToCompleteProject+1).fill().map((_, index) => ({
      day: index + 1,
      tasksCompleted: 0,
      featuresCompleted: 0,
      summaryData: []
    }));

    // iterate through each feature and task to update the timelineData
    let currentDay = 1;
    selectedTaskGroups.forEach(taskGroup => {
        // iterate through each task in the feature, updating the timelineData
        // to the date the next task is completed
        let currentTask;
        taskGroup.tasks.forEach(task => {
          if(task.status === TaskStatus.COMPLETED) {
            return;
          }
            currentTask = task;
            currentDay += task.maxSizeEstimate;
            dailyData[currentDay - 1].tasksCompleted++;

            // add the task to the summaryData array for the day by feature
            const completedTasks = dailyData[currentDay - 1].summaryData;
            if(!completedTasks) dailyData[currentDay - 1].summaryData = [];
            dailyData[currentDay - 1].summaryData.push({
                feature: `${taskGroup.name}`,
                task: task.title,
                featureCompleted: false});
        });
        if (currentTask) {
          dailyData[currentDay - 1].featuresCompleted++;
          dailyData[currentDay - 1].summaryData = [{
              feature: `${taskGroup.name}`,
              task: currentTask.title,
              featureCompleted: true}
          ];
        }
    });

    // count tasks and features completed each week
    const weeklyData = [];
    let weeklyTasks = 0;
    let weeklyFeatures = 0;
    let summaryData = [];
    for (let i = 0; i < dailyData.length; i++) {
        weeklyTasks += dailyData[i].tasksCompleted;
        weeklyFeatures += dailyData[i].featuresCompleted;
        summaryData = summaryData.concat(dailyData[i].summaryData);
        if ((i + 1) % weekDays === 0) {
            weeklyData.push({
              week: (i + 1) / weekDays,
              tasksCompleted: weeklyTasks,
              featuresCompleted: weeklyFeatures,
              summaryData
            });
            // reset the weekly counters
            weeklyTasks = 0;
            weeklyFeatures = 0;
            summaryData = [];
        }
    }
    // add the last week if it's not a full week
    if (dailyData.length % weekDays !== 0) {
        weeklyData.push({ week: Math.ceil(dailyData.length / weekDays), tasksCompleted: weeklyTasks, featuresCompleted: weeklyFeatures, summaryData });
    }

    return { dailyData, weeklyData, totalTimelineDays: currentDay };
  };

const CustomTooltip = ({ active, payload }) => {
    useEffect(() => {
        console.log('CustomTooltip rendered');
    }, []);

    if (active && payload && payload.length) {
        const data = payload[0].payload;
        const summaryData = data.summaryData || [];

        if (summaryData.length === 0) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
                    <p style={{marginBottom: '0px'}}>No tasks completed.</p>
                </div>
            );
        }

        const completedTasksByTaskGroup = summaryData.reduce((acc, item) => {
            if (item && item.feature && item.task) {
                if (!acc[item.feature]) {
                    acc[item.feature] = [];
                }
                acc[item.feature].push(item.task);
            }
            return acc;
        }, {});

        const completedTaskGroups = Object.keys(completedTasksByTaskGroup).map(taskGroupId => {
            const tasks = completedTasksByTaskGroup[taskGroupId].join('\n- ');
            return (
                <div key={taskGroupId}>
                    <Text strong>{taskGroupId}</Text>
                    <br />
                    <Text>- {tasks.split('\n').map((item, idx) => (
                        <span key={idx}>{item}<br /></span>
                    ))}</Text>
                </div>
            );
        });

        // Remove null values and set the highlighted tasks
        // const currentHighlightedTasks = summaryData.filter(item => item?.task).map(item => item.task);
        //console.log('Highlighted Tasks:', currentHighlightedTasks); // 'Task 1', 'Task 2', 'Task 3', 'Task 4', 'Task 5
        // TODO: get this working
        // setHighlightedTasks(currentHighlightedTasks);

        return (
            <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
                <span>
                    <p style={{marginBottom: '0px'}}>{`Feature${data.featuresCompleted !== 1 ? 's' : ''} Completed: `}<b>{data.featuresCompleted}</b></p>
                    <p>{`Task${data.tasksCompleted !== 1 ? 's' : ''} Completed: `}<b>{data.tasksCompleted}</b></p>
                </span>
                {completedTaskGroups}
            </div>
        );
    }

    return null;
  };

export default ProjectTracker;
