import { Button, Dropdown, Menu } from "antd";
import { darkenColor, menuItemStyle as defaultStyle } from "../../../app/utils";

const modifiedMenuItemStyle = (color) => ({
    ...defaultStyle(color),
    backgroundColor: darkenColor(color, 50),
    color: 'white',
    // fontWeight: 'bold',
});

const TrackingSubcategoryMenu = ({ categories, categoryColors, selectedCategoryId, selectedSubcategory, onSelect, menuItemStyle = modifiedMenuItemStyle}) => {
    const selectedCategory = categories.find(cat => cat.id === selectedCategoryId);
    const subcategories = selectedCategory ? selectedCategory.subcategories : [];

    const menu = (
        <Menu>
            {subcategories.length > 0 ? (
                subcategories.map(subcategory => (
                    <Menu.Item key={subcategory} style={menuItemStyle(categoryColors[selectedCategoryId])}>
                        <a onClick={() => onSelect(subcategory)}>
                            {subcategory}
                        </a>
                    </Menu.Item>
                ))
            ) : (
                <Menu.Item key="none">None</Menu.Item>
            )}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button
                style={selectedSubcategory ? menuItemStyle(categoryColors[selectedCategoryId]) : {}}
            >
                {selectedSubcategory || 'Subcategory'}
            </Button>
        </Dropdown>
    );
};

export default TrackingSubcategoryMenu;