import { Input } from 'antd';

const { Search } = Input;

const TrackingSearchBar = ({ onSearch }) => {

    const handleSearchChange = (e) => {
        onSearch(e.target.value);
    };

    return (
        <Search
            placeholder="Search tracking items"
            onChange={handleSearchChange}
            style={{ marginBottom: 16 }}
        />
    );
};

export default TrackingSearchBar;