import React from "react";
import classes from "./coach-styles/SearchAndNewBar.module.css";
import type { Thread } from "./coach-utils/corpath-types";
import Fuse from "fuse.js";

interface Props {
  threads: Thread[];
  setChosenThread: React.Dispatch<React.SetStateAction<Thread | null>>;
  setSearchedThreads: React.Dispatch<React.SetStateAction<Thread[] | null>>;
}

function SearchAndNewBar({
  threads,
  setSearchedThreads,
  setChosenThread,
}: Props) {
  const [query, setQuery] = React.useState<string>("");

  const fuse = React.useMemo(() => {
    return new Fuse(threads, {
      threshold: 0.3,
      keys: ["lastQuestion", "lastResponse", "threadType"],
    });
  }, [threads]);

  React.useEffect(setSearchResults, [query]);

  function setSearchResults() {
    if (query.length === 0) {
      // reset to empty so it can display all threads
      setSearchedThreads([]);
      return;
    }
    // fuzzy search, based on threshhold. Doesn't need perfect match
    const threadResults: Thread[] = fuse.search(query).map((result) => {
      return result.item;
    });
    // set to 'null' if NO RESULTS
    setSearchedThreads(threadResults.length > 0 ? threadResults : null);
  }

  return (
    <div className={classes.container}>
      <div className={classes.searchBox}>
        <textarea
          className={classes.searchTextField}
          placeholder="Search your coaches..."
          // id="auto-expand-textarea"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
        />
        <img className={classes.searchBoxImg}  src="/search-icon.svg" alt='search-icon'/>
      </div>
      <img
        onClick={() => setChosenThread(null)}
        className={classes.pencil}
        src="/pencil-icon.png"
        alt='pencil-icon'
      />
    </div>
  );
}

export default SearchAndNewBar;
