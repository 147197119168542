import { Sender, CoachingCategory } from "../../../../models";

export interface Coach {
  type: CoachingCategory;
  img: string;
  color: string;
  name: string;
  bio: string;
  disclaimer?: string;
}
export interface Thread {
  userId: string;
  id: string;
  createdAt?: string;
  updatedAt?: string;
  threadType: string;
  lastQuestion: string;
  lastResponse: string;
}

export interface Message {
  threadID: string;
  id?: string;
  createdAt?: string;
  content: string;
  sender: Sender.AI_COACH | Sender.USER;
}

export interface GPTCallBody {
  message: string;
  reqType: 'chat' | "summarize"
}

export const coaches: Coach[] = [
  {
    type: CoachingCategory.MINDFULNESS,
    img: "/profile-clip-art/fitness.png",
    color: "#ec7063",
    name: "Jessica",
    bio: "Heya, I'm Jessica! I am a personal fitness coach who motivates clients to reach their fitness goals with customized workout plans, nutrition guidance, and ongoing support.",
  },
  {
    type: CoachingCategory.FINANCE,
    img: "/profile-clip-art/financial.png",
    color: "#57d68d",
    name: "Stuart",
    bio: "Stuart is a financial coach who guides clients to achieve their financial goals through tailored strategies, budgeting, and smart investment advice.",
    disclaimer:
      "* Stuart is not a licensed financial advisor. He is an AI chatbot. The information provided is for informational purposes only and does not constitute financial advice. Please consult with a certified financial professional before making any financial decisions.",
  },
  {
    type: CoachingCategory.HABITS_AND_BEHAVIOR_CHANGE,
    img: "/profile-clip-art/business4.png",
    color: "#14a085",
    name: "Leroy",
    bio: "Leroy is a productivity coach who helps individuals streamline their workflows and achieve their goals through effective time management and personalized strategies.",
  },
  {
    type: CoachingCategory.HEALTH_AND_WELLNESS,
    img: "/profile-clip-art/health3.png",
    color: "#ffb109",
    name: "Brian",
    bio: "Brian Thompson is a dedicated health coach who guides clients to achieve optimal wellness through personalized plans, balanced nutrition, and sustainable lifestyle changes.",
    disclaimer:
      "* Brian is not a doctor. He is an AI chatbot. The information provided here is for educational purposes only and should not be considered medical advice. Always consult a qualified healthcare professional for medical concerns.",
  },
  {
    type: CoachingCategory.CAREER_DEVELOPMENT,
    img: "/profile-clip-art/sales.png",
    color: "#3096d9",
    name: "Josh",
    bio: "Hey! I'm Josh. I am a results-driven sales coach who empowers professionals to boost their sales skills and achieve targets through strategic insights and effective techniques.",
  },
  {
    type: CoachingCategory.RELATIONSHIPS,
    img: "/profile-clip-art/relationship.png",
    color: "#af7ac5",
    name: "Sarah",
    bio: "Hello! My name is Sarah. I am a dedicated relationship coach who helps individuals and couples strengthen connections and improve communication with her expertise in relationship dynamics and personal growth.",
  },
];

// TODO: update ThreadType enum in models so they can be correctly mapped in this data above (ie: most of them are incorrectly temporarily set as "Habit" for now). Amplify push later and correct these.

export const coachNameMapping: Record<string,string> = {
  Mental_Health: "Mental Health",
  Finance: "Finance",
  Confidence: "Confidence",
  Relationships: "Relationships",
  Leadership: "Leadership",
  Spirituality: "Spirituality",
  Creativity: "Creativity",
  Vulnerability: "Vulnerability",
  Productivity: "Productivity",
  Time_Management: "Time Management",
  Career_Development: "Career",
  Health_and_Wellness: "Health",
  Mindfulness: "Mindfulness",
  Communication_Skills: "Communication",
  Habits_and_Behavior_Change: "Habits",
  Motivation_and_Inspiration: "Motivation",
  Parenting: "Parenting",
  Self_Discovery: "Self Discovery"
}