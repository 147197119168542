// Core React Imports
import { useEffect, useState, useCallback } from 'react';

// Third-party Libraries
import { DataStore } from 'aws-amplify';
import {
  Modal,
  List,
  Card,
  Button,
  Row,
  Col,
  Typography,
  Collapse,
  Select,
} from 'antd';
import { CheckCircleOutlined, UpOutlined, DownOutlined  } from '@ant-design/icons';

// Components and Utils
import {
  Task,
} from '../../../models';

// from actions 
import { fetchTasks, setTasks } from '../../../store/actions';
import TaskItem from './TaskItem';
import { formatStatusName, statusOrder, sortTasks as utilSortTasks, groupTasks as utilGroupTasks } from '../taskUtils';
import { useDispatch, useSelector } from 'react-redux';

// Assets
import './TaskList.css';
import taskIcon from '../../../assets/images/task.png'; // Cute task icon :D
import TaskModal from './TaskModal';
import demoTaskData from '../mocks/demoTaskData';
import { uniqueId } from 'lodash';

const { Panel } = Collapse;
const { Option } = Select;

const TaskList = () => {
  const dispatch = useDispatch();
  // const [tasks, setTasks] = useState([]);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false); // State for the task creation modal
  const [isContainerSmall, setIsContainerSmall] = useState(false);
  const [sortCriterion, setSortCriterion] = useState('status');
  const [sortOrder, setSortOrder] = useState('ascend');
  const [activeKeys, setActiveKeys] = useState();
  const [groupedTasks, setGroupedTasks] = useState({});

  const { theme, userId, isDemoMode, tasks } = useSelector((state) => state.app);

  const handleCollapseChange = (keys) => {
    setActiveKeys(keys);
  };

  const sortTasks = useCallback((tasks, criterion, order) => {
    return utilSortTasks(tasks, criterion, order);
  }, []);

  const groupTasks = useCallback((tasks, criterion) => {
    return utilGroupTasks(tasks, criterion);
  }, []);

  useEffect(() => {
      const sortedTasks = sortTasks(tasks, sortCriterion, sortOrder);
      const groupedTasks = groupTasks(sortedTasks, sortCriterion)
      setGroupedTasks(groupedTasks);
      setActiveKeys(Object.keys(groupedTasks));
  }, [tasks, sortCriterion, sortTasks, sortOrder, groupTasks]);

  useEffect(() => {  
    if(isDemoMode) {
      dispatch(setTasks(demoTaskData));
      setActiveKeys(demoTaskData.map((task) => task.status));
      return;
    }
  
    // Function to handle window resize
    const handleResize = () => {
      const taskListContainers = document.querySelectorAll('.task-list-container');
      taskListContainers.forEach((container) => {
        setIsContainerSmall(container.offsetWidth < 500);
      });
    };
  
    // Initial fetch
    dispatch(fetchTasks(userId));
    handleResize(); // Initial check on component mount
  
    // Set up DataStore subscription
    const subscription = DataStore.observe(Task).subscribe(() => {
      fetchTasks(userId);
    });
  
    // Set up window resize event listener
    window.addEventListener('resize', handleResize);
  
    // Clean up function
    return () => {
      subscription.unsubscribe();
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch, isDemoMode, userId]);  

  const updateTaskStatus = async (task, newStatus) => {
    if(isDemoMode) {
      console.info('Demo mode: Database task status update disabled');
      dispatch(setTasks(
        tasks.map((t) => {
          if (t.id === task.id) {
            return { ...t, status: newStatus };
          }
          return t;
        })
      ));
      return;
    }

    if (!statusOrder.includes(newStatus)) {
      throw new Error(`Invalid status (not defined in status ordering): ${JSON.stringify(newStatus)}`);
    }

    try {
      // load a new copy of the task to avoid version conflicts
      const mostRecentTask = await DataStore.query(Task, task.id);
      await DataStore.save(
        Task.copyOf(mostRecentTask, (updated) => {
          updated.status = newStatus;
          updated.updatedAt = null; // Set updatedAt to null to create a new revision
        })
      );
      dispatch(fetchTasks(userId));
    } catch (error) {
      console.error(`Error updating task status: ${error}`);
    }
  };

  const deleteTask = async (task) => {
    if(isDemoMode) {
      console.info('Demo mode: Database task deletion disabled');
      dispatch(setTasks(tasks.filter((t) => t.id !== task.id)));
      return;
    }

    try {
      // load a new copy of the task to avoid version conflicts
      const taskToDelete = await DataStore.query(Task, task.id);
      await DataStore.delete(taskToDelete);
      dispatch(fetchTasks(userId));
    } catch (error) {
      console.error(`Error updating task status: ${error}`);
    }
  };

  const fetchEmailContent = async (emailId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/email/get?emailId=${emailId}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setEmailContent(data.message);
      setIsEmailModalOpen(true);
    } catch (error) {
      console.error('Error fetching email content', error);
    }
  };

  // Function to create a new task
  const createNewTask = async (taskData) => {
    if(isDemoMode) {
      console.info('Demo mode: Database task creation disabled');
      dispatch(setTasks([...tasks, {...taskData, userId, id: uniqueId()}]));
      return;
    }

    try {
      const newTask = new Task({
        ...taskData, // Spread the taskData to include title, content, status,priority, energydelta, description
        userId,
        // sourceType: SourceType.USER,
      });

      await DataStore.save(newTask);
      dispatch(fetchTasks(userId));
    } catch (error) {
      console.error('Error creating new task:', error);
    }
  };

  const updateTask = async (updatedTask) => {
    if(isDemoMode) {
      console.info('Demo mode: Database task update disabled');
      dispatch(setTasks(
        tasks.map((t) => {
          if (t.id === updatedTask.id) {
            return { ...t, ...updatedTask };
          }
          return t;
        })
      ));
      return;
    }

    try {
      const originalTask = await DataStore.query(Task, updatedTask.id);
      if (!originalTask) {
        throw new Error(`Task with id ${updatedTask.id} not found`);
      }

      await DataStore.save(
        Task.copyOf(originalTask, (updated) => {
          Object.assign(updated, updatedTask);  // Merge updatedTask into updated
          updated.id = originalTask.id; // Ensure the ID is not changed
          updated.userId = originalTask.userId; // Ensure the userId is not changed
          updated.createdAt = originalTask.createdAt; // Ensure the createdAt is not changed
          updated.updatedAt = null; // Set updatedAt to null to create a new revision
        })
      );
      
      dispatch(fetchTasks(userId)); // Refresh tasks after update
    } catch (error) {
      console.error(`Error updating task: ${error}`);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'ascend' ? 'descend' : 'ascend'));
  };

  return (
    <div
      className={`task-list-container ${theme} ${
        isContainerSmall ? 'small-container' : ''
      }`}
    >
      <Row align="middle" style={{ padding: '1em 0' }}>
        <Col>
          <CheckCircleOutlined
            style={{ fontSize: '24px', marginRight: '10px' }}
          />
        </Col>
        <Col>
          <Typography.Title level={4}>My Tasks</Typography.Title>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Button
            type="primary"
            style={{ backgroundColor: theme.buttonBackgroundColor, color: theme.buttonTextColor }}
            icon={<img src={taskIcon} alt="Add" style={{color:theme.buttonTextColor}} />}
            onClick={() => setIsTaskModalOpen(true)}
          >
            Add Task
          </Button>
        </Col>
        <Col>
          <Row gutter={[8, 8]}>
            <Col>
              <Select
                defaultValue="status"
                onChange={(value) => setSortCriterion(value)}
                style={{ width: 200 }}
              >
                <Option value="status">Status</Option>
                <Option value="priority">Priority</Option>
                <Option value="dueDate">Due Date</Option>
                <Option value="energyImpact">Energy Impact</Option>
                <Option value="duration">Duration</Option>
                <Option value="source">Source</Option>
              </Select>
            </Col>
            <Col>
              <Button
                type="text"
                icon={sortOrder === 'ascend' ? <UpOutlined style={{
                  backgroundColor: theme.buttonBackgroundColor,
                  color: theme.buttonTextColor,
                }}/> : <DownOutlined style={{
                  backgroundColor: theme.buttonBackgroundColor,
                  color: theme.buttonTextColor,
                }}/>}
                onClick={toggleSortOrder}
                style={{
                  backgroundColor: theme.buttonBackgroundColor,
                  color: theme.textColor,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Collapse
        activeKey={activeKeys}
        onChange={handleCollapseChange}
        expandIconPosition="end"
        className="collapse-card"
      >
        {Object.entries(groupedTasks).map(([key, groupedTasks]) => (
          <Panel
            key={key}
            header={<span className="task-card-status-title" style={{color: theme.textColor}}>{formatStatusName(key)}</span>}
          >
            <Card bordered={false} className="task-card">
              <List
                itemLayout="horizontal"
                dataSource={groupedTasks}
                renderItem={(task) => (
                  <List.Item key={task.id}>
                    <TaskItem
                      task={task}
                      onEmailClick={() => fetchEmailContent(task.sourceId)}
                      updateTaskStatus={(newStatus) =>
                        updateTaskStatus(task, newStatus)
                      }
                      deleteTask={() => deleteTask(task)}
                      updateTask={updateTask}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Panel>
        ))}
      </Collapse>

      <TaskModal
        isVisible={isTaskModalOpen}
        onCancel={() => setIsTaskModalOpen(false)}
        onSubmit={createNewTask}
        isEditMode={false}
      />
      <Modal
        title="Email Content"
        open={isEmailModalOpen}
        onCancel={() => setIsEmailModalOpen(false)}
        footer={[
          <Button key="close" onClick={() => setIsEmailModalOpen(false)}>
            Close
          </Button>,
        ]}
      >
        {emailContent}
      </Modal>
    </div>
  );
};

export default TaskList;
