// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TaskStatus = {
  "ARCHIVED": "ARCHIVED",
  "NOT_STARTED": "NOT_STARTED",
  "IN_PROGRESS": "IN_PROGRESS",
  "COMPLETED": "COMPLETED",
  "BLOCKED": "BLOCKED",
  "PENDING_APPROVAL": "PENDING_APPROVAL",
  "DRAFT": "DRAFT"
};

const Priority = {
  "HIGH": "High",
  "MEDIUM": "Medium",
  "LOW": "Low"
};

const ProjectPlanStatus = {
  "DRAFT": "Draft",
  "PENDING_APPROVAL": "Pending_Approval",
  "APPROVED_PENDING_START": "Approved_Pending_Start",
  "IN_PROGRESS": "In_Progress",
  "BLOCKED": "Blocked",
  "COMPLETE": "Complete",
  "CANCELLED": "Cancelled",
  "SHELVED": "Shelved"
};

const SizeEstimateUnit = {
  "HOURS": "Hours",
  "DAYS": "Days",
  "WEEKS": "Weeks",
  "MONTHS": "Months",
  "YEARS": "Years"
};

const ProviderType = {
  "GOOGLE": "Google",
  "MICROSOFT": "Microsoft",
  "APPLE": "Apple",
  "OURA": "Oura",
  "SLACK": "Slack",
  "SALESFORCE": "Salesforce"
};

const ServiceType = {
  "CONTACTS": "Contacts",
  "EMAIL": "Email",
  "CALENDAR": "Calendar",
  "HEALTH": "Health",
  "CHAT": "Chat",
  "VIDEO": "Video",
  "CRM": "CRM"
};

const EnergyImpact = {
  "VERY_DRAINING": "VERY_DRAINING",
  "DRAINING": "DRAINING",
  "SLIGHTLY_DRAINING": "SLIGHTLY_DRAINING",
  "NO_DIFFERENCE": "NO_DIFFERENCE",
  "RECHARGING": "RECHARGING"
};

const CoachingCategory = {
  "MENTAL_HEALTH": "Mental_Health",
  "FINANCE": "Finance",
  "CONFIDENCE": "Confidence",
  "RELATIONSHIPS": "Relationships",
  "LEADERSHIP": "Leadership",
  "SPIRITUALITY": "Spirituality",
  "CREATIVITY": "Creativity",
  "VULNERABILITY": "Vulnerability",
  "PRODUCTIVITY": "Productivity",
  "TIME_MANAGEMENT": "Time_Management",
  "CAREER_DEVELOPMENT": "Career_Development",
  "HEALTH_AND_WELLNESS": "Health_and_Wellness",
  "MINDFULNESS": "Mindfulness",
  "COMMUNICATION_SKILLS": "Communication_Skills",
  "HABITS_AND_BEHAVIOR_CHANGE": "Habits_and_Behavior_Change",
  "MOTIVATION_AND_INSPIRATION": "Motivation_and_Inspiration",
  "PARENTING": "Parenting",
  "SELF_DISCOVERY": "Self_Discovery"
};

const Sender = {
  "USER": "USER",
  "AI_COACH": "AI_COACH"
};

const { TaskType, Task, ProjectPlan, LinkedAccount, WorkStyleProfile, EnergyProfile, TrackingItem, TrackingCategory, DailyTrackingEntry, Corpathians, Thread, Message, TaskGroup, SizeEstimate, EnergyMapping, Schedule, DayAndTimeRanges, TimeRange, Book, TrackingEntry } = initSchema(schema);

export {
  TaskType,
  Task,
  ProjectPlan,
  LinkedAccount,
  WorkStyleProfile,
  EnergyProfile,
  TrackingItem,
  TrackingCategory,
  DailyTrackingEntry,
  Corpathians,
  Thread,
  Message,
  TaskStatus,
  Priority,
  ProjectPlanStatus,
  SizeEstimateUnit,
  ProviderType,
  ServiceType,
  EnergyImpact,
  CoachingCategory,
  Sender,
  TaskGroup,
  SizeEstimate,
  EnergyMapping,
  Schedule,
  DayAndTimeRanges,
  TimeRange,
  Book,
  TrackingEntry
};