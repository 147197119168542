// import moment from "moment";
import moment from "moment-timezone";

export function formatMysqlDatetime(mysqlUTCDateTime: string | null | undefined): {
  pstTimeString: string;
  pstTimeMomentObj: moment.Moment;
  pstSince: string;
  dateObj: Date;
  epochTimeMS: number;
} {
  // Original UTC time
  const utcTime = moment(mysqlUTCDateTime ?? undefined);

  // Convert to outputs
  const pstTimeString = utcTime.tz("America/Los_Angeles").format("lll");
  const pstTimeMomentObj = moment(pstTimeString, "lll");
  const pstSince = pstTimeMomentObj.fromNow();
  const dateObj = new Date(mysqlUTCDateTime ?? '');
  const epochTimeMS = new Date(mysqlUTCDateTime ?? '').getTime();

  return { pstTimeString, pstSince, pstTimeMomentObj, dateObj, epochTimeMS };
}