import React, { useState } from 'react';
import { Button, Tooltip,Form, Modal } from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
  CheckOutlined,
  StopOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { TaskStatus } from '../../../models';
import './taskItem.css';
import TaskModal from './TaskModal';

import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const tooltipStyle = {
  backgroundColor: '#fff',
  color: '#000',
  border: '1px solid #d9d9d9',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
  padding: '4px 8px',
  fontSize: '14px',
};

const TaskItem = React.memo(function TaskItem({
  task,
  onEmailClick,
  updateTaskStatus,
  deleteTask,
  updateTask,
}) {
  const { theme } = useSelector((state) => state.app);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
   const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [form] = Form.useForm();

  const taskStatusClass = `task-item ${task?.status?.toLowerCase().replace('_', '-')}`;

  const handleEmailClick = (e, emailId) => {
    e.preventDefault();
    e.stopPropagation();
    onEmailClick(emailId);
  };

  const handleDeleteTask = (e) => {
    e.stopPropagation();
    setIsDeleteModalVisible(true);
  };

  const confirmDeleteTask = () => {
    deleteTask(task);
    setIsDeleteModalVisible(false);
  };

  const cancelDeleteTask = () => {
    setIsDeleteModalVisible(false);
  };

  const handleButtonAction = (e, action) => {
    e.stopPropagation();
    action();
  };

  const showModal = () => {
    setIsEditModalVisible(true);
    form.setFieldsValue({
      title: task.title,
      description: task.description,
    });
  };

  const handleFormSubmit = (values) => {
    updateTask({ ...task, ...values });
    setIsEditModalVisible(false);
  };

  const handleCancel = () => {
    setIsEditModalVisible(false);
  };


  const formatDueDate = (dueDate) => {
    const today = moment();
    const lastWeek = today.clone().subtract(1, 'week');

    if (moment(dueDate).isSame(today, 'week')) {
      return moment(dueDate).format('dddd');
    } else if (moment(dueDate).isSame(today, 'day')) {
      return 'Today';
    } else if (moment(dueDate).isSame(today.clone().add(1, 'day'), 'day')) {
      return 'Tomorrow';
    } else if (moment(dueDate).isSame(today.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    } else if (
      moment(dueDate).isBefore(today) &&
      moment(dueDate).isSame(lastWeek, 'week')
    ) {
      return 'Last ' + moment(dueDate).format('dddd');
    } else {
      return moment(dueDate).format('MMM D');
    }
  };

  const renderButtons = () => {
    const buttonProperties = {
      [TaskStatus.PENDING_APPROVAL]: {
        type: 'primary',
        icon: <CheckCircleOutlined />,
        tooltip: 'Approve Task',
        action: () => updateTaskStatus(TaskStatus.NOT_STARTED)
      },
      [TaskStatus.NOT_STARTED]: {
        type: 'primary',
        icon: <PlayCircleOutlined />,
        tooltip: 'Start Task',
        action: () => updateTaskStatus(TaskStatus.IN_PROGRESS),
      },
      [TaskStatus.IN_PROGRESS]: [
        {
          type: 'success',
          icon: <CheckOutlined />,
          tooltip: 'Complete Task',
          action: () => updateTask({ ...task, completedAt: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), status: TaskStatus.COMPLETED }),
        },
        {
          type: 'danger',
          icon: <StopOutlined />,
          tooltip: 'Stop Task',
          action: () => updateTaskStatus(TaskStatus.NOT_STARTED),
        },
      ],
      [TaskStatus.COMPLETED]: {
        type: 'success',
        icon: <SaveOutlined />,
        tooltip: 'Archive Task',
        action: () => updateTask({ ...task, archived: true }),
      },
      [TaskStatus.BLOCKED]: {
        type: 'primary',
        icon: <ReloadOutlined />,
        tooltip: 'Unblock Task',
        action: () => updateTaskStatus(TaskStatus.IN_PROGRESS),
      },
    };

    const buttonSet = buttonProperties[task.status];

    if (!buttonSet) return null;

    const renderButton = ({ type, icon, tooltip, action }, index) => (
      <Tooltip
        key={index}
        title={tooltip}
        overlayStyle={tooltipStyle}
        placement="topLeft"
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0.1}
      >
        <Button
          type={type}
          style={{ color: theme.taskCheckColor }}
          icon={icon}
          onClick={(e) => handleButtonAction(e, action)}
          className="icon-button"
        />
      </Tooltip>
    );

    return Array.isArray(buttonSet)
      ? buttonSet.map(renderButton)
      : renderButton(buttonSet, 0);
  };

  return (
    <>
      <div className={taskStatusClass} style={{backgroundColor: theme.taskListBackgroundColor }}>
        <div className="content">
          <div className="content-row">
            <div className="content-first-container">
              <div className='button-container'>
                {renderButtons()}
              </div>
              <Tooltip title={task.title}>
                <div className="content-title" >
                  <span className="text-truncate" style={{ cursor: 'pointer' }} onClick={showModal}>{task.title}</span>
                </div>
              </Tooltip>
            </div>
            <div className="content-details">
              {/* <div className="description">{task.description}</div> */}
              <div className="additional-details">
                <div className="due-date">
                  {task.dueDate ? formatDueDate(task.dueDate) : ''}
                </div>
              </div>
              <div className="status">
              <Tooltip
                  key="deleteTask"
                  title="Delete"
                  overlayStyle={tooltipStyle}
                  placement="topLeft"
                  mouseEnterDelay={0.1}
                  mouseLeaveDelay={0.1}
                >
                  <Button
                    style={{ color: theme.taskDeleteColor }}
                    icon={<DeleteOutlined />}
                    onClick={handleDeleteTask}
                    className="icon-button"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TaskModal
        isVisible={isEditModalVisible}
        onCancel={handleCancel}
        onSubmit={handleFormSubmit}
        task={task}
        isEditMode={true}
      />
      <Modal
        title="Confirm Deletion"
        open={isDeleteModalVisible}
        onOk={confirmDeleteTask}
        onCancel={cancelDeleteTask}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this task?</p>
      </Modal>
    </>
  );
});

export default TaskItem;
