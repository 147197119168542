
// 3rd-party Libraries
import { useSelector, useDispatch } from 'react-redux';
import { setTheme, toggleChat, setChatWidth } from '../store/actions';
import { Auth } from 'aws-amplify';

// 3rd-party Components
import { Button, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { RightOutlined } from '@ant-design/icons';

// Components and Utils
import { themes } from './themes/themes';
import TaskList from '../features/tasks/components/TaskList';
import Calendar from '../features/calendar/Calendar';
import { AppDispatch, RootState } from '../store';

const Home = () => {
    const { theme, themeName, userId, username, chatOpen} = useSelector((state: RootState) => state.app);

    const homeStyles: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        color: theme.textColor,
    };

    const dispatch: AppDispatch = useDispatch();

    const handleThemeChange = (theme: string) => {
        dispatch(setTheme(theme));
    };

    const handleToggleChat = () => {
        dispatch(toggleChat());
        dispatch(setChatWidth(chatOpen? 0 : window.innerWidth * 0.2));
    };

    const handleSignOut = async () => {
        try {
          await Auth.signOut();
          // Handle post-sign-out logic, like redirecting to a login page
          window.location.href = '/';
        } catch (error) {
          console.error('Error signing out: ', error);
        }
      };

    return (
          <div style={homeStyles} >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                marginTop: '25px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: '16px',
                backgroundColor: 'transparent',
                color: themeName === 'dark'? 'white' : 'black',
              }}>

              {/* Theme switcher */}
              <div style={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 20,
              }}>
                {/* <h3>Theme:</h3> */}
                <Select
                  value={themeName}
                  onChange={handleThemeChange}
                  style={{
                    width: '100%',
                    minWidth: 120,
                    marginLeft: '10px',
                    borderRadius: '5px',
                    backgroundColor: theme.siderBackgroundColor,
                  }}
                >
                  {
                    Object.keys(themes).map((key) => (
                      <Option key={key} value={key} >
                        {key.charAt(0).toUpperCase() + key.slice(1)} Theme
                      </Option>
                    ))
                  }
                </Select>
              </div>
              {/* Header and sign out button */}
              <h1 style={ {
                color: theme.textColor,
              }}>Welcome {username}!</h1>
              <Button style={{backgroundColor: theme.buttonBackgroundColor, color: theme.buttonTextColor}} onClick={handleSignOut}> Sign Out </Button>
            </div>

            {/* Add content for home */}
            {/* Add taskListComponent on the left to occupy 20percent  then the calendar on the right*/}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '30%',
                  backgroundColor: 'transparent',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                 }}
                >
                    <TaskList />
                    <RightOutlined
                        style={{
                            fontSize: '30px',
                            cursor: 'pointer',
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                        }}
                        onClick={handleToggleChat}
                    />
              </div>
              <div style={{ width: '70%' }}>
                <Calendar />
              </div>
            </div>
          </div>
    );
};

export default Home;