import { Input, Select, Tooltip, Button } from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { taskStatusLabels, getTaskStatusColor, formatDuration, getFeatureName } from '../taskUtils';
import { getColorByPriority } from '../../../app/utils';
import {Priority}  from '../../../models';
import dayjs from 'dayjs';
const { Option } = Select;

export const getColumnDefinitions = ({
  statusSorter,
  prioritySorter,
  editingCell,
  newTasks,
  handleNewTaskChange,
  addNewTaskRow,
  handleAddTask,
  tempEditValues,
  handleCellChange,
  handleCellBlur,
  handleCellClick,
  handleEditTask,
  userId,
  assigneeOptions,
  projectNamesOptions,
  deleteTask
}) => [
        { 
            title: 'Title', 
            dataIndex: 'title', 
            key: 'title',
            fixed: 'left',
            width: 250,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => {
                if(a.id === "new") return;
                if(b.id === "new") return;
                return a.title.localeCompare(b.title);
                
            },
            render: (text, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'title';

                if (isNewRow) {
                    return (
                        <Input 
                            value={newTasks[record.group]?.title || ''}
                            onChange={(e) => {
                                if (!newTasks[record.group]?.title) {
                                    addNewTaskRow(record.group);
                                }
                                handleNewTaskChange(e.target.value, 'title', record.group);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleAddTask(record.group);
                                }
                            }}
                            placeholder="New task title"
                        />
                    );
                }

                if (isEditing) {
                    return (
                        <Input
                            value={tempEditValues[record.id]?.title ?? ""}
                            onChange={(e) => handleCellChange(record.id, 'title', e.target.value)}
                            onBlur={() => handleCellBlur(record.id, 'title')}
                            onPressEnter={() => handleCellBlur(record.id, 'title')}
                            autoFocus
                        />
                    );
                }

                return (
                    <div
                        onClick={() => handleCellClick(record.id, 'title', text)}
                        onDoubleClick={() => handleEditTask(record)}
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        <Tooltip placement="topLeft" title={text}>
                                {text}
                        </Tooltip>
                    </div>
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            ellipsis: true,
            sorter: statusSorter,
            render: (status, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'status';
                if (isNewRow) {
                    return (
                        <Select
                            value={newTasks[record.group]?.status || ''}
                            onChange={(value) => handleNewTaskChange(value, 'status', record.group)}
                            style={{ width: 150 }}
                        >
                            {Object.keys(taskStatusLabels).map(key => (
                                <Option key={key} value={key}>{taskStatusLabels[key]}</Option>
                            ))}
                        </Select>
                    );
                }


                if (isEditing) {
                    return (
                        <Select
                            value={tempEditValues[record.id]?.status ?? status}
                            onChange={(value) => handleCellChange(record.id, 'status', value)}
                            onBlur={() => handleCellBlur(record.id, 'status')}
                            style={{ width: 150 }}
                            autoFocus
                        >
                            {Object.keys(taskStatusLabels).map(key => (
                                <Option key={key} value={key}>{taskStatusLabels[key]}</Option>
                            ))}
                        </Select>
                    );
                }

                return (
                    <span 
                        onClick={() => handleCellClick(record.id, 'status', status)}
                        style={{ 
                            color: getTaskStatusColor(status), 
                            fontWeight: 'bold', 
                            cursor: 'pointer', 
                            minWidth: '50px', 
                            minHeight: '20px',display:'flex' }}
                    >
                        {taskStatusLabels[status]}
                    </span>
                );
            },
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            width: 100,
            ellipsis: true,
            sorter: prioritySorter,
            render: (priority, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'priority';

                if (isNewRow) {
                    return (
                        <Select
                            value={newTasks[record.group]?.priority || ''}
                            onChange={(value) => handleNewTaskChange(value, 'priority', record.group)}
                            style={{ width: 95 }}
                        >
                            <Option value="High">{Priority.High}</Option>
                            <Option value="Medium">{Priority.MEDIUM}</Option>
                            <Option value="Low">{Priority.LOW}</Option>
                        </Select>
                    );
                }
                if (isEditing) {
                    return (
                        <Select
                            value={tempEditValues[record.id]?.priority ?? ""}
                            onChange={(value) => handleCellChange(record.id, 'priority', value)}
                            onBlur={() => handleCellBlur(record.id, 'priority')}
                            style={{ width: 95 }}
                            autoFocus
                        >
                            <Option value={Priority.HIGH}>{Priority.HIGH}</Option>
                            <Option value={Priority.MEDIUM}>{Priority.MEDIUM}</Option>
                            <Option value={Priority.LOW}>{Priority.LOW}</Option>
                        </Select>
                    );
                }

                return (
                    <span 
                        onClick={() => handleCellClick(record.id, 'priority', priority)}
                        style={{ 
                            color: getColorByPriority(priority), 
                            fontWeight: 'bold', 
                            cursor: 'pointer', 
                            minWidth: '50px', 
                            minHeight: '20px',
                            display:'flex' }}
                    >
                        {priority}
                    </span>
                );
            },
        },
        { 
            title: 'Assignee', 
            dataIndex: 'assignee', 
            key: 'assignee',
            width: 150,
            ellipsis: true,
            sorter: (a, b) =>{
                if(a.id === "new") return;
                if(b.id === "new") return;
                if(!a?.assignee) return -1;
                if(!b?.assignee) return 1;
                return  a.assignee.localeCompare(b.assignee)
            },
            render: (assignee, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'assignee';

                if(isNewRow){
                    return(
                    <Select
                        value={newTasks[record.group]?.assignee || userId} // Ensure default assignee is set
                        onChange={(value) => handleNewTaskChange(value, 'assignee', record.group)}
                        style={{ width: 150 }}
                    >
                        {assigneeOptions.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                ) }

                 if (isEditing) {
                    return (
                        <Select
                            value={tempEditValues[record.id]?.assignee ?? ""}
                            onChange={(value) => handleCellChange(record.id, 'assignee', value)}
                            onBlur={() => handleCellBlur(record.id, 'assignee')}
                            style={{ width: 150 }}
                            autoFocus
                        >
                            {assigneeOptions.map(option => (
                                <Option key={option.value} value={option.value}>{option.label}</Option>
                            ))}
                        </Select>
                    );
                }

                return (
                    <span onClick={() => handleCellClick(record.id, 'assignee', assignee)} 
                        style={{ cursor: 'pointer',
                            minWidth: '50px', 
                            minHeight: '20px',
                            display:'flex'
                         }}>
                        {assigneeOptions.find(item => item.value === assignee)?.label}
                    </span>
                );
            }
        },
        {
            title: 'Due Date', 
            dataIndex: 'dueDate', 
            key: 'dueDate',
            width: 200,
            ellipsis: true,
            sorter: (a, b) => {
                if(a.id === "new") return;
                if(b.id === "new") return;
                const dateA = new Date(a.dueDate).getTime() || new Date(0).getTime();
                const dateB = new Date(b.dueDate).getTime() || new Date(0).getTime();
                return  dateA - dateB;
            },
            render: (dueDate, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'dueDate';
                if (isNewRow) {
                    return (
                        <Input 
                            type="datetime-local"
                            value={newTasks[record.group]?.dueDate || ''}
                            onChange={(e) => handleNewTaskChange(e.target.value, 'dueDate', record.group)}
                        />
                    );
                }
                
                if (isEditing) {
                    return (
                        <Input
                            type="datetime-local"
                            value={tempEditValues[record.id]?.dueDate ?? dueDate}
                            onChange={(e) => handleCellChange(record.id, 'dueDate', e.target.value)}
                            onBlur={() => handleCellBlur(record.id, 'dueDate')}
                            autoFocus
                        />
                    );
                }

                return (
                    <span onClick={() => handleCellClick(record.id, 'dueDate', dueDate)} 
                        style={{ cursor: 'pointer', 
                            minWidth: '50px', 
                            minHeight: '20px',
                            display:'flex'
                    }}>
                        {dueDate ? dayjs(dueDate).format('(ddd) MMMM Do, h:mm A') : null}
                    </span>
                );
            },
        },
        {
            title: 'Duration', 
            dataIndex: 'duration', 
            key: 'duration',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => {
                if(a.id === "new") return;
                if(b.id === "new") return;
                return  a.duration - b.duration
            },
            render: (duration, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'duration';
                if (isNewRow) {
                    return (
                        <Input 
                            type="number"
                            value={newTasks[record.group]?.duration || ''}
                            onChange={(e) => handleNewTaskChange(Number(e.target.value), 'duration', record.group)}
                            placeholder="Duration in minutes"
                        />
                    );
                }
                
                if (isEditing) {
                    return (
                        <Input
                            type="number"
                            value={tempEditValues[record.id]?.duration ?? duration}
                            onChange={(e) => handleCellChange(record.id, 'duration', Number(e.target.value))}
                            onBlur={() => handleCellBlur(record.id, 'duration')}
                            autoFocus
                        />
                    );
                }

                return (
                    <span onClick={() => handleCellClick(record.id, 'duration', duration)} 
                        style={{ 
                            cursor: 'pointer',
                            minWidth: '50px', 
                            minHeight: '20px',
                            display:'flex'
                    }}>
                        {formatDuration(duration)}
                    </span>
                );
            },
        },
        {
            title: 'Project',
            dataIndex: 'projectId',
            key: 'project',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => {
                if(a.id === "new") return;
                if(b.id === "new") return;

                const projectA = projectNamesOptions.find(p => p.id === a.projectId)?.name || '';
                const projectB = projectNamesOptions.find(p => p.id === b.projectId)?.name || '';
                return projectA.localeCompare(projectB);
            },
            render: (projectId, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'projectId';

                if (isNewRow) {
                    return (
                        <Select
                            value={newTasks[record.group]?.projectId || ''}
                            onChange={(value) => handleNewTaskChange(value, 'projectId', record.group)}
                            style={{ width: 148 }}
                        >
                            {projectNamesOptions.map(project => (
                                <Option key={project.id} value={project.id}>{project.name}</Option>
                            ))}
                        </Select>
                    );
                }
                if (isEditing) {
                    return (
                        <Select
                            value={tempEditValues[record.id]?.projectId ?? projectId}
                            onChange={(value) => handleCellChange(record.id, 'projectId', value)}
                            onBlur={() => handleCellBlur(record.id, 'projectId')}
                            style={{ width: 148 }}
                            autoFocus
                        >
                            {projectNamesOptions.map(project => (
                                <Option key={project.id} value={project.id}>{project.name}</Option>
                            ))}
                        </Select>
                    );
                }

                return (
                    <span onClick={() => handleCellClick(record.id, 'projectId', projectId)} 
                    style={{ 
                        cursor: 'pointer',
                        minWidth: '50px', 
                        minHeight: '20px',
                        display:'flex'
                    }}>
                        {projectNamesOptions.find(p => p.id === projectId)?.name || ''}
                    </span>
                );
            },
        },
        {
            title: 'Feature',
            dataIndex: 'taskGroupId',
            key: 'feature',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => {
                if(a.id === "new") return;
                if(b.id === "new") return;

                const featureA = getFeatureName(projectNamesOptions, a.taskGroupId);
                const featureB = getFeatureName(projectNamesOptions, b.taskGroupId);
                return featureA.localeCompare(featureB);
            },
            render: (taskGroupId, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'taskGroupId';
                const selectedProjectId = newTasks[record.group]?.projectId;

                if (isNewRow) {
                    const filteredFeatures = projectNamesOptions
                        .find(project => project.id === selectedProjectId)?.feature || [];

                    return (
                        <Select
                            value={newTasks[record.group]?.taskGroupId || ''}
                            onChange={(value) => handleNewTaskChange(value, 'taskGroupId', record.group)}
                            style={{ width: 148 }}
                            disabled={!selectedProjectId} // Disable if no project selected
                        >
                            {filteredFeatures.map(feature => (
                                <Option key={feature.id} value={feature.id}>
                                    {feature.name}
                                </Option>
                            ))}
                        </Select>
                    );
                }
                
                if (isEditing) {
                    const filteredFeatures = projectNamesOptions
                        .find(project => project.id === selectedProjectId)?.feature || [];

                    return (
                        <Select
                            value={tempEditValues[record.id]?.taskGroupId ?? taskGroupId}
                            onChange={(value) => handleCellChange(record.id, 'taskGroupId', value)}
                            onBlur={() => handleCellBlur(record.id, 'taskGroupId')}
                            style={{ width: 148 }}
                            autoFocus
                            disabled={!selectedProjectId}
                        >
                            {filteredFeatures.map(feature => (
                                <Option key={feature.id} value={feature.id}>{feature.name}</Option>
                            ))}
                        </Select>
                    );
                }

                return (
                    <span onClick={() => handleCellClick(record.id, 'taskGroupId', taskGroupId)} 
                        style={{ 
                            cursor: 'pointer',
                            minWidth: '50px', 
                            minHeight: '20px',
                            display:'flex'
                    }}>
                        {getFeatureName(projectNamesOptions, taskGroupId)}
                    </span>
                );
            },
        },
        {
            title: 'Size',
            dataIndex:'sizeEstimate',
            key:'size',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => {
                if(a.id === "new") return;
                if(b.id === "new") return;
                return  a.size - b.size
            },
            render: (sizeEstimate, record) => {
                const isNewRow = record.id === 'new';
                const isEditing = editingCell?.id === record.id && editingCell?.field === 'sizeEstimate';

                if (isNewRow) {
                    return (
                        <Select
                            value={newTasks[record.group]?.sizeEstimate || ''} // Ensure default assignee is set
                            onChange={(value) => handleNewTaskChange(value, 'sizeEstimate', record.group)}
                            style={{ width: 80 }}
                        >
                            <Option value="XS">XS</Option>
                            <Option value="S">S</Option>
                            <Option value="M">M</Option>
                            <Option value="L">L</Option>
                            <Option value="XL">XL</Option>
                        </Select>
                    );
                }
                if (isEditing) {
                    return (
                        <Select
                            value={tempEditValues[record.id]?.sizeEstimate ?? sizeEstimate}
                            onChange={(value) => handleCellChange(record.id, 'sizeEstimate', value)}
                            onBlur={() => handleCellBlur(record.id, 'sizeEstimate')}
                            style={{ width: 80 }}
                            autoFocus
                        >
                            <Option value="XS">XS</Option>
                            <Option value="S">S</Option>
                            <Option value="M">M</Option>
                            <Option value="L">L</Option>
                            <Option value="XL">XL</Option>
                        </Select>
                    );
                }

                return (
                    <span onClick={() => handleCellClick(record.id, 'sizeEstimate', sizeEstimate)} 
                        style={{ cursor: 'pointer',
                            minWidth: '50px', 
                            minHeight: '20px',
                            display:'flex' 
                        }}>
                        {sizeEstimate}
                    </span>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 80,
            ellipsis: true,
            render: (_, record) => {
                if (record.id === 'new') {
                    return (
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => handleAddTask(record.group)}
                            type="primary"
                        >
                            Add
                        </Button>
                    );
                }

                return (
                    <div>
                        <Tooltip title="Delete">
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={(event) => deleteTask(record, event)}
                            />
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => handleEditTask(record)}
                            />
                        </Tooltip>
                    </div>
                );
            },
        }
    ];