import React, { useState, useEffect } from 'react';
import { Auth, DataStore } from 'aws-amplify';
import { TaskType, EnergyProfile as UserEnergyProfile } from '../../models';
import { Button, Alert, Card, Collapse, List, Typography, Space } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import './EnergyProfile.css';

const { Panel } = Collapse;
const { Title, Text, Paragraph } = Typography;

const EnergyProfile = () => {
  const [taskTypes, setTaskTypes] = useState([]);
  const [energyMappings, setEnergyMappings] = useState([]);
  const [originalMappings, setOriginalMappings] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [errorAlertVisible, setErrorAlertVisible] = useState(false);


  useEffect(() => {
    fetchTaskTypes();
    fetchUserEnergyProfile();
  }, []);

  const fetchTaskTypes = async () => {
    const types = await DataStore.query(TaskType);
    const mappings = types.flatMap(type => 
      type.subtypes
        .filter(subtype => subtype !== 'Other')
        .map(subtype => ({ taskType: type.name, subtype, energyImpact: null }))
    );
    setTaskTypes(types);
    setEnergyMappings(mappings);
  };

  const fetchUserEnergyProfile = async () => {
    const userId = Auth.user.attributes.sub;
    const profiles = await DataStore.query(UserEnergyProfile, c => c.userId.eq(userId));
    if (profiles.length > 0) {
      const profile = profiles[0];
      setEnergyMappings(profile.energyMappings);
      setOriginalMappings(profile.energyMappings);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const userId = Auth.user.attributes.sub;
      const profiles = await DataStore.query(UserEnergyProfile, c => c.userId.eq(userId));
      if (profiles.length > 0) {
        const profile = profiles[0];
        await DataStore.save(UserEnergyProfile.copyOf(profile, updated => {
          updated.energyMappings = energyMappings;
        }));
      } else {
        await DataStore.save(new UserEnergyProfile({
          userId,
          energyMappings
        }));
      }
      setOriginalMappings(energyMappings);
      // Show success notification
      setSuccessAlertVisible(true);
      setTimeout(() => setSuccessAlertVisible(false), 3000); // Hide alert after 3 seconds
    } catch (error) {
      console.error('Error saving energy profile:', error);
        // Show error notification
        setErrorAlertVisible(true);
        setTimeout(() => setErrorAlertVisible(false), 3000); // Hide alert after 3 seconds
    } finally {
      setIsSaving(false);
    }
  };

  const moveCard = (taskType, subtype, energyImpact) => {
    const index = energyMappings.findIndex(
      mapping => mapping.taskType === taskType && mapping.subtype === subtype
    );
    setEnergyMappings(update(energyMappings, {
      [index]: { energyImpact: { $set: energyImpact } }
    }));
  };

  const hasChanges = () => {
    return JSON.stringify(energyMappings) !== JSON.stringify(originalMappings);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="energy-profile-page">
        <div className="header">
          <h1>Energy Profile</h1>
          <Button type="primary" disabled={!hasChanges()} onClick={handleSave} loading={isSaving}>
            Save
          </Button>
        </div>
        {successAlertVisible && (
          <Alert message="Success" description="Your energy profile has been saved successfully." type="success" showIcon style={{ marginBottom: '16px' }} />
        )}
        {errorAlertVisible && (
          <Alert message="Error" description="An error occurred while saving your energy profile." type="error" showIcon style={{ marginBottom: '16px' }} />
        )}
        <p className="explanation">
          Drag and drop your task subtypes into the appropriate categories below. This helps the system auto-schedule your tasks to minimize energy burnout.
        </p>
        <div className="floating-cards">
          {energyMappings.filter(card => card.energyImpact === null).map(card => (
            <DragDropCard key={`${card.taskType}-${card.subtype}`} taskType={card.taskType} subtype={card.subtype} moveCard={moveCard} />
          ))}
        </div>
        <div className="categories">
          {[
            { label: 'Very Draining', value: 'VERY_DRAINING' },
            { label: 'Draining', value: 'DRAINING' },
            { label: 'Slightly Draining', value: 'SLIGHTLY_DRAINING' },
            { label: 'No Difference', value: 'NO_DIFFERENCE' },
            { label: 'Recharging', value: 'RECHARGING' }
          ].map(category => (
            <Category
              key={category.value}
              category={category}
              cards={energyMappings.filter(card => card.energyImpact === category.value)}
              moveCard={moveCard}
            />
          ))}
        </div>
        <InformationSection />

        <ResearchSection />
      </div>
    </DndProvider>
  );
};

const Category = ({ category, cards, moveCard }) => {
  const [, drop] = useDrop({
    accept: 'CARD',
    drop: (item) => moveCard(item.taskType, item.subtype, category.value)
  });

  return (
    <div className="category" ref={drop}>
      <h2>{category.label}</h2>
      {cards.map(card => (
        <DragDropCard key={`${card.taskType}-${card.subtype}`} taskType={card.taskType} subtype={card.subtype} moveCard={moveCard} />
      ))}
    </div>
  );
};

const InformationSection = () => {
    return (
        <span
          style={{ 
            display: 'flex',
          }}>
      <Card 
        style={{ 
          /* top, right, bottom, left */
          padding: '0px 20px 0px 20px', 
          backgroundColor: '#ffffff', 
          borderRadius: '8px', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
          width: '50%',
          /* top, right, bottom, left */
          margin: '0px 10px 20px 0px'
        }}
      >
        <Title level={4}>Why Group Task Types by Energy Impact?</Title>
        <List
          itemLayout="vertical"
          dataSource={[
            {
              title: 'Improved Self-Awareness',
              description: 'Grouping tasks into relative categories helps you reflect on the energy impact of different activities, fostering better understanding and mindful energy management.'
            },
            {
              title: 'Enhanced Scheduling Accuracy',
              description: 'By providing relative input on the energy demands of your tasks, our app can more accurately understand your unique energy patterns. This helps in intelligently scheduling your day to balance draining tasks with recharging ones, promoting sustained productivity and preventing burnout.'
            }
          ]}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={<Text strong>{item.title}</Text>}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Card>
        <Card 
        style={{ 
            /* top, right, bottom, left */
            padding: '0px 20px 0px 20px', 
            backgroundColor: '#ffffff', 
            borderRadius: '8px', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
            width: '50%',
            /* top, right, bottom, left */
            margin: '0px 0px 20px 10px'
        }}
        >
            <Title level={4}>Why Group Instead of Stack or Rank?</Title>
            <Paragraph style={{ fontSize: '1.0em', color: 'rgba(0, 0, 0, 0.45)', marginTop: '20px' }}>
                <i style={{color:'rgba(0, 0, 0, 0.45)', fontSize: '1.15em'}}>
                    How do you really know how much a task truly impacts your energy? 
                </i>
                    <br></br>
                    If you had to put a number to it, it might be challenging.
                <br></br><br></br>
                However, <b style={{fontSize: '1.1em'}}>
                    research shows that humans are generally better at making relative judgments
                  </b> 
                  (e.g., "she is taller than him") compared to assigning precise, absolute values (e.g., "Shiva is 5'8"). 
                <br></br><br></br>
                This is why <b style={{fontSize: '1.1em'}}>
                      we cater to your natural human superpowers
                    </b> 
                  and ask you to group your recurring task types into categories based on their relative energy impact on you.
                We'll figure out the rest! And don't worry, you can always update these categories whenever you feel want.
            </Paragraph>
        </Card>
        </span>
    );
  };

const ResearchSection = () => {
  return (
        <Collapse accordion>
            <Panel header="Supporting Research" key="1">
            <List
                itemLayout="horizontal"
                dataSource={[
                {
                    title: 'Comparative Judgments',
                    description: 'Studies have shown that comparative ratings (relative judgments) are more accurate than absolute ratings in various contexts, such as employee performance and personality traits.',
                    link: 'https://pubmed.ncbi.nlm.nih.gov/26162115/'
                },
                {
                    title: 'WITNESS Model',
                    description: 'Research on the WITNESS model indicates that relative judgments can be more intuitive and accurate than absolute judgments in decision-making processes.',
                    link: 'https://link.springer.com/article/10.3758/s13423-013-0493-1'
                },
                {
                    title: 'Perceptual Decision-Making',
                    description: 'Higher levels of absolute evidence can decrease decision accuracy, suggesting that relative judgments might be more reliable.',
                    link: 'https://link.springer.com/article/10.3758/s13423-015-0940-2'
                }
                ]}
                renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                    title={<a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>}
                    description={item.description}
                    />
                </List.Item>
                )}
            />
            </Panel>
        </Collapse>
    );
}

const DragDropCard = ({ taskType, subtype, moveCard }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { taskType, subtype },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div className="card" ref={drag} style={{ opacity: isDragging ? 0.5 : 1, width: 'auto' }}>
      {subtype} ({taskType})
    </div>
  );
};

export default EnergyProfile;
