import { useState } from "react";
import { Card, Row, Col, Typography, Image, Button, Alert, ConfigProvider, Statistic, Tag, Popconfirm } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useSelector } from "react-redux";
import LinkedAccountsComponent from "./components/LinkedAccountsComponent";
import { Auth } from "aws-amplify";

const { Title, Text } = Typography;

const cardStyle = {
    width: '100%',
    borderRadius: 10,
    borderColor: 'lightgray',
    borderWidth: 'thin',
};

const AccountPage = () => {
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [successAlertVisible, setSuccessAlertVisible] = useState(false);
    const [errorAlertVisible, setErrorAlertVisible] = useState(false);
    const { userId, username } = useSelector((state) => state.app);

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            // Handle post-sign-out logic, like redirecting to a login page
            window.location.href = '/';
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handlePlanChange = async (plan) => {
        try {
            setSelectedPlan(plan);
            setSuccessAlertVisible(true);
            setTimeout(() => {
                setSuccessAlertVisible(false);
            }, 3000); // Hide the banner after 5 seconds
        } catch (error) {
            console.error('Error changing plan: ', error);
            setErrorAlertVisible(true);
        }
    }

    const profileCard = (
        <div>
            <Card
                hoverable
                cover={<Image src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />}
                style={{ 
                    width: '100%',
                    flex: 1,
                    borderRadius: 10, 
                    borderColor: 'lightgray', 
                    borderWidth: 'thin',
                    maxHeight: '600px',
                }}
            >
                <Text strong>Name:</Text> {username}
            </Card>
        </div>
    );

    const subscriptionCard = (
        <Card title="My Subscription" style={{...cardStyle}} hoverable>
            {successAlertVisible && (
                <Alert message="Success" description="Subscription updated successfully." type="success" showIcon style={{ marginBottom: '16px' }} />
            )}
            {errorAlertVisible && (
                <Alert message="Error" description="An error occurred while updating your subscription." type="error" showIcon style={{ marginBottom: '16px' }} />
            )}
            <Text strong>Current Plan:</Text> <Tag color='green'>Free</Tag>
            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center', marginTop: '16px' }}>
                <Popconfirm
                    title={`Are you sure you want to change to the Free plan?`}
                    onConfirm={() => handlePlanChange('free')}
                    okText="Yes"
                    cancelText="No"
                >
                    <Card hoverable type="inner"  
                        style={{ ...cardStyle, 
                            marginTop: '16px', 
                            width: '30%', 
                            backgroundColor: selectedPlan === 'free' ? 'lightgray' : 'white' 
                        }
                    }>
                        <Statistic valueStyle={{ color: '#3f8600' }} value={'FREE'} />
                        <Text type="secondary">for life</Text>
                    </Card>
                </Popconfirm>
                <Popconfirm
                    title={`Are you sure you want to change to the Monthly plan?`}
                    onConfirm={() => handlePlanChange('monthly')}
                    okText="Yes"
                    cancelText="No"
                >
                    <Card hoverable type="inner" 
                        style={{ ...cardStyle, 
                            marginTop: '16px', 
                            width: '30%', 
                            backgroundColor: selectedPlan === 'monthly' ? 'lightgray' : 'white' 
                        }}
                    >
                        <Statistic valueStyle={{ color: '#3f8600' }} prefix='$' value={'59.99'} />
                        <Text type="secondary">per month</Text>
                    </Card>
                </Popconfirm>
                <Popconfirm
                    title={`Are you sure you want to change to the Annual plan?`}
                    onConfirm={() => handlePlanChange('yearly')}
                    okText="Yes"
                    cancelText="No"
                >
                    <Card hoverable type="inner" 
                        onClick={() => handlePlanChange('yearly')}
                        style={{ ...cardStyle, 
                            marginTop: '16px', width: '30%', 
                            backgroundColor: selectedPlan === 'yearly' ? 'lightgray' : 'white' 
                        }}
                    >
                        <Statistic valueStyle={{ color: 'green' }} prefix='$' value={'599.99'} />
                        <Text type="secondary">per year</Text>
                    </Card>
                </Popconfirm>
            </div>
        </Card>
    );

    const linkedAccountsCard = (
        <Card title="Linked Accounts" style={cardStyle} hoverable>
            <LinkedAccountsComponent userId={userId} visible={true} />
        </Card>
    );

    const confirmationPopover = (plan) => (
        <div>
            <Popconfirm 
                title={`Are you sure you want to change your subscription to the ${selectedPlan}?`} 
                onConfirm={() => handlePlanChange(plan)}>
                <Button type="primary">Change Subscription to {selectedPlan}</Button>
            </Popconfirm>
        </div>
    );

    return (
        <div>
            <Header style={{ background: 'white', padding: '0px' }}>
                <span style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                    <Title>My Account</Title>
                    <Button type='primary' onClick={handleSignOut} style={{marginBottom: '16px'}}>
                        Sign Out
                    </Button>
                </span>
                {confirmationPopover}
            </Header>
            <Content style={{ padding: '20px' }}>
                <Row style={{ height: '97vh' }}>
                    {/* Left Side: Profile Card */}
                    <Col span={10} 
                        style={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            padding: '20px', 
                        }}>
                        {profileCard}
                    </Col>

                    {/* Right Side: Linked Accounts and Subscription */}
                    <Col span={14} 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'column', // Stack items vertically
                            alignItems: 'center', 
                            padding: '20px', // Optional padding
                            gap: '20px' // Add gap between the stacked items
                        }}
                    >
                        {subscriptionCard}
                        {linkedAccountsCard}
                    </Col>
                </Row>
            </Content>
        </div>
    );
};

export default AccountPage;
