// mockTaskData.js
import dayjs from 'dayjs';

const taskData = [
  // Monday
  {
    id: 1,
    title: 'Morning Exercise',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(1, 'day').hour(6).minute(0).toISOString(),
    completed: true,
    type: 'activity',
  },
  {
    id: 2,
    title: 'Prep for Client Call',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(1, 'day').hour(8).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
  {
    id: 3,
    title: 'Client Call with ABC Corp',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(1, 'day').hour(10).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  {
    id: 4,
    title: 'Lunch Break',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(1, 'day').hour(12).minute(0).toISOString(),
    completed: false,
    type: 'other',
  },
  {
    id: 5,
    title: 'Put Together Sales Pitch for Don Glanaven',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(1, 'day').hour(13).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
  {
    id: 6,
    title: 'Follow-up Emails',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(1, 'day').hour(15).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
  {
    id: 7,
    title: 'Evening Jog',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(1, 'day').hour(18).minute(0).toISOString(),
    completed: false,
    type: 'activity',
  },
  // Tuesday
  {
    id: 8,
    title: 'Breakfast with Client',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(2, 'day').hour(8).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  {
    id: 9,
    title: 'Sales Call with XYZ Inc.',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(2, 'day').hour(10).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  {
    id: 10,
    title: 'Lunch Break',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(2, 'day').hour(12).minute(0).toISOString(),
    completed: false,
    type: 'other',
  },
  {
    id: 11,
    title: 'Work on Proposal for DEF Ltd.',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(2, 'day').hour(13).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
  {
    id: 12,
    title: 'Racquetball Practice',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(2, 'day').hour(18).minute(0).toISOString(),
    completed: true,
    type: 'activity',
  },
  // Wednesday
  {
    id: 13,
    title: 'Prep for Product Demo',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(3, 'day').hour(9).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
  {
    id: 14,
    title: 'Product Demo with GHI Co.',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(3, 'day').hour(11).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  {
    id: 15,
    title: 'Lunch Break',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(3, 'day').hour(12).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  {
    id: 16,
    title: 'Client Follow-ups',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(3, 'day').hour(13).minute(0).toISOString(),
    completed: false,
    type: 'task',
  },
  {
    id: 17,
    title: 'Prepare Quarterly Sales Report',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(3, 'day').hour(15).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
  {
    id: 18,
    title: 'Dinner with Partner',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(3, 'day').hour(19).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  // Thursday
  {
    id: 19,
    title: 'Yoga Class',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(4, 'day').hour(7).minute(0).toISOString(),
    completed: true,
    type: 'activity',
  },
  {
    id: 20,
    title: 'One-on-One with Manager',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(4, 'day').hour(9).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  {
    id: 21,
    title: 'Sales Strategy Meeting',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(4, 'day').hour(11).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  {
    id: 22,
    title: 'Lunch Break',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(4, 'day').hour(12).minute(0).toISOString(),
    completed: false,
    type: 'other',
  },
  {
    id: 23,
    title: 'Work on Marketing Plan',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(4, 'day').hour(13).minute(0).toISOString(),
    completed: false,
    type: 'task',
  },
  {
    id: 24,
    title: 'Client Dinner',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(4, 'day').hour(18).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  // Friday
  {
    id: 25,
    title: 'Morning Run',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(5, 'day').hour(6).minute(0).toISOString(),
    completed: true,
    type: 'activity',
  },
  {
    id: 26,
    title: 'Sales Call with JKL Inc.',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(5, 'day').hour(9).minute(0).toISOString(),
    completed: true,
    type: 'meeting',
  },
  {
    id: 27,
    title: 'Lunch Break',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(5, 'day').hour(12).minute(0).toISOString(),
    completed: false,
    type: 'other',
  },
  {
    id: 28,
    title: 'Put Together Sales Pitch for Acme Corp.',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(5, 'day').hour(13).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
  {
    id: 29,
    title: 'Afternoon Hike',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(5, 'day').hour(16).minute(0).toISOString(),
    completed: true,
    type: 'activity',
  },
  {
    id: 30,
    title: 'Movie Night with Friends',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(5, 'day').hour(20).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  // Saturday
  {
    id: 31,
    title: 'Family Breakfast',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(6, 'day').hour(9).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  {
    id: 32,
    title: 'Grocery Shopping',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(6, 'day').hour(11).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  {
    id: 33,
    title: 'Read a Book',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(6, 'day').hour(14).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  {
    id: 34,
    title: 'Dinner Date',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(6, 'day').hour(19).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  // Sunday
  {
    id: 35,
    title: 'Church Service',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(7, 'day').hour(10).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  {
    id: 36,
    title: 'Brunch with Family',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(7, 'day').hour(12).minute(0).toISOString(),
    completed: false,
    type: 'other',
  },
  {
    id: 37,
    title: 'Relax and Unwind',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(7, 'day').hour(15).minute(0).toISOString(),
    completed: true,
    type: 'other',
  },
  {
    id: 38,
    title: 'Prepare for Upcoming Week',
    startTime: dayjs().subtract(1, 'week').startOf('week').add(7, 'day').hour(17).minute(0).toISOString(),
    completed: true,
    type: 'task',
  },
];

export default taskData;
