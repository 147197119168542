import Home from "./Home";
import AccountPage from "./account/AccountPage";
import HealthDashboard from "../features/health/HealthDashboard";
import EnergyProfile from "../features/health/EnergyProfile";
import Calendar from "../features/calendar/Calendar";
import ModifyTaskType from "../features/tasks/components/ModifyTaskType";
import ProjectList from "../features/projects/components/ProjectList";
import ProjectTracker from "../features/projects/ProjectTracker";
import TaskListMain from "../features/tasks/TaskListMain";

import {
    HomeOutlined,
    KeyOutlined,
    UserOutlined,
    HeartOutlined,
    ThunderboltOutlined,
    CalendarOutlined,
    FileDoneOutlined,
    MessageOutlined,
    AreaChartOutlined,
    AimOutlined,
    ContactsOutlined,
} from "@ant-design/icons";
import CoachesPage from "../features/coaches/CoachUI/CoachMain";
// import CoachesPage from "../features/coaches/CoachesPage";
import TrackerPage from '../features/tracker/TrackerPage';
import AddressBook from "../features/contacts/AddressBook";
import React from "react";

interface Route {
  path: string;
  name: string;
  icon: React.ReactElement;
  component: React.ReactElement;
  admin: boolean;
  hidden?: boolean;
}

const routes: Route[] = [
  {
    path: "/account",
    name: "Account",
    icon: <UserOutlined/>,
    component: <AccountPage/>,
    admin: false,
  },
  {
    path: "/",
    name: "Home",
    icon: <HomeOutlined/>,
    component: <Home/>,
    admin: false,
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: <FileDoneOutlined/>,
    component: <TaskListMain/>,
    admin: false,
  },
  {
    path: "/projects",
    name: "Projects",
    icon: <AreaChartOutlined/>,
    component: <ProjectList/>,
    admin: true,
  },
  {
    path: "/project/:projectId",
    name: "Project",
    icon: <AreaChartOutlined/>,
    component: <ProjectTracker/>,
    admin: true,
    hidden: true,
  },
  {
    path: "/health",
    name: "Health",
    icon: <HeartOutlined/>,
    component: <HealthDashboard/>,
    admin: true,
  },
  {
    path: "/tracking",
    name: "Tracking",
    icon: <AimOutlined/>,
    component: <TrackerPage/>,
    admin: false,
  },
  {
    path: "/coaches",
    name: "Coaches",
    icon: <MessageOutlined/>,
    component: <CoachesPage/>,
    admin: false,
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: <CalendarOutlined/>,
    component: <Calendar/>,
    admin: false,
  },
  {
    path: "/taskType",
    name: "Task Types",
    icon: <KeyOutlined/>,
    component: <ModifyTaskType/>,
    admin: true,
  },
  {
    path: "/contacts",
    name: "Contacts",
    icon: <ContactsOutlined/>,
    component: <AddressBook/>,
    admin: true,
  },
  {
    path: "/energy",
    name: "Energy",
    icon: <ThunderboltOutlined/>,
    component: <EnergyProfile/>,
    admin: true,
    hidden: true
  },
];
export default routes;
