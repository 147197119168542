import { useState } from "react";
import { lightenColor, darkenColor } from "../../../app/utils";
import { Typography, Input, Button, Row, Col, Flex } from "antd";
import { DeleteOutlined, EditOutlined, CloseCircleFilled } from "@ant-design/icons";

const { Title, Text } = Typography;

const ModifyTrackingCategories = ({ categories, onEdit, onDelete, onAddSubcategory, onDeleteSubcategory }) => {
    const [newCategoryNames, setNewCategoryNames] = useState({});
    const [newSubcategories, setNewSubcategories] = useState({});
    const [toggleEdit, setToggleEdit] = useState(false);

    const handleDelete = (category) => {
        onDelete(category);
    };

    const handleEditInputChange = (category, value) => {
        setNewCategoryNames((prev) => ({
            ...prev,
            [category.id]: value,
        }));
    };

    const handleEdit = (category) => {
        onEdit({ ...category, name: newCategoryNames[category.id] });
        setToggleEdit(false);
    };

    const handleAddSubcategoryInputChange = (category, value) => {
        setNewSubcategories((prev) => ({
            ...prev,
            [category.id]: value,
        }));
    };

    const handleAddSubcategory = (category) => {
        onAddSubcategory(category.id, newSubcategories[category.id]);
        setNewSubcategories((prev) => ({
            ...prev,
            [category.id]: '',
        }));
    }; 

    const handleDeleteSubcategory = (category, subcategory) => {
        onDeleteSubcategory(category, subcategory);
    };

    const verticalLayout = (
        <Row gutter={16}>
                {categories.map((category) => (
                    <Col key={category.id} span={23}
                        style={{ 
                            border: '1px solid grey',
                            borderColor: darkenColor(category.color) || 'grey',
                            padding: '16px', 
                            borderRadius: '8px', 
                            margin: '8px',
                            backgroundColor: lightenColor(category.color) || 'grey'
                        }}
                    >
                        <Flex justify="space-between" style={{ marginBottom: '8px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Title level={4} style={{ color: darkenColor(category.color, 50) || 'grey' }}>
                                    {toggleEdit ?
                                        <Input 
                                            value={newCategoryNames[category.id] || category.name}
                                            onChange={(e) => handleEditInputChange(category, e.target.value)}
                                            onPressEnter={() => handleEdit(category)}
                                        />
                                    :
                                        <div>
                                            {category.name} ({category.subcategories?.length || 0})
                                        </div>
                                    }
                                </Title>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button 
                                    type="primary" 
                                    icon={<EditOutlined />}
                                    onClick={() => toggleEdit ? handleEdit(category) : setToggleEdit(true)}
                                    style={{ 
                                        backgroundColor: darkenColor(category.color) || 'grey', 
                                        border: '1px solid grey', 
                                        borderColor: darkenColor(category.color) || 'grey'
                                    }} 
                                />
                                <Button 
                                    type="primary" 
                                    icon={<DeleteOutlined />} 
                                    onClick={() => handleDelete(category)}
                                    style={{ 
                                        backgroundColor: darkenColor(category.color) || 'grey', 
                                        border: '1px solid grey', 
                                        borderColor: darkenColor(category.color) || 'grey',
                                        marginLeft: '8px',
                                    }}
                                />
                            </div>
                        </Flex>
                        <div style={{
                            border: '1px solid grey',
                            borderColor: darkenColor(category.color) || 'grey',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#f9f9f9'
                        }}>
                            <Flex wrap="wrap" gap={8}>
                                {category.subcategories?.map((subcategory) => (
                                    <div key={subcategory} 
                                        size="small"
                                        bordered={false}
                                        style={{
                                            backgroundColor: darkenColor(category.color, 50) || '#e0e0e0',
                                            border: '1px solid grey',
                                            borderColor: darkenColor(category.color, 50) || 'grey',
                                            borderRadius: '3px',
                                            textAlign: 'center',
                                            padding: '2px',
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                        }}
                                    >
                                        {/* Add any additional content here if needed */}
                                        <CloseCircleFilled onClick={() => handleDeleteSubcategory(category.id, subcategory)} 
                                            style={{ color: 'white', marginRight: '4px' }} />
                                        <Text strong style={{ color: 'white', cursor: 'pointer'}} >{subcategory}</Text>
                                    </div>
                                ))}
                            </Flex>
                        </div>
                        <Flex style={{ marginTop: '10px' }}>
                            <Input
                                placeholder={`Add new subcategory to ${category.name}`}
                                value={newSubcategories[category.id] || ""}
                                onChange={(e) => handleAddSubcategoryInputChange(category, e.target.value)}
                                onPressEnter={() => handleAddSubcategory(category)}
                                style={{ 
                                    marginBottom: '8px' ,
                                    border: '1px solid grey',
                                    borderColor: darkenColor(category.color) || 'grey',
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                }}
                            />
                            <Button
                                type="primary"
                                onClick={() => handleAddSubcategory(category)}
                                style={{
                                    backgroundColor: darkenColor(category.color) || 'grey',
                                    borderTopRightRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                    border: '1px solid grey',
                                    borderColor: darkenColor(category.color) || 'grey',
                                }}
                            >
                                Add
                            </Button>
                        </Flex>
                    </Col>
                ))}
            </Row>
    );

    // const horizontalLayout = (
    //     <>
    //             <Row gutter={16}>
    //             {categories.map((category) => (
    //                 <Col key={category.id} span={22}
    //                     style={{ 
    //                         border: '1px solid grey',
    //                         borderColor: darkenColor(category.color) || 'grey',
    //                         padding: '16px', 
    //                         borderRadius: '8px', 
    //                         margin: '8px',
    //                         backgroundColor: lightenColor(category.color) || 'grey'
    //                     }}
    //                 >
    //                     <Row gutter={16}>
    //                         <Col span={8}>
                            

    //                         <div style={{ display: 'flex', alignItems: 'center' }}>
                                
    //                             <Title level={3} style={{ color: darkenColor(category.color, 50) || 'grey' }}>
    //                                 <span style={{fontSize: 16}}>({categoryToSubcategoryMap[category.id]?.length || 0})</span> {category}
    //                             </Title>
    //                             <EditOutlined size={16} style={{ color: darkenColor(category.color, 50) || 'grey', marginLeft: '8px' }} />
    //                         </div>
    //                         <div style={{ display: 'flex', justifyContent: 'center' }}>
    //                             <Flex style={{  }}>
    //                                 <Input
    //                                     placeholder={`Add new subcategory to ${category.name}`}
    //                                     value={newSubcategories[category.id] || ""}
    //                                     onChange={(e) => handleInputChange(category, e.target.value)}
    //                                     style={{ 
    //                                         marginBottom: '8px' ,
    //                                         border: '1px solid grey',
    //                                         borderColor: darkenColor(category.color) || 'grey',
    //                                         borderTopLeftRadius: '8px',
    //                                         borderBottomLeftRadius: '8px',
    //                                     }}
    //                                 />
    //                                 <Button
    //                                     type="primary"
    //                                     onClick={() => handleAddSubcategory(category)}
    //                                     style={{
    //                                         backgroundColor: darkenColor(category.color) || 'grey',
    //                                         borderTopRightRadius: '8px',
    //                                         borderBottomRightRadius: '8px',
    //                                         border: '1px solid grey',
    //                                         borderColor: darkenColor(category.color) || 'grey',
    //                                     }}
    //                                 >
    //                                     Add
    //                                 </Button>
    //                             </Flex>
    //                         </div>
    //                         </Col>
    //                         <Col span={16}>

    //                         <div style={{
    //                         border: '1px solid grey',
    //                         borderColor: darkenColor(category.color) || 'grey',
    //                         borderRadius: '8px',
    //                         padding: '16px',
    //                         backgroundColor: '#f9f9f9'
    //                     }}>
    //                         <Flex wrap="wrap" gap={8}>
    //                             {categoryToSubcategoryMap[category.id]?.map((subcategory) => (
    //                                 <div key={subcategory} 
    //                                     size="small"
    //                                     bordered={false}
    //                                     style={{
    //                                         backgroundColor: darkenColor(category.color, 50) || '#e0e0e0',
    //                                         border: '1px solid grey',
    //                                         borderColor: darkenColor(category.color, 50) || 'grey',
    //                                         borderRadius: '3px',
    //                                         textAlign: 'center',
    //                                         padding: '2px',
    //                                         paddingLeft: '8px',
    //                                         paddingRight: '8px',
    //                                     }}
    //                                 >
    //                                     {/* Add any additional content here if needed */}
    //                                     <Text strong style={{ color: 'white'}} >{subcategory}</Text>
    //                                 </div>
    //                             ))}
    //                         </Flex>
    //                     </div>
    //                     </Col>
    //                     </Row>
                        
    //                 </Col>
    //             ))}
    //         </Row>
    //     </>
    // );

    return (
        <div>
            {verticalLayout}
        </div>
    );
};

export default ModifyTrackingCategories;
