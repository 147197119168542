import { Priority } from '../models';
import { useSelector } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import tinycolor from 'tinycolor2';
import awsconfig from '../aws-exports';
import type {Task} from '../models/index.js'

Amplify.configure(awsconfig);
AWS.config.update({
  region: 'us-west-2',
});

export const isAdmin = (user) => {
  const idPayload = user?.getSignInUserSession()?.getIdToken()?.payload;
  return idPayload?.['cognito:groups']?.includes('Admin');
};

export const getUserGroups = (user) => {
  const idPayload = user?.getSignInUserSession()?.getIdToken()?.payload;
  return idPayload?.['cognito:groups'];
};

const getUserPoolId = async () => {
  const currentConfig = Auth.configure();
  return currentConfig.userPoolId;
};

// Function to get all users in a given group
export const getUsersInGroup = async (groupName) => {
  try {
    // Get the User Pool ID dynamically
    const userPoolId = await getUserPoolId();

    // Get AWS credentials from Amplify Auth
    const credentials = await Auth.currentCredentials();

    // Set AWS credentials and region in the SDK
    AWS.config.update({
      credentials: credentials,
      region: process.env.REACT_APP_AWS_REGION || 'us-west-2',
    });

    const params = {
      GroupName: groupName,
      UserPoolId: userPoolId,
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();

    // Use a Promise to handle the async nature of the listUsersInGroup method
    const data = await cognito.listUsersInGroup(params).promise();

    // Extract usernames or other necessary attributes from the data
    const users = data.Users.map(user => ({
      value: user.Username, // userId
      label: user.Attributes.find(attr => attr.Name === 'name')?.Value || 'No name',
    }));

    return users;

  } catch (error) {
    console.error('Error fetching users in group:', error);
    throw error; // Re-throw the error to handle it outside
  }
};

export const formatTimeDisplayHourAndMinutes = (totalMinutes) => {
  const hoursValue = Math.floor(totalMinutes / 60);
  const minutesValue = totalMinutes % 60;
  return minutesValue > 0 ? `${hoursValue} Hours ${minutesValue.toFixed(0)} Minutes` : `${hoursValue} Hours`;
};

export const menuItemStyle = (color) => ({
  backgroundColor: lightenColor(color),
  color: darkenColor(color, 50),
  borderColor: darkenColor(color),
  borderWidth: '1px',
  borderStyle: 'solid',
  width: '100%',
  marginBottom: '2px',
  // fontWeight: 'bold',
});

export const lightenColor = (color, percentage = 10) => {
  if(color === undefined) {
      return 'grey';
  } else if(color === 'rgb(224, 255, 255)') {
      return 'azure';
  }
  return tinycolor(color).lighten(percentage).toString();
};

export const darkenColor = (color, percentage = 10, override=false) => {
  if(color === undefined) {
      return 'grey';
  } else if(color === 'rgb(224, 255, 255)') {
      // double-darken cyan
      return 'rgb(0, 198, 198)';
  } else if(!override && color === 'rgb(168, 248, 168)') {
      // double-darken green
      return 'rgb(0, 168, 0)';
  } else if(!override && color === 'rgb(255, 255, 153)') {
      // return darker yellow
      return 'rgb(255, 211, 0)';
  }

  return tinycolor(color).darken(percentage).toString();
};

export const getColorByPriority = (priority) => {
    switch (priority) {
        case Priority.HIGH:
            return '#d32f2f';   // red
        case Priority.MEDIUM:
            return '#f2c94c';   // yellow
        case Priority.LOW:
            return '#00bcd4';  // cyan
        default:
            return '#d9d9d9';   // gray
    }
};

export function getRandomColor() {
  // Generate a random hue (0 to 360)
  const hue = Math.floor(Math.random() * 360);

  // Use fixed saturation and lightness for vibrant colors
  const saturation = 70 + Math.random() * 10; // 70-80%
  const lightness = 45 + Math.random() * 10;  // 45-55%

  // Convert HSL to Hex
  return hslToHex(hue, saturation, lightness);
};

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};


  // Utility function to transform model instances to plain objects
  export const modelToPlainObject = (model) => ({
    id: model.id,
    userId: model.userId,
    assignee: model.assignee,
    title: model.title,
    description: model.description,
    status: model.status,
    dueDate: model.dueDate,
    priority: model.priority,
    duration: model.duration,
    type: model.type,
    subtype: model.subtype,
    blocks: model.blocks,
    blockedBy: model.blockedBy,
    start: model.start,
    end: model.end,
    completedAt: model.completedAt,
    energyDelta: model.energyDelta,
    energyImpact: model.energyImpact,
    sizeEstimate: model.sizeEstimate,
    sourceId: model.sourceId,
    sourceProvider: model.sourceProvider,
    sourceServiceType: model.sourceServiceType,
    archived: model.archived,
    projectId: model.projectId,
    taskGroupId: model.taskGroupId,
    createdAt: model.createdAt,
    updatedAt: model.updatedAt,
    _version: model._version,
    _lastChangedAt: model._lastChangedAt,
    _deleted: model._deleted
  });

export const useTheme = () => useSelector((state) => state.app.theme);