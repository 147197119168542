import dayjs from 'dayjs';
import { random } from 'lodash';

export let demoTrackingItems = [
    {
        id: 1,
        name: 'Meditate',
        goal: '3',
        goalUnit: 'times',
        goalFrequency: 'daily',
        average: 2.6,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 1, // Health
        subcategory: 'Recharge',
        improvement: true,
        improvementDelta: 45.6,
    },
    {
        id: 2,
        name: 'Headache',
        goal: '0',
        goalUnit: 'times',
        goalFrequency: 'daily',
        average: 0.5,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 1, // Health
        subcategory: 'Pain',
        improvement: false,
        improvementDelta: 10.2,
    },
    {
        id: 3,
        name: 'Sleep',
        goal: '7',
        goalUnit: 'hours',
        goalFrequency: 'daily',
        average: 6.5,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 1, // Health
        subcategory: 'Recharge',
        improvement: true,
        improvementDelta: 12.3,
        generated: true,
    },
    {
        id: 4,
        name: 'Sleep.REM',
        goal: '2',
        goalUnit: 'hours',
        goalFrequency: 'daily',
        average: 1.5,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 1, // Health
        subcategory: 'Recharge',
        improvement: true,
        improvementDelta: 23.4,
        generated: true,
    },
    {
        id: 5,
        name: 'Sleep.Deep',
        goal: '1',
        goalUnit: 'hours',
        goalFrequency: 'daily',
        average: 0.5,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 1, // Health
        subcategory: 'Recharge',
        improvement: false,
        improvementDelta: 11.6,
        generated: true,
    },
    {
        id: 6,
        name: 'Sleep.Light',
        goal: '4',
        goalUnit: 'hours',
        average: 4.5,
        goalFrequency: 'daily',
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 1,  // Health
        subcategory: 'Recharge',
        improvement: true,
        improvementDelta: 5.6,
        generated: true,
    },
    {
        id: 7,
        name: 'Date Night',
        goal: '1',
        goalUnit: 'times',
        goalFrequency: 'weekly',
        average: 0.5,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 2,  // Relationship
        subcategory: 'Connection',
        improvement: true,
        improvementDelta: 15.6,
    },
    {
        id: 8,
        name: 'Fight',
        goal: '0',
        goalUnit: 'times',
        goalFrequency: 'weekly',
        average: 0.1,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 2, // Relationship
        subcategory: 'Conflict',
        improvement: true,
        improvementDelta: 5.6,
    },
    {
        id: 9,
        name: 'Call Mom',
        goal: '1',
        goalUnit: 'times',
        goalFrequency: 'weekly',
        average: 0.4,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 3, // Family
        subcategory: 'Mother',
        improvement: true,
        improvementDelta: 0.0,
    },
    {
        id: 10,
        name: 'Dance Class',
        goal: '1',
        goalUnit: 'times',
        goalFrequency: 'weekly',
        average: 0.1,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 2,  // Relationship
        subcategory: 'Connection',
        improvement: true,
        improvementDelta: 5.6,
    },
    {
        id: 11,
        name: 'Play Guitar',
        goal: '2',
        goalUnit: 'times',
        goalFrequency: 'weekly',
        average: 0.1,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 4,  // Hobby
        subcategory: 'Music',
        improvement: false,
        improvementDelta: 5.6,
    },
    {
        id: 12,
        name: 'Play Piano',
        goal: '1',
        goalUnit: 'times',
        goalFrequency: 'weekly',
        average: 0.3,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 4,  // Hobby
        subcategory: 'Music',
        improvement: true,
        improvementDelta: 13.7,
    },
    {
        id: 13,
        name: 'Read Book',
        goal: '1',
        goalUnit: 'times',
        goalFrequency: 'monthly',
        average: 4,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 4,  // Hobby
        subcategory: 'Reading',
        improvement: true,
        improvementDelta: 37.9,
    },
    {
        id: 14,
        name: 'Uber Shift',
        goal: '10',
        goalUnit: 'times',
        goalFrequency: 'monthly',
        average: 0.5,
        lastUpdated: '2021-09-01T00:00:00Z',
        categoryId: 5,  // Work
        subcategory: 'Driving',
        improvement: true,
        improvementDelta: 5.6,
    }
];

export let demoTrackingCategories = [
    {
        id: 1,
        userId: 'demo',
        name: 'Health',
        subcategories: ['Recharge', 'Pain'],
        color: 'rgb(168, 248, 168)',  // Lighter Green (Health)
    },
    {
        id: 2,
        userId: 'demo',
        name: 'Relationship',
        subcategories: ['Connection', 'Conflict'],
        color: 'rgb(255, 182, 193)',  // Lighter Pink (Relationship)
    },
    {
        id: 3,
        userId: 'demo',
        name: 'Family',
        subcategories: ['Mother', 'Father'],
        color: 'rgb(179, 204, 255)',  // Lighter Blue (Family)
    },
    {
        id: 4,
        userId: 'demo',
        name: 'Hobby',
        subcategories: ['Music', 'Reading'],
        color: 'rgb(224, 255, 255)',  // Lighter Cyan (Hobby)
    },
    {
        id: 5,
        userId: 'demo',
        name: 'Work',
        subcategories: ['Driving', 'Coding'],
        color: 'rgb(255, 255, 153)',  // Lighter Yellow (Next distinct color)
    }
];

const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
export let demoTrackingEntries = [
    { trackingItemId: 1, value: randomNumber(0,3)}, //Meditate
    { trackingItemId: 2, value: randomNumber(0,1)}, //Headache
    { trackingItemId: 3, value: randomNumber(5,9)}, //Sleep
    { trackingItemId: 4, value: randomNumber(1,3)}, //Sleep.REM
    { trackingItemId: 5, value: randomNumber(0,1)}, //Sleep.Deep
    { trackingItemId: 6, value: randomNumber(3,6)}, //Sleep.Light
    { trackingItemId: 7, value: randomNumber(0,1)}, //Date Night
    { trackingItemId: 8, value: randomNumber(0,1)}, //Fight
    { trackingItemId: 9, value: randomNumber(0,1)}, //Call Mom
    { trackingItemId: 10, value: randomNumber(0,1)}, //Dance Class
    { trackingItemId: 11, value: randomNumber(0,1)}, //Play Guitar
    { trackingItemId: 12, value: randomNumber(0,1)}, //Play Piano
    { trackingItemId: 13, value: randomNumber(0,1)}, //Read Book
    { trackingItemId: 14, value: randomNumber(0,1)}, //Uber Shift
];

// Utility functions
const shouldRenderHabit = () => Math.random() < 0.2; // 20% chance
const randomlyGenerateDailyTrackingEntry = () => {
    return demoTrackingEntries.filter(() => shouldRenderHabit());
};

// Generate demo data for the month
export const fetchDailyTrackingEntries = (start, end) => {
    let current = dayjs(start);
    let generatedDailyTrackingEntries = [];

    while (current.isBefore(end) || current.isSame(end, 'day')) {
        generatedDailyTrackingEntries.push(randomlyGenerateDailyTrackingEntry());
        current = current.add(1, 'day');
    }
    return generatedDailyTrackingEntries;
};