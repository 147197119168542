import { useEffect, useState } from 'react';
import { Typography, Row, Col, Button, Tag, InputNumber } from 'antd';

const { Title } = Typography;

const itemsPerColumn = 3;

const TrackingEntryEditor = ({ loading, entry, trackingItems, trackingCategories, categoryColors, onSave }) => {
    const [newEntry, setNewEntry] = useState({});

    // Sort by category and type
    const filteredItems = trackingItems.filter(item => !item.generated);
    const sortedTrackingItems = filteredItems.sort((a, b) => {
        const categoryA = a.category || "";
        const categoryB = b.category || "";
        const subcategoryA = a.subcategory || "";
        const subcategoryB = b.subcategory || "";

        if (categoryA === categoryB) {
            return subcategoryA.localeCompare(subcategoryB);
        }
        return categoryA.localeCompare(categoryB);
    });

    useEffect(() => {
        setNewEntry({
            date: entry?.date ? entry.date : new Date().toISOString(),
            items: filteredItems.map(item => ({
                    trackingItemId: item.id,
                    value: entry?.items?.find(entryItem => entryItem.trackingItemId === item.id)?.value || '0',
                })),
        });
    }, [entry, trackingItems]);

    const handleNumberChange = (item, value) => {
        setNewEntry((prevEntry) => ({
            ...prevEntry,
            items: prevEntry?.items?.map((entryItem) =>
                entryItem.trackingItemId === item.id
                    ? { ...entryItem, value: String(value) } // value must be a String, not a Number
                    : entryItem
            ),
        }));
    }

    const handleSave = () => {
        if (!newEntry) {
            console.log('No entry to save');
            return;
        }

        if (onSave) {
            onSave(newEntry);
        }
    };

    return (
        loading ?  <div>Loading...</div> :
        <div>
            <Row justify='space-between'>
                <Title level={4}>Today's Tracking Entry</Title>
                <div>
                    {trackingCategories.map((category) => (
                        <Tag key={category.id} color={categoryColors[category.id]} 
                            style={{ borderRadius: '6px', borderColor: 'grey', color: 'black' }}
                        >
                            {category.name}
                        </Tag>
                    ))}
                </div>
                <Button type='primary' onClick={handleSave}>Save</Button>
            </Row>
            <Row style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginLeft: '20px' }}>
                {Array.from({ length: Math.ceil(trackingItems.length / itemsPerColumn) }).map((_, colIndex) => (
                    <Col key={colIndex} style={{ display: 'inline-block', verticalAlign: 'top' }}>
                        {sortedTrackingItems.slice(colIndex * itemsPerColumn, (colIndex + 1) * itemsPerColumn).map((item) => (
                            <div key={item.id} 
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    marginRight: '16px',
                                    marginLeft: '16px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '8px',
                                    borderColor: 'grey',
                                    padding: '4px',
                                    backgroundColor: categoryColors[item.categoryId] || 'grey',
                                }}>
                                    <div>
                                        <InputNumber
                                            size='small'
                                            min={0}
                                            value={
                                                newEntry?.items?.find(
                                                    (entryItem) =>
                                                        entryItem.trackingItemId === item.id
                                                )?.value || 0
                                            }
                                            onChange={(value) =>
                                                handleNumberChange(item, value)
                                            }
                                            style={{ width: '50px', marginRight: '8px' }}
                                        />
                                        <Typography.Text>{item.name}</Typography.Text>
                                    </div>
                            </div>
                        ))}
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default TrackingEntryEditor;
