import React from "react";
import classes from "./coach-styles/LeftPane.module.css";
import type { Thread } from "./coach-utils/corpath-types";
import { formatMysqlDatetime } from "./coach-utils/utils";

import SearchBar from "./SearchAndNewBar";
import {coaches, coachNameMapping} from './coach-utils/corpath-types'
import { useSelector } from 'react-redux';
import type { RootState } from '../../../store'
import * as api from './coach-utils/api'


interface Props {
  chosenThread: Thread | null;
  setChosenThread: React.Dispatch<React.SetStateAction<Thread | null>>;
  refreshThisThreadId: string;
  setRefreshThisThreadId: React.Dispatch<React.SetStateAction<string>>;
  threads: Thread[];
  setThreads: React.Dispatch<React.SetStateAction<Thread[]>>;
  setSearchedThreads: React.Dispatch<React.SetStateAction<Thread[] | null>>;
  searchedIsNull: boolean;
}


// TODO: access unique userId from global state

function LeftPane({
  chosenThread,
  setChosenThread,
  refreshThisThreadId,
  setRefreshThisThreadId,
  threads,
  setThreads,
  setSearchedThreads,
  searchedIsNull,
}: Props) {
  const [previewIndex, setPreviewIndex] = React.useState<number | null>(null);
  const [xHover, setXHover] = React.useState<boolean>(false);
  const {userId} = useSelector((state: RootState) => state.app);

  React.useEffect(() => {
    console.log('USER ID (GLOBAL):\n', userId);
  },[userId]);

  // get init threads by userId
  React.useEffect(() => {
    api.getThreadsByUserId(userId!)
      .then((res) => {
        if(res.status !== 'ok') return new Error()
        setThreads(
          res.threads.sort(
            (a: Thread, b: Thread) =>
              formatMysqlDatetime(b.updatedAt!).epochTimeMS -
              formatMysqlDatetime(a.updatedAt!).epochTimeMS
          )
        );
      })
      .catch((err) => {
        console.error("ERR from Datastore: \n", err);
      });
  }, []);

  // refresh thread with new message sent
  React.useEffect(() => {
    if (!refreshThisThreadId.length) return;
    // prep for next call
    setRefreshThisThreadId("");
    // re-fetch thread with updated values...

    api.getThreadById(refreshThisThreadId)
      .then((res) => {
        // ...and replace it in threads list
        if (res.status !== 'ok') return new Error();

          const updatedThread = res.thread;
          setThreads([
            updatedThread,
            ...threads.filter((t) => t.id !== updatedThread.id),
          ]);
      })
      .catch((err) => console.error("update-thread-by-id fetch err:\n", err));
  }, [refreshThisThreadId]); // not an error (linter?)

  function deleteThread(e: React.MouseEvent<HTMLDivElement>, thread: Thread) {
    e.stopPropagation();

    const newThreadList = threads.filter((t) => t.id !== thread.id);
    setThreads(newThreadList);

    console.log("Deleting thread:\n", thread);

    api.deleteThreadById(thread.id)
      .then((res) => console.log("Deleted thread:\n", res.message))
      .catch((err) => console.error(err));
  }

  return (
    <div className={classes.container}>
      <SearchBar
        threads={threads}
        setSearchedThreads={setSearchedThreads}
        setChosenThread={setChosenThread}
      />
      {threads.length === 0 && (
        <div className={classes.noThreads}>
          <p>
            No threads.
            <br />
            Start creating messages...
          </p>
        </div>
      )}

      {searchedIsNull && (
        <div className={classes.noThreads}>
          <p>No search results.</p>
        </div>
      )}

      {!searchedIsNull &&
        threads.map((thread: Thread, i) => {
          return (
            <div
              onMouseEnter={() => setPreviewIndex(i)}
              onMouseLeave={() => setPreviewIndex(null)}
              key={thread.id}
              className={`${classes.thread} ${
                thread.id === chosenThread?.id ? classes.on : ""
              }`}
              onClick={() => {
                setChosenThread(thread);
              }}
            >
              <div className={classes.imgBox}>
                <img src={coaches.find(coach => coach.type === thread.threadType)?.img} alt='profile-img' />
              </div>
              <div className={classes.body}>
                <p title={thread.lastQuestion ?? ''} className={classes.title}>
                  {(previewIndex === i && thread.lastQuestion.length !== 0)
                    ? thread.lastQuestion.slice(0, 18) + "..."
                    : coachNameMapping[thread.threadType] + " Coach"}
                </p>
                <p className={classes.subtitle}>
                  {thread.lastResponse.length === 0 ? 'Ask me something' : thread.lastResponse.slice(0, 55) + ".."}
                </p>
              </div>
              <p className={classes.updatedAt}>
                {formatMysqlDatetime(thread.updatedAt!).pstSince}
              </p>
              {previewIndex === i && (
                <div
                  onClick={(e) => deleteThread(e, thread)}
                  onMouseEnter={() => setXHover(true)}
                  onMouseLeave={() => setXHover(false)}
                  className={`${classes.x} ${xHover ? classes.xOn : ""}`}
                >
                  x
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default LeftPane;
