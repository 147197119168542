import { ProjectPlanStatus } from "../../models";

export const projectStatusLabels = {
    [ProjectPlanStatus.DRAFT]: 'Draft',
    [ProjectPlanStatus.PENDING_APPROVAL]: 'Pending Approval',
    [ProjectPlanStatus.APPROVED_PENDING_START]: 'Approved - Pending Start',
    [ProjectPlanStatus.IN_PROGRESS]: 'In Progress',
    [ProjectPlanStatus.BLOCKED]: 'Blocked',
    [ProjectPlanStatus.COMPLETE]: 'Complete',
    [ProjectPlanStatus.CANCELLED]: 'Cancelled',
    [ProjectPlanStatus.SHELVED]: 'Shelved',
};