import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Card, Input, Button, Row, Col, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ProjectPlan, SizeEstimateUnit } from '../../../models';
import { projectStatusLabels } from '../projectUtils';

const { TextArea, Search } = Input;

const ProjectList = () => {
  const [projectPlans, setProjectPlans] = useState([]);
  const [newProjectName, setNewProjectName] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchProjectPlans();
  }, []);

  const fetchProjectPlans = async () => {
    const plans = await DataStore.query(ProjectPlan);
    setProjectPlans(plans);
  };

  const addProjectPlan = async () => {
    if (newProjectName.trim() === '') return;
    const newProject = await DataStore.save(
      new ProjectPlan({
        name: newProjectName,
        status: 'Draft',
        workDays: 5,
        sizeEstimates: [
          { name: 'XS', min: 1, max: 1, unit: SizeEstimateUnit.DAYS },
          { name: 'S', min: 1, max: 2, unit: SizeEstimateUnit.DAYS },
          { name: 'M', min: 2, max: 3, unit: SizeEstimateUnit.DAYS },
          { name: 'L', min: 4, max: 7, unit: SizeEstimateUnit.DAYS },
          { name: 'XL', min: 8, max: 14, unit: SizeEstimateUnit.DAYS }
        ], // Set your default size estimates here
        taskGroups: [
          { id: "1", name: 'Feature A', taskIds: [] },
          { id: "2", name: 'Feature B', taskIds: [] },
          { id: "3", name: 'Feature C', taskIds: [] }
        ]
      })
    );
    setProjectPlans([...projectPlans, newProject]);
    setNewProjectName('');
  };

  const deleteProjectPlan = async () => {
    await DataStore.delete(projectToDelete);
    fetchProjectPlans();
    setIsModalVisible(false);
    setProjectToDelete(null);
  };

  const showDeleteConfirm = (project) => {
    setProjectToDelete(project);
    setIsModalVisible(true);
  };

  return (
    <div>
      <h1>Project Planner</h1>

      {/* Search Project Planners */}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={10}>
            <Search
                placeholder="Search by name"
                onSearch={value => setProjectPlans(projectPlans.filter(plan => plan.name.toLowerCase().includes(value.toLowerCase())))}
                style={{ marginBottom: 16 }}
            />
        </Col>
      </Row>

      {/* Add New Project Planner */}
      <Row gutter={16} style={{ marginBottom: 16}}>
        <Col span={10}>
          <TextArea
            rows={1}
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            onPressEnter={addProjectPlan}
            placeholder="Enter new project name"
            />
        </Col>
        <Col>
          <Button onClick={addProjectPlan} type="primary">
            Add
          </Button>
        </Col>
      </Row>

      {/* List Project Planners */}
      <Row gutter={16}>
        {projectPlans.map(plan => (
          <Col span={8} key={plan.id}>
            <Card
              title={plan.name}
              actions={[
                <DeleteOutlined key="delete" onClick={() => showDeleteConfirm(plan)} />,
              ]}
              style={{ marginBottom: 16, cursor: 'pointer'}}
            >
              <div onClick={() => navigate(`/project/${plan.id}`)} >
                <p>Status: {projectStatusLabels[plan.status]}</p>
                { plan.startDate &&
                  <p>Start Date: {plan.startDate}</p> 
                }
                { (plan.currentCompletionDate || plan.initialCompletionDate) &&
                  <p>Completion Date: {plan.currentCompletionDate || plan.initialCompletionDate || 'Not Set'}</p>
                }
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Delete Project Plan Modal */}
      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onOk={deleteProjectPlan}
        onCancel={() => setIsModalVisible(false)}
      >
        <p>Are you sure you want to delete this project plan?</p>
      </Modal>
    </div>
  );
};

export default ProjectList;
