import React from 'react';
import styled from 'styled-components';
import {Tag} from 'antd'

const CalendarListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const CalendarItem = styled.div`
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: ${({ visible, color }) => (visible ? color : '#f0f0f0')};
  color: ${({ visible }) => (visible ? '#fff' : '#888')};

  &:hover {
    background-color: ${({ visible, color }) =>
      visible ? color : '#e0e0e0'};
  }
`;

const CalendarList = ({ calendars, onToggleCalendar }) => {
  return (
    <CalendarListContainer>
      {calendars.map((calendar) => (
        <Tag 
            color={calendar.visible?calendar.color:'grey'}
            onClick={() => onToggleCalendar(calendar.id)}
            style={{ borderRadius: '6px', borderColor: 'grey', color: 'black' }}
        >
                            {calendar.name}
                        </Tag>
        // <CalendarItem
        //   key={calendar.id}
        //   visible={calendar.visible}
        //   color={calendar.color}
        //   onClick={() => onToggleCalendar(calendar.id)}
        // >
        //   {calendar.name}
        // </CalendarItem>
      ))}
    </CalendarListContainer>
  );
};

export default CalendarList;
