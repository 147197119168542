import React, { useState, useEffect} from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import { Layout, Menu, Button } from 'antd';
import {
  RightOutlined,
  LeftOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleChat, setChatWidth, setChatOpen} from '../../store/actions';

import ChatWindow from '../../features/assistant/ChatWindow';
import routes from '../routes';
import TrackingEntryEditor from '../../features/tracker/components/TrackingEntryEditor';
import { useTracking } from '../../features/tracker/hooks/TrackingContext';

const { Sider } = Layout;

const MainLayout = ({ children }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [scoreboardToggle, setScoreboardToggle] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const { loading, 
    trackingItems, 
    trackingCategories, 
    categoryIdToColorMap: categoryColors, 
    todaysTrackingEntry, 
    saveTrackingEntry } = useTracking();

  const location = useLocation();
  const dispatch = useDispatch();
  const { theme, chatWidth, chatOpen, isAdmin, username } = useSelector((state) => state.app);  

  useEffect(() => {
  const currentRoute = routes.find(
    route => ((route.path + '/') === location.pathname) || (route.path === location.pathname));
  if (currentRoute) {
    setSelectedMenuItem(currentRoute.name);
  } else {
    // If no exact match, find the closest parent route
    const parentRoute = routes.find(route => location.pathname.startsWith(route.path));
    if (parentRoute) {
      setSelectedMenuItem(parentRoute.name);
    }
  }
}, [location]);

  const contentStyles = {
    marginLeft: collapsed ? '80px' : '200px',
    paddingTop: '25px',
    paddingLeft: '25px',
    transition: 'all 0.5s',
    maxWidth: '100%',
    overflow: 'auto',
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };
  // Handle resizing the chat window
  const handleMouseDown = (event) => {
    event.preventDefault(); // Prevent text selection or other unwanted behavior
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event) => {
    // Calculate the new width based on mouse movement
    const newWidth = window.innerWidth - event.clientX;
    // set tota
    if (newWidth > 0 && newWidth <= window.innerWidth * 0.4) {
      dispatch(setChatOpen(true))
      dispatch(setChatWidth(newWidth));
    }else if (newWidth<= 0 || !window.innerWidth) {
      dispatch(setChatOpen(false));
    }
  };

  const handleMenuClick = (e) => {
    setSelectedMenuItem(e.key);
  };

  const handleToggleChat = () => {
    dispatch(toggleChat());
    dispatch(setChatWidth(chatOpen? 0 : window.innerWidth * 0.2));
  };

const appStyles = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
    height: '100vh',
};

  const siderStyles = {
    position: 'fixed',
    height: '100vh',
    left: 0,
    top: 0,
    transition: 'width 0.2s',
    backgroundColor: theme.siderBackgroundColor,
    color: theme.textColor,
  };

  const chatSiderStyles = {
    height: '100vh',
    right: 0,
    top: 64,
    zIndex: 10,
    boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    transition: 'width 0.5s',
    width: `${chatWidth}px!important`,
    backgroundColor: theme.siderBackgroundColor,
    color: theme.textColor,
  };

  return (
    <Layout style={appStyles}>

      {/* Custom Drawer for Daily Scoreboard */}
      <div
        style={{
          position: 'fixed',
          bottom: scoreboardToggle ? 0 : '-100%',
          left: 100,
          right: 50,
          height: '20%',
          backgroundColor: 'white',
          boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.15)',
          transition: 'bottom 0.3s ease-in-out',
          zIndex: 1000,
          padding: '20px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
        onMouseLeave={() => setScoreboardToggle(false)}
      >
        <TrackingEntryEditor
          loading={loading}
          entry={todaysTrackingEntry}
          onSave={saveTrackingEntry}
          trackingItems={trackingItems}
          trackingCategories={trackingCategories}
          categoryColors={categoryColors}
        />
      </div>

      {/* Scoreboard drawer handle */}
      {!scoreboardToggle && (
          <Button
            type="primary"
            shape="circle"
            icon={<UpOutlined />}
            style={{
              position: 'fixed',
              bottom: -8,
              transform: 'translateX(-50%)',
              left: '50%',
              zIndex: 100,
            }}
            onMouseEnter={() => setScoreboardToggle(true)}
          />
        )}

        {/* Main content layout */}
        <Layout style={{ flex: 1 }}>
            {/* Sidebar for navigation */}
            <Sider
            collapsible
            collapsed={collapsed}
            onMouseEnter={() => setCollapsed(false)}
            onMouseLeave={() => setCollapsed(true)}
            style={siderStyles}
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                    <Menu
                        theme={theme === 'dark' ? 'dark' : 'light'}
                        mode="inline"
                        selectedKeys={[selectedMenuItem]}
                        onClick={handleMenuClick}
                        style={{ backgroundColor: theme.siderBackgroundColor, color: theme.textColor }}
                    >
                        {routes.filter(route => !route.hidden && (!route.admin || isAdmin)).map((route, index) => (
                            <React.Fragment key={index}>
                                <Menu.Item key={route.name} icon={route.icon}>
                                    <NavLink to={route.path}>{route.name === 'Account' ? username : route.name}</NavLink>
                                </Menu.Item>
                                {route.name === 'Account' && <Menu.Divider />}
                            </React.Fragment>
                        ))}
                    </Menu>
                    <Outlet />  {/* This will render child route components */}
                </div>
            </Sider>

            {/* Main content */}
            <Content style={contentStyles} >
                {children}
            </Content>
        </Layout>

        {/* Chat window sidebar */}
        <Sider
            collapsible
            collapsed={chatWidth === null} // Collapse chat window when width is 0
            width={chatWidth}
            style={chatSiderStyles}
            className="chat-sider"
        >
            {/* Resizable handle */}
            <div
            style={{
                width: '5px',
                cursor: 'ew-resize',
                position: 'absolute',
                left: '-5px',
                height: '100%',
            }}
            onMouseDown={handleMouseDown}
            ></div>

            {/* Close/Open chat button */}
            <Button
            type="primary"
            shape="circle"
            icon={chatOpen ? <RightOutlined /> : <LeftOutlined />}
            style={{
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'translate(-25px, -50%)', // Center the button vertically
                zIndex: 100,
            }}
            onClick={handleToggleChat}
            ></Button>
            {<ChatWindow />}
        </Sider>
    </Layout>
  );
}

export default MainLayout;