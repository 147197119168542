import React, { useState, useCallback, useMemo, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Select, Collapse } from 'antd';
import {  MenuOutlined } from '@ant-design/icons';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';
import { formatStatusName,groupTasks } from '../taskUtils';
import { fetchTasks } from '../../../store/actions';
import TaskModal from './TaskModal';
import { DataStore } from 'aws-amplify';
import dayjs from 'dayjs';
import { Task, TaskStatus, Priority}  from '../../../models';
import ColumnSelector from './ColumnSelector';
import { getColumnDefinitions} from './TaskTableColumns';
import { useTheme } from '../../../app/utils';

const { Option } = Select;
const { Panel } = Collapse;

const TaskTable = React.memo(({ tasks, setTasks, loading }) => {
    const dispatch = useDispatch();
    const { userId, isDemoMode, assigneeOptions, projectNamesOptions } = useSelector((state) => state.app);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [groupingCriterion, setGroupingCriterion] = useState('status');
    const [newTasks, setNewTasks] = useState({});
    const [expandedPanels, setExpandedPanels] = useState([]);
    const [tempEditValues, setTempEditValues] = useState({});
    const [editingCell, setEditingCell] = useState(null); // state for field being updated/edited
    const [columns, setColumns] = useState([
        { key: 'title', title: 'Title', visible: true, fixed: true },
        { key: 'status', title: 'Status', visible: true },
        { key: 'priority', title: 'Priority', visible: true },
        { key: 'assignee', title: 'Assignee', visible: true },
        { key: 'dueDate', title: 'Due Date', visible: true },
        { key: 'duration', title: 'Duration', visible: true },
        { key: 'project', title: 'Project', visible: true },
        { key: 'feature', title: 'Feature', visible: true},
        { key: 'size', title: 'Size', visible: true },
        { key: 'actions', title: 'Actions', visible: true },
    ]);

    
    const theme = useTheme();

    const statusSorter = useCallback((a, b) => {
        if(a.id === "new") return;
        if(b.id === "new") return;
        return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
    });
    const prioritySorter = useCallback((a, b) => {
        if(a.id === "new") return;
        if(b.id === "new") return;
        return priorityOrder.indexOf(a.priority) - priorityOrder.indexOf(b.priority);
    })
    
    const statusOrder = useMemo(() => [
        TaskStatus.PENDING_APPROVAL,
        'Not Set',
        TaskStatus.BLOCKED,
        TaskStatus.IN_PROGRESS,
        TaskStatus.NOT_STARTED,
        TaskStatus.COMPLETED,
    ], []);

    const priorityOrder = useMemo(() => [
        Priority.HIGH,
        Priority.MEDIUM,
        Priority.LOW,
        'Not Set',   
    ], []);

    const StyledSelect = styled(Select)`
        width: 200px;
        background-color: ${theme.backgroundColor};
        color: ${theme.textColor};
    `;

    const StyledButton = styled(Button)`
        background-color: ${theme.buttonBackgroundColor};
        color: ${theme.buttonTextColor};
    `;

    const tableSetting = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <StyledSelect
        value={groupingCriterion}
        onChange={handleGroupChange}
        style={{ width: '200px' }}
      >
        <Option value="status">Group by Status</Option>
        <Option value="priority">Group by Priority</Option>
        <Option value="dueDate">Group by Due Date</Option>
        <Option value="duration">Group by Duration</Option>
        <Option value="energyImpact">Group by Energy Impact</Option>
        <Option value="source">Group by Source</Option>
        <Option value="project">Group by Project</Option>
        <Option value="feature">Group by Feature</Option>
        <Option value="size">Group by Size</Option>
      </StyledSelect>
      <ColumnSelector columns={columns} toggleColumnVisibility={toggleColumnVisibility} />
    </div>
  );

    const addNewTaskRow = useCallback((group) => {
        setNewTasks(prev => ({
            ...prev,
            [group]: getDefaultTaskValues( group, groupingCriterion)
        }));
    }, [ groupingCriterion]);

    const deleteTask = useCallback(async (task, event) => {
        event.stopPropagation();
        if (isDemoMode) {
            console.info('Demo mode: Database task deletion disabled');
            dispatch(setTasks(tasks.filter((t) => t.id !== task.id)));
            return;
        }
        setTaskToDelete(task);
        setIsDeleteModalVisible(true);
    }, [isDemoMode, dispatch, setTasks, tasks]);

    const confirmDeleteTask = useCallback(async () => {
        if (!taskToDelete) return;

        try {
            const taskToDeleteFromDB = await DataStore.query(Task, taskToDelete.id);
            await DataStore.delete(taskToDeleteFromDB);
            dispatch(fetchTasks(userId));
        } catch (error) {
            console.error(`Error deleting task: ${error}`);
        } finally {
            setIsDeleteModalVisible(false);
            setTaskToDelete(null);
        }
    }, [taskToDelete, dispatch, userId]);

    const handlePanelChange = (key) => {
        setExpandedPanels(key);
    };

    const handleEditTask = useCallback((task) => {
        setSelectedTask(task);
        setIsTaskModalOpen(true);
    }, []);

    const updateTask = useCallback(async (updatedTask) => {
        if (isDemoMode) {
            console.info('Demo mode: Database task update disabled');
            dispatch(setTasks(tasks.map((t) => t.id === updatedTask.id ? { ...t, ...updatedTask } : t)));
            return;
        }

        try {
            const originalTask = await DataStore.query(Task, updatedTask.id);
            if (!originalTask) throw new Error(`Task with id ${updatedTask.id} not found`);

            await DataStore.save(Task.copyOf(originalTask, (updated) => {
                Object.assign(updated, updatedTask);
                updated.id = originalTask.id;
                updated.userId = originalTask.userId;
                updated.createdAt = originalTask.createdAt;
                updated.updatedAt = null;
            }));

            dispatch(fetchTasks(userId));
        } catch (error) {
            console.error(`Error updating task: ${error}`);
        } finally {
            setIsTaskModalOpen(false);
        }
    }, [isDemoMode, dispatch, setTasks, tasks, userId]);

    const handleCellClick = useCallback((id, field, value) => {
        setEditingCell({ id, field });
        setTempEditValues(prev => ({
            ...prev,
            [id]: { ...prev[id], [field]: value }
        }));
    }, []);

    const handleCellChange = useCallback((id, field, value) => {
        setTempEditValues(prev => ({
            ...prev,
            [id]: { ...prev[id], [field]: value }
        }));
    }, []);

    const handleCellEdit = useCallback(async (id, field, value) => {
    if (isDemoMode) {
        console.info('Demo mode: Database task update disabled');
        dispatch(setTasks(tasks.map((t) => t.id === id ? { ...t, [field]: value } : t)));
        return;
    }

    try {
        // Fetch the latest version of the task from DataStore
        const originalTask = await DataStore.query(Task, id);
        if (!originalTask) {
            console.error(`Task with id ${id} not found`);
            return;
        }

        // Perform the update
        const updatedTask = await DataStore.save(
            Task.copyOf(originalTask, (updated) => {
                updated[field] = value;
            })
        );

        // Update local state
        dispatch(setTasks(tasks.map((t) => t.id === id ? updatedTask : t)));

        // Optionally, you can refresh all tasks
        // dispatch(fetchTasks(userId));
    } catch (error) {
        console.error(`Error updating task: ${error}`);
        // Optionally, revert the optimistic update
        // dispatch(setTasks(tasks));
    }
}, [isDemoMode, dispatch, setTasks, tasks, userId]);

    const handleCellBlur = useCallback(async (id, field) => {
        const newValue = tempEditValues[id]?.[field];
        if (newValue !== undefined) {
            await handleCellEdit(id, field, newValue);
        }
        setEditingCell(null);
        setTempEditValues(prev => {
            const newTemp = { ...prev };
            delete newTemp[id];
            return newTemp;
        });
    }, [tempEditValues, handleCellEdit]);
    

    const baseDefaults = useMemo(() => {
        const nextMonday = dayjs().day(8).hour(18).minute(0).second(0);
        return {
            assignee: userId,
            status: TaskStatus.NOT_STARTED,
            priority: Priority.MEDIUM,
            dueDate: nextMonday.format('YYYY-MM-DDTHH:mm'),
            duration: 60,
        };
    }, [userId]);;

    const getDefaultTaskValues = (group, groupingCriterion) => {
        switch (groupingCriterion) {
            case 'status':
                return { ...baseDefaults, status: group  !== "Not Set" ? group: "" };
            case 'priority':
                return { ...baseDefaults, priority: group !== "Not Set" ? group: ""};
            case 'dueDate':
                return { ...baseDefaults, dueDate: dayjs(group).format('YYYY-MM-DDTHH:mm') };
            case 'duration':
                const durationInMinutes = parseInt(group);
                return { ...baseDefaults, duration: isNaN(durationInMinutes) ? 60 : durationInMinutes};
            case 'energyImpact':
                return { ...baseDefaults, energyImpact: group !== "Not Set" ? group: ""};
            case 'source':
                return { ...baseDefaults, source: group !== "Not Set" ? group: ""};
            case 'project':
                return { ...baseDefaults, projectId: group !== "Not Set" ? group: ""};
            case 'feature':
                return { ...baseDefaults, taskGroupId: group !== "Not Set" ? group: ""};
            case'size':
                return { ...baseDefaults, sizeEstimate: group !== "Not Set" ? group: ""};
            default:
                return { ...baseDefaults,  };
        }
    };

    const handleNewTaskChange = useCallback((value, field, group) => {
    setNewTasks(prev => {
        const updatedGroup = {
            ...prev[group],
            [field]: value,
        };

        // Reset the taskGroupId (feature) if a new project is selected
        if (field === 'projectId') {
            updatedGroup.taskGroupId = ''; // Reset feature selection
        }

        return {
            ...prev,
            [group]: updatedGroup
        };
    });
}, []);


    const handleAddTask = useCallback(async (group) => {
        const newTask = newTasks[group];
        if (!newTask?.title) return;

        const taskToAdd = {
            ...newTask,
            userId,
            
        };

        if (isDemoMode) {
            console.info('Demo mode: Database task addition disabled');
            dispatch(setTasks([...tasks, taskToAdd]));
        } else {
            try {
                await DataStore.save(new Task(taskToAdd));
                dispatch(fetchTasks(userId));
            } catch (error) {
                console.error(`Error adding task: ${error}`);
            }
        }

        setNewTasks(prev => ({ ...prev, [group]: {} }));
    }, [newTasks, userId, isDemoMode, dispatch, setTasks, tasks]);

    const groupedTasks = useMemo(() => groupTasks(tasks, groupingCriterion, projectNamesOptions), [tasks, groupingCriterion, projectNamesOptions]);

    const allGroupKeys = useMemo(() => Object.keys(groupedTasks), [groupedTasks]);

    useEffect(() => {
        setExpandedPanels(allGroupKeys);
    }, [allGroupKeys]);

    const groupColumns =useMemo(() => getColumnDefinitions({
        statusSorter,
        prioritySorter,
        editingCell,
        newTasks,
        handleNewTaskChange,
        addNewTaskRow,
        handleAddTask,
        tempEditValues,
        handleCellChange,
        handleCellBlur,
        handleCellClick,
        handleEditTask,
        userId,
        assigneeOptions,
        projectNamesOptions,
        deleteTask
    }), [statusSorter, prioritySorter, editingCell, newTasks, handleNewTaskChange, addNewTaskRow, handleAddTask, tempEditValues, handleCellChange, handleCellBlur, handleCellClick, handleEditTask, userId, assigneeOptions, projectNamesOptions, deleteTask]);
    const handleGroupChange = useCallback((value) => {
        setGroupingCriterion(value);
    }, []);

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setColumns((items) => {
                const oldIndex = items.findIndex((item) => item.key === active.id);
                const newIndex = items.findIndex((item) => item.key === over.id);
                const newItems = [...items];
                const [reorderedItem] = newItems.splice(oldIndex, 1);
                newItems.splice(newIndex, 0, reorderedItem);
                return newItems;
            });
        }
    }, []);

    const toggleColumnVisibility = useCallback((key) => {
        setColumns((prevColumns) =>
            prevColumns.map((col) =>
                col.key === key && !col.fixed ? { ...col, visible: !col.visible } : col
            )
        );
    }, []);

    const SortableHeader = ({ column }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: column.key });
        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            cursor: 'move',
        };

        return (
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {!column.fixed && <MenuOutlined style={{ marginRight: 8 }} />}
                {column.title}
            </div>
        );
    };

    const visibleColumns = useMemo(() => {
        return columns
            .filter((col) => col.visible)
            .map((col) => ({
                ...getColumnDefinitions({
                statusSorter,
                prioritySorter,
                editingCell,
                newTasks,
                handleNewTaskChange,
                addNewTaskRow,
                handleAddTask,
                tempEditValues,
                handleCellChange,
                handleCellBlur,
                handleCellClick,
                handleEditTask,
                userId,
                assigneeOptions,
                projectNamesOptions,
                deleteTask
            })
            .find((groupCol) => groupCol.key === col.key),
                title: <SortableHeader column={col} />,
                fixed: col.fixed ? (col.key === 'title' ? 'left' : 'right') : undefined,
            }));
    }, [columns, statusSorter, prioritySorter, editingCell, newTasks, handleNewTaskChange, addNewTaskRow, handleAddTask, tempEditValues, handleCellChange, handleCellBlur, handleCellClick, handleEditTask, userId, assigneeOptions, projectNamesOptions, deleteTask]);

    return (
        <>
            <div style={{
                color:theme.buttonTextColor,
                marginBottom:'10px'
            }}>{tableSetting()}</div>
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} style={{
                backgroundColor: theme.backgroundColor,
                color: theme.buttonTextColor
            }}>
                <SortableContext items={columns.map((col) => col.key)} strategy={verticalListSortingStrategy}>
                <Collapse 
                    activeKey={expandedPanels} 
                    onChange={handlePanelChange}
                    
                >
                    {Object.entries(groupedTasks).map(([group, groupTasks]) => (
                    <Panel 
                        header={<div style={{color: theme.textColor}}>{formatStatusName(group)}</div>} 
                        key={group}
                        style={{
                            backgroundColor:theme.backgroundColor,
                            color:theme.buttonTextColor
                        }} 
                         
                    >
                        <Table
                            columns={visibleColumns}
                            dataSource={[...groupTasks, { id: 'new', group }]}
                            pagination={false}
                            rowKey="id"
                            size="small"
                            loading={loading}
                            scroll={{ x: 1500 }}
                            onRow={(record) => ({
                                onClick: (event) => {
                                    // Prevent row click from triggering when clicking on action buttons
                                    if (event.target.tagName === 'BUTTON' || event.target.closest('button')) {
                                        return;
                                    }
                                    if (record.id === 'new') {
                                        return;
                                    }
                                },
                                onDoubleClick: () => {
                                    if (record.id !== 'new') {
                                        handleEditTask(record);
                                    }
                                },
                            })}
                        />
                    </Panel>
                    ))}
                </Collapse>
                </SortableContext>
            </DndContext>


            <Modal
                title="Confirm Deletion"
                visible={isDeleteModalVisible}
                onOk={confirmDeleteTask}
                onCancel={() => setIsDeleteModalVisible(false)}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete this task?</p>
            </Modal>

            <TaskModal
                isVisible={isTaskModalOpen}
                onCancel={() => setIsTaskModalOpen(false)}
                onSubmit={updateTask}
                task={selectedTask}
                isEditMode
            />
        </>
    );
});

export default TaskTable;