import { useState } from 'react';
import { Row, Col, Card, Statistic, Spin, DatePicker } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';

const DailyActivityComponent = ({ activityData }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);

    // Create a set of dates that have data points
    const availableDates = new Set(activityData.map(activity => dayjs(activity.date).format('YYYY-MM-DD')));

    // Function to disable dates that are not in the available dates set
    const disabledDate = (current) => {
        return !availableDates.has(current.format('YYYY-MM-DD'));
    };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredActivityData = activityData.find(activity => dayjs(activity.date).isSame(selectedDate, 'day'));

  const renderActivityCards = (activity) => (
    <Row gutter={16}>
      <Col span={8}>
        <Card>
          <Statistic title="Total Steps" value={activity.totalSteps} />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic title="Total Calories" value={activity.totalCalories} />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic title="Active Calories" value={activity.activeCalories} />
        </Card>
      </Col>
    </Row>
  );

  const renderAdditionalMetrics = (additionalFields) => (
    <Row gutter={16}>
      <Col span={8}>
        <Card>
          <Statistic title="Meet Daily Targets" value={additionalFields.meetDailyTargets} suffix="%" />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic title="Move Every Hour" value={additionalFields.moveEveryHour} suffix="%" />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic title="Stay Active" value={additionalFields.stayActive} suffix="%" />
        </Card>
      </Col>
    </Row>
  );

  const renderLineChart = (metData) => {
    const chartData = metData.items.map((value, index) => ({ name: index, met: value }));

    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="met" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div>
      <DatePicker 
        value={selectedDate} 
        onChange={handleDateChange} 
        disabledDate={disabledDate}
      />

      {loading ? (
        <Spin />
      ) : filteredActivityData ? (
        <div>
          <h2>{dayjs(filteredActivityData.date).format('MMMM D, YYYY')}</h2>
          {renderActivityCards(filteredActivityData)}
          {renderAdditionalMetrics(filteredActivityData.additionalFields)}
          {renderLineChart(filteredActivityData.additionalFields.met)}
        </div>
      ) : (
        <div>No data available for the selected date</div>
      )}
    </div>
  );
};

export default DailyActivityComponent;
