
export const themes: Record<string, Theme> = {
    light: {
      primaryColor: '#1890ff',
      backgroundColor: '#ffffff',
      textColor: '#333333',
      siderBackgroundColor: '#f0f2f5',
      headerBackgroundColor: '#ffffff',
      headerTextColor: '#333333',
      buttonBackgroundColor: '#1890ff',
      buttonTextColor: '#ffffff',
      selectBackgroundColor: '#ffffff',
      selectTextColor: '#333333',
      selectItemColor: '#1890ff',
      chatBackgroundColor: '#f0f2f5',
      chatTextColor: '#333333',
      chatInputBorder: '1px solid #d9d9d9',
      chatSendButtonColor: '#1890ff',
      chatSendButtonHoverColor: '#40a9ff',
      // taskList items
      taskCheckColor: '#1890ff',
      taskDeleteColor: '#ff4d4f',
      // chat window
      chatCardBackgroundColor: '#f0f2f5',
      chatUserBackgroundColor: '#000000',
      chatUserTextColor: '#ffffff',
      chatRobotBackgroundColor: '#000000',
      chatRobotTextColor: '#ffffff',
      chatUserBubbleBgColor: "#11A690",
      chatRobotBubbleBgColor: "#473A64",
      chatUserBubbleTextColor: "#ffffff",
      chatRobotBubbleTextColor: "#ffffff",
      // chat window

    },
    dark: {
      primaryColor: '#1890ff',
      backgroundColor: '#262626',
      textColor: '#A64C11',
      siderBackgroundColor: '#001529',
      headerBackgroundColor: '#262626',
      headerTextColor: '#ffffff',
      buttonBackgroundColor: '#1890ff',
      buttonTextColor: '#ffffff',
      selectBackgroundColor: '#333333',
      selectTextColor: '#ffffff',
      selectItemColor: '#1890ff',
      chatBackgroundColor: '#001529',
      chatTextColor: '#ffffff',
      chatInputBorder: '1px solid #404040',
      chatSendButtonColor: '#1890ff',
      chatSendButtonHoverColor: '#40a9ff',
      // taskList items
      taskListBackgroundColor: '#5b5b5b',
      taskCheckColor: '#1890ff',
      taskDeleteColor: '#ff4d4f',
      // chat window
      chatCardBackgroundColor: '#000000',
      chatUserBackgroundColor: '#ffffff',
      chatUserTextColor: '#000000',
      chatRobotBackgroundColor: '#ffffff',
      chatRobotTextColor: '#000000',
      chatUserBubbleBgColor: "#1E0E42",
      chatRobotBubbleBgColor: "#11A690",
      chatUserBubbleTextColor: "#ffffff",
      chatRobotBubbleTextColor: "#ffffff",
    },
    bubblegum: {
      primaryColor: '#38B6FF',
      backgroundColor: '#FFDE59',
      textColor: '#FF66C4',
      siderBackgroundColor: '#38B6FF',
      headerBackgroundColor: '#ffffff',
      headerTextColor: '#333333',
      buttonBackgroundColor: '#1890ff',
      buttonTextColor: '#ffffff',
      selectBackgroundColor: '#ffffff',
      selectTextColor: '#333333',
      selectItemColor: '#1890ff',
      chatBackgroundColor: '#CB6CE6',
      chatTextColor: '#333333',
      chatInputBorder: '1px solid #d9d9d9',
      chatSendButtonColor: '#1890ff',
      chatSendButtonHoverColor: '#40a9ff',
      // chat window
      chatCardBackgroundColor: '#FFFFFF',
      chatUserBackgroundColor: '#000000',
      chatUserTextColor: '#ffffff',
      chatRobotBackgroundColor: '#000000',
      chatRobotTextColor: '#ffffff',
      chatUserBubbleBgColor: "#38B6FF",
      chatRobotBubbleBgColor: "#CB6CE6",
      chatUserBubbleTextColor: "#ffffff",
      chatRobotBubbleTextColor: "#ffffff",

    },
    panther: {
      primaryColor: '#38B6FF',
      backgroundColor: '#1e1f1f',
      textColor: '#5d5c5b',
      siderBackgroundColor: '#333333',
      headerBackgroundColor: '#ffffff',
      headerTextColor: '#f492ad',
      buttonBackgroundColor: '#f492ad',
      buttonTextColor: '#ffffff',
      selectBackgroundColor: '#4d4d4c',
      selectTextColor: '#f1ecd8',
      selectItemColor: '#1890ff',
      chatBackgroundColor: '#CB6CE6',
      chatTextColor: '#333333',
      chatInputBorder: '1px solid #d9d9d9',
      chatSendButtonColor: '#1890ff',
      chatSendButtonHoverColor: '#40a9ff',
      // taskList items
      taskListBackgroundColor: '#5d5c5b',
      // chat window
      chatCardBackgroundColor: '#1e1f1f',
      chatUserBackgroundColor: '#000000',
      chatUserTextColor: '#ffffff',
      chatRobotBackgroundColor: '#000000',
      chatRobotTextColor: '#ffffff',
      chatUserBubbleBgColor: "#38B6FF",
      chatRobotBubbleBgColor: "#5d5c5b",
      chatUserBubbleTextColor: "#ffffff",
      chatRobotBubbleTextColor: "#ffffff",
    },
  };

  export interface Theme {
    primaryColor: string;
    backgroundColor: string;
    textColor: string;
    siderBackgroundColor: string;
    headerBackgroundColor: string;
    headerTextColor: string;
    buttonBackgroundColor: string;
    buttonTextColor: string;
    selectBackgroundColor: string;
    selectTextColor: string;
    selectItemColor: string;
    chatBackgroundColor: string;
    chatTextColor: string;
    chatInputBorder: string;
    chatSendButtonColor: string;
    chatSendButtonHoverColor: string;
    // Task list items
    taskListBackgroundColor?: string;
    taskCheckColor?: string;
    taskDeleteColor?: string;
    // Chat window
    chatCardBackgroundColor: string;
    chatUserBackgroundColor: string;
    chatUserTextColor: string;
    chatRobotBackgroundColor: string;
    chatRobotTextColor: string;
    chatUserBubbleBgColor: string;
    chatRobotBubbleBgColor: string;
    chatUserBubbleTextColor: string;
    chatRobotBubbleTextColor: string;
  }