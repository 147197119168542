import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Form, Input, Select, Button, DatePicker, Row, Col, Tooltip } from 'antd';
import { StarOutlined, FlagOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { taskStatusLabels, getTaskStatusColor } from '../taskUtils';
import { getColorByPriority } from '../../../app/utils';
import { TaskStatus, Priority, EnergyImpact, EnergyProfile, TaskType, ProjectPlan } from '../../../models';
import { DataStore } from 'aws-amplify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const { Option } = Select;

const selectableTaskStatuses = [
  TaskStatus.NOT_STARTED,
  TaskStatus.IN_PROGRESS,
  TaskStatus.COMPLETED,
  TaskStatus.BLOCKED,
];

const TaskModal = ({
  isVisible,
  onCancel,
  onSubmit,
  task,
  isDraftMode = false,
  isEditMode = false,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userEnergyProfile, setUserEnergyProfile] = useState(null);
  const [taskTypes, setTaskTypes] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [requireCompletionDate, setRequireCompletionDate] = useState(false);
  const [showAddTaskToProjectInputs, setShowAddTaskToProjectInputs] = useState(false);
  const [projectPlans, setProjectPlans] = useState([]);
  const [selectedProjectId, setSelectedProject] = useState(null);
  const [selectedTaskGroupId, setSelectedTaskGroupId] = useState(null);

  const { userId,  assigneeOptions} =  useSelector((state) => state.app);

  useEffect(() => {
    console.log('Assignee Options:', assigneeOptions); // Debugging step
  }, [assigneeOptions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profiles, types] = await Promise.all([
          DataStore.query(EnergyProfile, c => c.userId.eq(userId)),
          DataStore.query(TaskType)
        ]);

        if (profiles.length > 0) {
          setUserEnergyProfile(profiles[0]);
        }
        setTaskTypes(types);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    fetchProjectPlans();
  }, [userId]); 

  const fetchProjectPlans = async () => {
    try {
      const projectPlans = await DataStore.query(ProjectPlan);
      setProjectPlans(projectPlans);
    } catch (error) {
      console.error('Error fetching project plans:', error);
    }
  };

  useEffect(() => {
    if (isEditMode && task) {
      setEditFormValues(task);
    } else {
      setDefaultFormValues(form); // Set default values for new tasks
    }
  }, [isEditMode, task, form, taskTypes]); // Added taskTypes to dependencies

  const setEditFormValues = (task) => {
    form.setFieldsValue({
      title: task?.title,
      description: task?.description,
      status: task?.status,
      energyDelta: task?.energyDelta,
      assignee: task?.assignee,
      dueDate: task?.dueDate ? dayjs(task.dueDate) : null,
      priority: task?.priority,
      start: task?.start ? dayjs(task.start) : null,
      duration: task?.duration,
      energyImpact: task?.energyImpact, // Set the existing energy impact value in edit mode
      type: task?.type, // Set the existing task type
      subtype: task?.subtype, // Set the existing subtype
      completedAt: task?.completedAt ? dayjs(task.completedAt) : null,
      projectId: task?.projectId || selectedProjectId,
      taskGroupId: task?.taskGroupId,
      sizeEstimate: task?.sizeEstimate,
    });
    // Require the completion date to stay set if the task is marked completed
    if(task?.completedAt?.length > 0) {
      setRequireCompletionDate(true);
    } else {
      setRequireCompletionDate(false);
    }
    if(!isDraftMode) { // not needed in draft mode
      setSelectedTaskType(task?.type);
      setSubtypes(task?.subtype ? [task?.subtype] : []);
      setRequireCompletionDate(task?.status === TaskStatus.COMPLETED);
    }
    if(task?.projectId) {
      setShowAddTaskToProjectInputs(true);
      setSelectedProject(task?.projectId);
      setSelectedTaskGroupId(task?.taskGroupId);
    } else {
      setShowAddTaskToProjectInputs(false);
    }
  };

  const setDefaultFormValues = (form) => {
    form.resetFields(); // Reset the form fields in create mode

    // Set default values for new tasks
    const defaultType = taskTypes[0]?.name; // Default to the first task type
    const defaultSubtypes = taskTypes[0]?.subtypes || []; // Default to the subtypes of the first task type
    form.setFieldsValue({
      status: TaskStatus.NOT_STARTED,
      priority: Priority.MEDIUM,
      assignee: userId,
      dueDate: dayjs('6:00 PM', 'hh:mm A').add(7, 'day'),
      start: dayjs('10:00 AM', 'hh:mm A'),
      duration: 60,
      type: defaultType,
      subtype: defaultSubtypes[0],
    });
    setSelectedTaskType(defaultType); // Update selected task type
    setSubtypes(defaultSubtypes); // Update subtypes
    setShowAddTaskToProjectInputs(false); // Hide inputs until user selects to add task to project
    setSelectedProject(projectPlans[0]?.id); // but set default to the first project
    setSelectedTaskGroupId(projectPlans[0]?.taskGroups[0]?.id);

    if (defaultType && defaultSubtypes.length > 0) {
      handleSubtypeChange(defaultSubtypes[0], defaultType);
    }
  }; 

  const showProjectInputs = () => {
    form.setFieldsValue({ projectId: selectedProjectId, taskGroupId: selectedTaskGroupId});
    setShowAddTaskToProjectInputs(true);
  };

  const handleProjectIdChange = (value) => {
    setSelectedProject(value);
    const project = projectPlans.find(p => p.id === value);
    const defaultTaskGroup = project?.taskGroups[0]?.id;
    setSelectedTaskGroupId(defaultTaskGroup);
    form.setFieldsValue({ taskGroupId: defaultTaskGroup });
  };
  
  const handleRemoveTaskFromProject = () => {
    form.setFieldsValue({ projectId: null, taskGroupId: null });
    setShowAddTaskToProjectInputs(false);
  };

  const handleStatusChange = (value) => {
    if (value === TaskStatus.COMPLETED) {
      form.setFieldsValue({ completedAt: dayjs(), status: TaskStatus.COMPLETED });
      setRequireCompletionDate(true);
    } else {
      form.setFieldsValue({ completedAt: null, status: value});
      setRequireCompletionDate(false);
    }
  };

  const handleTaskTypeChange = (value) => {
    setSelectedTaskType(value);
    if (value && taskTypes) {
      // Find the task by name type and set the subtypes
      const type = taskTypes.find(t => t.name === value);
      const newSubtypes = type ? type.subtypes : [];
      setSubtypes(newSubtypes);

      // Set the subtype to the first available option and update energy impact accordingly
      if (newSubtypes.length > 0) {
        handleSubtypeChange(newSubtypes[0], value); // Call handleSubtypeChange to update the form values
      } else {
        form.setFieldsValue({ subtype: null, energyImpact: EnergyImpact.NO_DIFFERENCE });
      }
    }
  };

  const handleSubtypeChange = (value, taskType = selectedTaskType) => {
    const defaultEnergyImpact = getDefaultEnergyImpact(taskType, value.value || value);
    form.setFieldsValue({ energyImpact: defaultEnergyImpact, subtype: value });
  };

  const getDefaultEnergyImpact = (taskType, subtype) => {
    const defaultEnergyImpact = userEnergyProfile?.energyMappings?.find(mapping =>
      mapping.taskType === taskType && mapping.subtype === subtype)?.energyImpact || EnergyImpact.NO_DIFFERENCE;
    return defaultEnergyImpact;
  };  

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Convert integers to numbers if they exist
      if (values.energyDelta) {
        values.energyDelta = parseInt(values.energyDelta);
      }
      if (values.duration) {
        values.duration = parseInt(values.duration);
      }
      // Convert dates to a string in the expected format if it exists
      if (values.dueDate) {
        values.dueDate = dayjs(values.dueDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      }
      if (values.start) {
        values.start = dayjs(values.start).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      }
      if (values.completedAt) {
        values.completedAt = dayjs(values.completedAt).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      }
      await onSubmit(isEditMode ? { ...task, ...values } : values);
      if(!isEditMode) { // Reset form values only in create mode
        setDefaultFormValues(form);
      }
      onCancel();
    } catch (error) {
      console.error(
        `Error ${isEditMode ? 'updating' : 'creating'} the task:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  // reset form fields and cancel
  const handleCancel = () => {
    if (!isEditMode) {
      setDefaultFormValues(form);
    } else {
      setEditFormValues(task);
    }
    onCancel();
  };

  return (
    <Modal
      title={isEditMode ? 'Edit Task' : 'Create New Task'}
      open={isVisible}
      onCancel={handleCancel}
      footer={null}
      width={800}
    >

      <Form
        form={form}
        name={isEditMode ? 'editTaskForm' : 'createTaskForm'}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[40, 16]}>
          {/* Left column for title and description */}
          <Col span={16}>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: 'Please enter the title!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea style={{ height: '135px' }} />
                </Form.Item>
              </Col>
                { showAddTaskToProjectInputs ? 
                  (
                    <>
                      <Col span={2}>
                        <Tooltip title="Unlink from Project">
                          <MinusCircleOutlined style={{ marginTop: '40px'}} onClick={handleRemoveTaskFromProject}/>
                        </Tooltip>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="projectId"
                          label="Project"
                          rules={[{ required: showAddTaskToProjectInputs, message: 'Please select a project!' }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={handleProjectIdChange}
                            value={selectedProjectId}
                          >
                            {projectPlans.map((project) => (
                              <Option key={project.id} value={project.id}>
                                {project.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="taskGroupId"
                          label="Feature"
                          rules={[{ required: showAddTaskToProjectInputs, message: 'Please select a feature!' }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={(value) => setSelectedTaskGroupId(value)}
                            disabled={!selectedProjectId}
                            value={selectedTaskGroupId}
                          >
                            {projectPlans.find(p => p.id === selectedProjectId)?.taskGroups.map((group) => (
                              <Option key={group.id} value={group.id}>
                                {group.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name="sizeEstimate"
                          label="Size"
                          rules={[{ required: showAddTaskToProjectInputs, message: 'Please select a size!' }]}
                        >
                          {/* <Select style={{ width: '100%' }}>
                            {Object.values(Size).map((size) => (
                              <Option key={size} value={size}>
                                {size}
                              </Option>
                            ))}
                          </Select> */}
                          <Select style={{ width: '100%' }} defaultValue="S">
                            <Option value="XS">XS</Option>
                            <Option value="S">S</Option>
                            <Option value="M">M</Option>
                            <Option value="L">L</Option>
                            <Option value="XL">XL</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  ) 
                : 
                  (
                    <Col span={24}>
                      <Button type="primary" onClick={showProjectInputs} loading={loading} style={{width: '100%', marginBottom: '20px'}}>
                        Add Task to Project
                      </Button>
                    </Col>
                  )
                }
              <Col span={12}>
                <Form.Item
                  name="type"
                  label="Task Type"
                  rules={[{ required: !isDraftMode, message: 'Please select a task type!' }]}
                >
                  <Select onChange={handleTaskTypeChange}>
                    {taskTypes.map((type) => (
                      <Option key={type.name} value={type.name}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="subtype"
                  label="Task Subtype"
                  rules={[{ required: !isDraftMode, message: 'Please select a subtype!' }]}
                >
                  <Select onChange={(value) => handleSubtypeChange(value)} disabled={!selectedTaskType}>
                    {subtypes.map((subtype) => (
                      <Option key={subtype} value={subtype}>
                        {subtype}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="energyImpact"
                  label="How does this task impact your energy?"
                  rules={[{ required: !isDraftMode, message: 'Please select an option!' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    disabled={!selectedTaskType && !subtypes.length}
                  >
                    {Object.values(EnergyImpact).map((impact) => (
                      <Option key={impact} value={impact}>
                        {impact.replace('_', ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Right column for smaller fields */}
          <Col span={8}>
            <Row gutter={[16, 0]}>
            <Col span={24}>
                <Form.Item
                  name="assignee"
                  label="Assignee"
                  rules={[{ required: !isDraftMode, message: 'Please select an assignee!' }]}
                >
                  <Select style={{ width: '100%' }}>
                    {assigneeOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="duration"
                  label="Duration"
                  rules={[{ required: !isDraftMode, message: 'Please select the duration!' }]}
                >
                  <Select style={{ width: '100%' }}>
                    <Option value={15}>15 Mins</Option>
                    <Option value={30}>30 Mins</Option>
                    <Option value={60}>1 Hour</Option>
                    <Option value={120}>2 Hours</Option>
                    <Option value={180}>3 Hours</Option>
                    <Option value={240}>4 Hours</Option>
                    <Option value={360}>6 Hours</Option>
                    <Option value={480}>8 Hours</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="priority"
                  label="Priority"
                  rules={[{ required: !isDraftMode, message: 'Please select the priority!' }]}
                >
                  <Select>
                    {Object.values(Priority).map((priority) => (
                      <Option key={priority} value={priority}>
                        <StarOutlined style={{ color: getColorByPriority(priority) }} /> {priority}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[{ required: true, message: 'Please select the status!' }]}
                >
                  <Select onChange={(value) => handleStatusChange(value)}>
                    {Object.values(TaskStatus).map(
                      (status) =>
                        (selectableTaskStatuses.includes(status) || isDraftMode) && (
                          <Option key={status} value={status}>
                            <FlagOutlined style={{ color: getTaskStatusColor(status) }} /> {taskStatusLabels[status]}
                          </Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {
                // Only show the completion date field if the task is completed
                requireCompletionDate && (
                  <Col span={24}>
                    <Form.Item
                      name="completedAt"
                      label="Completion Date"
                      // Only require the completion date if the task is completed
                      rules={[{ required: requireCompletionDate, message: 'Please select the completion date!' }]}
                    >
                     <DatePicker
                        format="(ddd) MMMM Do, h:mm A"
                        showTime={{ format: 'hh:mm A' }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                )}
              <Col span={24}>
                <Form.Item name="dueDate" label="Due Date">
                  <DatePicker
                    format="(ddd) MMMM Do, h:mm A"
                    showTime={{ format: 'hh:mm A' }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="start" label="Start Time">
                  <DatePicker
                    format="(ddd) MMMM Do, h:mm A"
                    showTime={{ format: 'hh:mm A' }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {isEditMode ? 'Save' : 'Create Task'}
            </Button> 
          </Col>
        </Row>
      </Form>

    </Modal>
  );
};

export default TaskModal;
