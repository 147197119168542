
import { DataStore, Auth } from 'aws-amplify';
import axios from 'axios';
import { getRandomColor } from '../../app/utils'; // Import the getRandomColor function
import type { UserData, Theme, AssistantMessage } from '../../Types';
import type {RootState} from '../index'
import {
  // SourceType,
  Task,
  TaskStatus,
  LinkedAccount,
  ProjectPlan
} from '../../models';
import type { AppDispatch } from '../index';
import { modelToPlainObject } from '../../app/utils';
import calendarDemoData from '../../features/calendar/mocks/calendarEvents.json';

// eo: type error on original code. Check it by uncommenting and commenting out my line:
const isDemoUser = false;
// const isDemoUser = Auth?.user?.attributes?.sub === '73ec5ccf-f055-4a23-9607-79a1f5e10484';

export const SET_USER = 'SET_USER';
export const SET_SELECTED_MENU_ITEM = 'SET_SELECTED_MENU_ITEM';
export const SET_THEME = 'SET_THEME';
export const SET_CHAT_WIDTH = 'SET_CHAT_WIDTH';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const TOGGLE_CHAT = 'TOGGLE_CHAT';
export const SET_CHAT_OPEN = 'SET_CHAT_OPEN';
export const SET_HIGHLIGHTED_TASKS = 'SET_HIGHLIGHTED_TASKS';
export const SET_ACTIVE_PROJECT_PLAN = 'SET_ACTIVE_PROJECT_PLAN';
export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';
export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';
export const SET_TASKS = 'SET_TASKS';
// eo: unused action
// export const FETCH_ASSIGNEE_OPTIONS = 'FETCH_ASSIGNEE_OPTIONS';
export const SET_ASSIGNEE_OPTIONS = 'SET_ASSIGNEE_OPTIONS';
export const SET_PROJECT_NAME_OPTIONS = 'SET_PROJECT_NAME_OPTIONS';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_CALENDARS = 'SET_CALENDARS';
export const TOGGLE_CALENDAR_VISIBILITY = 'TOGGLE_CALENDAR_VISIBILITY';
// eo: unused action updateCalendarColor
// export const UPDATE_CALENDAR_COLOR = 'UPDATE_CALENDAR_COLOR';

export type ActionType = (
  ReturnType<typeof setUser> |
  ReturnType<typeof setSelectedMenuItem> |
  ReturnType<typeof setUser> |
  ReturnType<typeof setTheme> |
  ReturnType<typeof setChatWidth> |
  ReturnType<typeof setMessages> |
  ReturnType<typeof setIsLoading> |
  ReturnType<typeof toggleChat> |
  ReturnType<typeof setChatOpen> |
  ReturnType<typeof fetchTasksRequest> |
  ReturnType<typeof updateTaskRequest> |
  ReturnType<typeof fetchTasksSuccess> |
  ReturnType<typeof fetchTasksFailure> |
  ReturnType<typeof updateTaskFailure> |
  ReturnType<typeof updateTaskSuccess> |
  ReturnType<typeof setTasks> |
  ReturnType<typeof setAssigneeOptions> |
  ReturnType<typeof setProjectNameOptions> |
  ReturnType<typeof setEvents> |
  ReturnType<typeof setCalendars> |
  ReturnType<typeof toggleCalendarVisibility>

)

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
});

interface Action<T, P> {
  type: T;
  payload: P;
}

export const setUser = (user: UserData): Action<'SET_USER',UserData> => ({ type: SET_USER, payload: user });

export const setSelectedMenuItem = (routeName: string): Action<typeof SET_SELECTED_MENU_ITEM, string> => ({
  type: SET_SELECTED_MENU_ITEM,
  payload: routeName,
});

export const setTheme = (theme: string): Action<'SET_THEME', string> => ({ type: SET_THEME, payload: theme });

export const setChatWidth = (width: number): Action<'SET_CHAT_WIDTH', number> => ({
  type: SET_CHAT_WIDTH,
  payload: width,
});

export const setMessages = (messages: AssistantMessage[]): Action<typeof SET_MESSAGES, AssistantMessage[]> => ({
  type: SET_MESSAGES,
  payload: messages,
});

export const setIsLoading = (isLoading: boolean): Action<typeof SET_IS_LOADING, boolean> => ({
  type: SET_IS_LOADING,
  payload: isLoading,
});

export const toggleChat = (): Action<typeof TOGGLE_CHAT, null> => ({
  type: TOGGLE_CHAT,
  payload: null
});

export const setChatOpen = (open: boolean): Action<typeof SET_CHAT_OPEN, boolean> =>({
  type: SET_CHAT_OPEN,
  payload: open,
});


// eo: commenting out setHighlightedTasks/setActiveProjectPlan; they're unused

// export const setHighlightedTasks = (taskIds) => ({
//   type: SET_HIGHLIGHTED_TASKS,
//   payload: taskIds,
// });

// export const setActiveProjectPlan = (projectId) => ({
//   type: SET_ACTIVE_PROJECT_PLAN,
//   payload: projectId,
// });

export const setEvents = (data: CalendarEvent[]): Action<typeof SET_EVENTS, CalendarEvent[]> => ({
  type: SET_EVENTS,
  payload: data,
});

export const fetchTasksRequest = (): Action<typeof FETCH_TASKS_REQUEST, null> => ({ type: FETCH_TASKS_REQUEST, payload: null });
export const fetchTasksSuccess = (tasks: any[]): Action<typeof FETCH_TASKS_SUCCESS, any[]> => ({ type: FETCH_TASKS_SUCCESS, payload: tasks });
export const fetchTasksFailure = (error: any): Action<typeof FETCH_TASKS_FAILURE, any> => ({ type: FETCH_TASKS_FAILURE, payload: error });
export const updateTaskRequest = (task: Task): Action<typeof UPDATE_TASK_REQUEST, Task> => ({ type: UPDATE_TASK_REQUEST, payload: task });
export const updateTaskSuccess = (task: Task): Action<typeof UPDATE_TASK_SUCCESS, Task> => ({ type: UPDATE_TASK_SUCCESS, payload: task });
export const updateTaskFailure = (error: any): Action<typeof UPDATE_TASK_FAILURE, any> => ({ type: UPDATE_TASK_FAILURE, payload: error });

export const fetchTasks = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchTasksRequest());
  try {
    const tasks: Task[] = await DataStore.query(Task, (t) =>
      t.and(t => [
        t.archived.ne(true),  // Exclude archived tasks
        t.and(t => [
          t.status.ne(TaskStatus.DRAFT),  // Exclude draft tasks
          // only show tasks that are assigned to the user or created
          // by the user if the task is not assigned to anyone
          t.or(t => [
            t.assignee.eq(userId), // Tasks assigned to the user
            t.and(t => [  // Tasks created by the user
              t.assignee.eq(null),
              t.userId.eq(userId)
            ])
          ])
        ])
      ])
    );
    // Convert the tasks to plain objects
    const plainTasks = tasks.map(t => modelToPlainObject(t));
    dispatch(fetchTasksSuccess(plainTasks));
  } catch (error) {
    dispatch(fetchTasksFailure(error));
  }
};

export const updateTask = (updatedTask: Task) => async (dispatch: AppDispatch) => {
  dispatch(updateTaskRequest(updatedTask));
  try {
    const originalTask: Task | undefined = await DataStore.query(Task, updatedTask.id);
    if (!originalTask) {
      throw new Error(`Task with id ${updatedTask.id} not found`);
    }
    await DataStore.save(

      // eo: i was getting TS error on id, createdAt, updatedAt. ChatGPT told me that these fields are readOnly by datastore so I'm commenting them out
      Task.copyOf(originalTask, updated => {
        Object.assign(updated, updatedTask);
        // updated.id = originalTask.id;
        updated.userId = originalTask.userId;
        // updated.createdAt = originalTask.createdAt;
        // updated.updatedAt = null; // Set updatedAt to null to create a new revision
      })
    );
    dispatch(updateTaskSuccess(updatedTask));
    dispatch(fetchTasks(updatedTask.userId)); // Fetch tasks after update
  } catch (error) {
    dispatch(updateTaskFailure(error));
  }
};

export const setTasks = (tasks: Task[]): Action<typeof SET_TASKS, Task[]> => ({
  type: SET_TASKS,
  payload: tasks,
});

export const fetchProjectPlanOptions = () => async (dispatch: AppDispatch) => {
  try {
    const projects: ProjectPlan[] = await DataStore.query(ProjectPlan);
    const projectNameIdOptions = projects.map(project => ({
      id:project.id,
      name: project.name,
      feature: project.taskGroups?.map(taskGroup =>({id:taskGroup?.id, name:taskGroup?.name})),
  }));

    dispatch(setProjectNameOptions(projectNameIdOptions));
  } catch (error) {
    console.error('Error fetching assignee options:', error);
  }
};

interface AssigneeOption {
  value: string | undefined;
  label: string;
}

export const setAssigneeOptions = (options: AssigneeOption[]): Action<typeof SET_ASSIGNEE_OPTIONS, AssigneeOption[]> => ({
  type: SET_ASSIGNEE_OPTIONS,
  payload: options,
});

export interface ProjectNameOption { id: string; name: string; feature: string | { id: string | undefined; name: string | undefined; }[] | undefined; }

export const setProjectNameOptions = (options: ProjectNameOption[]): Action<typeof SET_PROJECT_NAME_OPTIONS, ProjectNameOption[]> => ({
  type: SET_PROJECT_NAME_OPTIONS,
  payload: options
});

export const setCalendars = (calendars: Calendar[]): Action<typeof SET_CALENDARS, Calendar[]> => ({
  type: SET_CALENDARS,
  payload: calendars,
});

// Fetch list of calendars
export const fetchCalendars = () => async (dispatch: AppDispatch) => {
  const linkedAccounts = await DataStore.query(LinkedAccount, l => l);
      // const assigneeOptions = linkedAccounts.map(account => ({
      //   value: account.userId,
      //   label: account.userName ,
      //   providerId: account.providerId,
      //   providerType: account.providerType,
      //   id:account.id,
      //   userEmail: account.userEmail

      // }));
  try {

    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    const response = await api.get('/calendar/list', {
      headers: {
        'Authorization': `Bearer ${idToken}`,
      },
    });

    if (response.status === 200) {
      const updatedCalendars: Calendar[] = response.data.message.map((calendar: Omit<Calendar, 'color' | 'visible' | 'provider'>) => ({
        ...calendar,
        color: getRandomColor(), // Function to generate a random color
        visible: true, // Set all calendars visible by default
        provider: 'google' // Assuming all are Google calendars
      }));

      dispatch(setCalendars(updatedCalendars));
    } else {
      throw new Error('Failed to fetch calendars');
    }
  } catch (error) {
    console.error('Failed to fetch calendars:', error);
  }
};

// export const fetchEvents = (timeMin, timeMax) => async (dispatch) => {
//   if(isDemoMode) {
//     const events = calendarDemoData
//     dispatch(setEvents(events));
//     return;
//   }

//   try {
//     const session = await Auth.currentSession();
//     const idToken = session.getIdToken().getJwtToken();

//     const response = await api.get('/calendar/events', {
//       params: { provider: 'google', timeMin, timeMax },
//       headers: {
//         'Authorization': `Bearer ${idToken}`,
//       }
//     });

//     if (response.status === 200) {
//       dispatch(setEvents(response.data.message));
//     } else {
//       throw new Error('Failed to fetch events');
//     }
//   } catch (error) {
//     console.error('Failed to fetch events:', error);
//   }
// };

// const fetchEvents = (timeMin, timeMax) => async (dispatch, getState) => {
//   const { calendars } = getState().app;
//   const allEvents = [];

//   for (const calendar of calendars) {
//     const events = await api.get('/calendar/events', {
//       params: { calendarId: calendar.id, timeMin, timeMax, userId }
//     });
//     allEvents.push(...events.data.map(event => ({
//       ...event,
//       calendarId: calendar.id,
//       backgroundColor: calendar.color,
//     })));
//   }

//   dispatch(setEvents(allEvents));
// };

export interface CalendarEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  description: string;
  location: string;
  meetizngLink?: string;
  calendarId?: string;
  backgroundColor?: string;
}

export interface Calendar {
  events: CalendarEvent[];
  id: string;
  name: string;
  color: string;
  visible: boolean;
  provider: 'google'
}


export const fetchEvents = (timeMin: string, timeMax: string) => async (dispatch: AppDispatch, getState: RootState) => {
  // eo: this is errorring (line below) so i had to add checks (??)
  const { calendars } = getState?.app ?? {calendars: []};
  // console.log('Calendars\n', calendars)

  if (isDemoUser) {
    const {events} = calendarDemoData;
    dispatch(setEvents(events));
    return;
  }

  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    let allEvents: CalendarEvent[] = [];

    for (const calendar of calendars) {
      if (calendar.visible) {
        const response = await api.get('/calendar/events', {
          params: {
            provider: 'google',
            timeMin,
            timeMax,
            calendarId: calendar.id
          },
          headers: {
            'Authorization': `Bearer ${idToken}`,
          }
        });

        if (response.status === 200) {
          const calendarEvents: CalendarEvent[] = response.data.message.map((event: Omit<CalendarEvent, 'calendarId' | 'backgroundColor'>) => ({
            ...event,
            calendarId: calendar.id,
            backgroundColor: calendar.color,
          }));
          allEvents = [...allEvents, ...calendarEvents];
        } else {
          console.error(`Failed to fetch events for calendar ${calendar.id}`);
        }
      }
    }

    dispatch(setEvents(allEvents));
  } catch (error) {
    console.error('Failed to fetch events:', error);
  }
};

// EO: This func is unused. Commenting out
// export const createEvent = (calendarId, event) => async (dispatch) => {
//   try {
//     const session = await Auth.currentSession();
//     const idToken = session.getIdToken().getJwtToken();
//     const response = await api.post('/calendar/events/create', { calendarId, event }, {
//       headers: {
//         'Authorization': `Bearer ${idToken}`,
//       },
//     });

//     if (response.status === 200) {
//       dispatch(fetchEvents(calendarId));
//     } else {
//       throw new Error('Failed to create event');
//     }
//   } catch (error) {
//     console.error('Failed to create event:', error);
//   }
// };


// eo: commenting out updateEvent; it's unused

// Update an event
// export const updateEvent = (calendarId, eventId, event) => async (dispatch) => {
//   try {
//     const session = await Auth.currentSession();
//     const idToken = session.getIdToken().getJwtToken();
//     const response = await api.put('/calendar/events/update', { calendarId, eventId, event }, {
//       headers: {
//         'Authorization': `Bearer ${idToken}`,
//       },
//     });

//     if (response.status === 200) {
//       dispatch(fetchEvents(calendarId));
//     } else {
//       throw new Error('Failed to update event');
//     }
//   } catch (error) {
//     console.error('Failed to update event:', error);
//   }
// };

// eo: commenting out deleteEvent; it's unused

// Delete an event
// export const deleteEvent = (calendarId, eventId) => async (dispatch) => {
//   try {
//     const session = await Auth.currentSession();
//     const idToken = session.getIdToken().getJwtToken();
//     const response = await api.delete('/calendar/events/delete', {
//       data: { calendarId, eventId },
//       headers: {
//         'Authorization': `Bearer ${idToken}`,
//       },
//     });

//     if (response.status === 200) {
//       dispatch(fetchEvents(calendarId));
//     } else {
//       throw new Error('Failed to delete event');
//     }
//   } catch (error) {
//     console.error('Failed to delete event:', error);
//   }
// };

export const toggleCalendarVisibility = (calendarId: string): Action<typeof TOGGLE_CALENDAR_VISIBILITY, string> => ({
  type: TOGGLE_CALENDAR_VISIBILITY,
  payload: calendarId,
});


// eo: commenting out updateCalendarColor/setActiveProjectPlan; they're unused

// export const updateCalendarColor = (calendarId, color) => ({
//   type: UPDATE_CALENDAR_COLOR,
//   payload: { calendarId, color },
// });