import React, { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import { TaskType } from '../../../models';
import { Table, Button, Input, Modal, Form, Popconfirm, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ModifyTaskType = () => {
  const [taskTypes, setTaskTypes] = useState([]);
  const [isTypeModalVisible, setIsTypeModalVisible] = useState(false);
  const [newSubtypes, setNewSubtypes] = useState({});

  useEffect(() => {
    fetchTaskTypes();
  }, []);

  const fetchTaskTypes = async () => {
    const types = await DataStore.query(TaskType);
    setTaskTypes(types);
  };

  const handleAddType = async (values) => {
    if (!values.name) {
      notification.error({
        message: 'Validation Error',
        description: 'Task Type name is required.'
      });
      return;
    }
    try {
      await DataStore.save(new TaskType({ name: values.name, subtypes: ['Other'] }));
      fetchTaskTypes();
      setIsTypeModalVisible(false);
    } catch (error) {
      console.error('Error saving TaskType:', error);
      notification.error({
        message: 'Save Error',
        description: 'An error occurred while saving the task type.'
      });
    }
  };

  const handleAddSubtype = async (taskType) => {
    const newSubtype = newSubtypes[taskType.id];
    if (!newSubtype) {
      notification.error({
        message: 'Validation Error',
        description: 'Subtype name is required.'
      });
      return;
    }
    const subtypes = taskType.subtypes || [];
    if (subtypes.includes(newSubtype)) {
      notification.error({
        message: 'Duplicate Subtype',
        description: 'This subtype already exists.'
      });
      return;
    }
    try {
        // Fetch the latest instance of the TaskType to prevent weird loading issues
        const modelInstance = await DataStore.query(TaskType, taskType.id);
        if (modelInstance) {
          const updatedType = TaskType.copyOf(modelInstance, updated => {
            updated.subtypes = [...new Set([...subtypes, newSubtype])]; 
          });
          await DataStore.save(updatedType);
          fetchTaskTypes();
          setNewSubtypes(prev => ({ ...prev, [taskType.id]: '' })); // Clear input after adding
        }
    } catch (error) {
      console.error('Error saving subtype:', error);
      notification.error({
        message: 'Save Error',
        description: 'An error occurred while saving the subtype.'
      });
    }
  };

  const handleDeleteType = async (type) => {
    try {
      // Fetch the latest instance of the TaskType (important if the task 
      // was just created, as it will be missing _version needed to delete)
      const latestInstance = await DataStore.query(TaskType, type.id);
      if (!latestInstance) {
        notification.error({
          message: 'Delete Error',
          description: 'TaskType not found.'
        });
        return;
      }

      // Delete the TaskType
      await DataStore.delete(latestInstance);
      fetchTaskTypes();
    } catch (error) {
      console.error('Error deleting TaskType:', error);
      notification.error({
        message: 'Delete Error',
        description: 'An error occurred while deleting the task type.'
      });
    }
  };

  const handleDeleteSubtype = async (taskType, subtype) => {
    try {
        // Fetch the latest instance of the TaskType to prevent weird loading issues
      const modelInstance = await DataStore.query(TaskType, taskType.id);
      if (modelInstance) {
        const updatedType = TaskType.copyOf(modelInstance, updated => {
          // Remove the subtype from the subtypes array
          updated.subtypes = [...new Set(updated.subtypes.filter(s => s !== subtype))];
        });
        await DataStore.save(updatedType);
        fetchTaskTypes();
      }
    } catch (error) {
      console.error('Error deleting subtype:', error);
      notification.error({
        message: 'Delete Error',
        description: 'An error occurred while deleting the subtype.'
      });
    }
  };

  const handleInputChange = (taskTypeId, value) => {
    setNewSubtypes(prev => ({ ...prev, [taskTypeId]: value }));
  };

  return (
    <div style={{ padding: '20px' }}>
      <div className="header">
        <h1>Task Type Manager</h1>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Button type="primary" onClick={() => setIsTypeModalVisible(true)}>Add Task Type</Button>
      </div>
      <Table
        dataSource={taskTypes}
        rowKey="id"
        pagination={false}
        columns={[
          {
            title: '',
            key: 'delete',
            width: 50,
            render: (text, record) => (
              <Popconfirm
                title={`Are you sure you want to delete ${record.name}? This will also delete the following subtypes: ${record.subtypes.join(', ')}`}
                onConfirm={() => handleDeleteType(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )
          },
          { title: 'Type', dataIndex: 'name', key: 'name', align: 'left', className: 'type-column' },
          {
            title: 'Subtypes',
            key: 'subtypes',
            align: 'left',
            render: (text, record) => (
              <div className="subtypes-list">
                {record.subtypes.map((subtype, index) => (
                  <div key={index} className="subtype-item">
                    <Popconfirm
                      title={`Are you sure you want to delete the subtype ${subtype}?`}
                      onConfirm={() => handleDeleteSubtype(record, subtype)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="text" danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <span>{subtype}</span>
                  </div>
                ))}
                <div style={{ display: 'flex', marginTop: '8px' }}>
                  <Input
                    value={newSubtypes[record.id] || ''}
                    onChange={(e) => handleInputChange(record.id, e.target.value)}
                    placeholder={`New ${record.name} Subtype`}
                    style={{ marginRight: '8px' }}
                    onPressEnter={() => handleAddSubtype(record)}
                  />
                  <Button type="primary" onClick={() => handleAddSubtype(record)}>
                    +Add
                  </Button>
                </div>
              </div>
            )
          }
        ]}
      />

      <Modal
        title="Add Task Type"
        visible={isTypeModalVisible}
        onCancel={() => setIsTypeModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleAddType}>
          <Form.Item name="name" label="Type Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Add</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ModifyTaskType;
