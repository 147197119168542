import { useEffect, useState } from 'react';
import { Input, Button, Menu } from 'antd';
import { lightenColor, darkenColor } from '../../../app/utils';
import TrackingCategoryMenu from './TrackingCategoryMenu';
import TrackingSubcategoryMenu from './TrackingSubcategoryMenu';
import { max } from 'lodash';

const { TextArea } = Input;

const AddTrackingItem = ({ onAdd, categories, categoryColors }) => {
    const [addItem, setAddItem] = useState({});

    const menuItemStyle = (color) => ({
        backgroundColor: lightenColor(color),
        color: darkenColor(color, 50),
        borderColor: darkenColor(color),
        borderWidth: '1px',
        borderStyle: 'solid',
    });

    useEffect(() => {
        if (categories.length > 0) {
            const firstCategory = categories[0];
            const firstSubcategory = firstCategory.subcategories.length > 0 ? firstCategory.subcategories[0] : '';
            setAddItem({
                name: '',               // required
                categoryId: firstCategory.id,   // required
                subcategory: firstSubcategory,  // required
            });
        }
    }, [categories]);

    const handleAddItem = () => {
        onAdd(addItem);
        setAddItem({...addItem, name: ''});
    };

    return (
        <div style={{ display: 'flex', marginRight: '20px' }}>
            <div style={{ flexGrow: 2 }}>
                <TextArea
                    rows={1}
                    value={addItem.name}
                    onChange={(e) => setAddItem({...addItem, name: e.target.value})}
                    onPressEnter={(e) => { e.preventDefault(); handleAddItem(); }}
                    placeholder="Enter new tracker name"
                />
            </div>
            <div style={{ flexGrow: 1, maxWidth: '150px' }}>
                <TrackingCategoryMenu
                    categories={categories}
                    categoryColors={categoryColors}
                    selectedCategoryId={addItem.categoryId}
                    onSelect={(category) =>
                        setAddItem({
                            ...addItem, 
                            categoryId: category.id, 
                            subcategory: category.subcategories.length > 0 ? category.subcategories[0] : ''
                        })
                    }
                />
            </div>
            <div style={{ flexGrow: 1, maxWidth: '150px' }}>
                <TrackingSubcategoryMenu
                    categories={categories}
                    categoryColors={categoryColors}
                    selectedCategoryId={addItem.categoryId}
                    selectedSubcategory={addItem.subcategory}
                    onSelect={(subcategory) => setAddItem({...addItem, subcategory})}
                />
            </div>
            <Button onClick={handleAddItem} type="primary">Add</Button>
        </div>
    );
};

export default AddTrackingItem;
