import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Button, Row, Col, Input, Typography, Flex, Tag, Dropdown, Menu, Calendar, Avatar, Switch, Alert, Radio} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { presetPalettes} from '@ant-design/colors';
import dayjs from 'dayjs';
import TrackerItem from './components/TrackerItem';
import AddTrackingItem from './components/AddTrackingItem';
import TrackingSearchBar from './components/TrackingSearchBar';
import ModifyTrackingItems from './components/ModifyTrackingItems';
import ModifyTrackingCategories from './components/ModifyTrackingCategories';
import { darkenColor, lightenColor } from '../../app/utils';
import AddTrackingCategory from './components/AddTrackingCategory';
import { useTracking } from './hooks/TrackingContext';

const { Text, Title } = Typography;

const defaultColors = [{
    label: 'Defaults',
    colors: [
        'rgb(168, 248, 168)',  // Lighter Green (Health)
        'rgb(255, 182, 193)',  // Lighter Pink (Relationship)
        'rgb(179, 204, 255)',  // Lighter Blue (Family)
        'rgb(224, 255, 255)',  // Lighter Cyan (Hobby)
        'rgb(255, 255, 153)',  // Lighter Yellow (Next distinct color)
        'rgb(184, 195, 204)',  // Lighter Grey (Next distinct color)
    ],
}];

const sortByOptions = [
    { key: 'name', label: 'Name' },
    { key: 'category', label: 'Category' },
    { key: 'subcategory', label: 'Subcategory' },
    { key: 'progress', label: 'Progress' },
    { key: 'goal', label: 'Goal' },
    { key: 'average', label: 'Average' },
    { key: 'lastUpdated', label: 'Last Logged' }
];

const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const trackingEntries = [
    { trackingItemId: 1, name: 'Meditate', value: randomNumber(0,3)},
    { trackingItemId: 2, name: 'Headache', value: randomNumber(0,1)},
    { trackingItemId: 3, name: 'Sleep', value: randomNumber(5,9)},
    { trackingItemId: 4, name: 'Sleep.REM', value: randomNumber(1,3)},
    { trackingItemId: 5, name: 'Sleep.Deep', value: randomNumber(0,1)},
    { trackingItemId: 6, name: 'Sleep.Light', value: randomNumber(3,6)},
    { trackingItemId: 7, name: 'Date Night', value: randomNumber(0,1)},
    { trackingItemId: 8, name: 'Fight', value: randomNumber(0,1)},
    { trackingItemId: 9, name: 'Call Mom', value: randomNumber(0,1)},
    { trackingItemId: 10, name: 'Dance Class', value: randomNumber(0,1)},
    { trackingItemId: 11, name: 'Play Guitar', value: randomNumber(0,1)},
    { trackingItemId: 12, name: 'Play Piano', value: randomNumber(0,1)},
    { trackingItemId: 13, name: 'Read Book', value: randomNumber(0,1)},
    { trackingItemId: 14, name: 'Uber Shift', value: randomNumber(0,1)},
];

const correlatorLabels = {
    equalsTo: 'Equals',
    greaterThan: 'Greater Than',
    lessThan: 'Less Than'
};
  
// Function to randomly decide whether to render an dailyHabit for a given day
const shouldRenderHabit = () => Math.random() < 0.2; // 20% chance

const randomlyGenerateDailyTrackingEntries = () => {
    return trackingEntries.filter(() => shouldRenderHabit());
};

const sortBy = (array, key) => {
    return array.slice().sort((a, b) => {
        if(key === 'progress') {
            const progressA = getProgress(a.average, a.value);
            const progressB = getProgress(b.average, b.value);
            return progressA - progressB;
        }
        if (key === 'lastUpdated') {
            return new Date(a[key]) - new Date(b[key]);
        }
        if (typeof a[key] === 'string' && typeof b[key] === 'string') {
            return a[key].localeCompare(b[key]);
        }
        return a[key] > b[key] ? 1 : -1;
    }); 
};

const getProgress = (average, value) => {
    if(value === 0) {
        return average * 100;
    }
    return Math.ceil(average / value * 100);
};

const calculateAverages = (entries, items) => {
    const today = dayjs();
    const ninetyDaysAgo = today.subtract(90, 'day');
    const averages = {};

    items.forEach(item => {
        let totalValue = 0;
        let count = 0;

        Object.entries(entries).forEach(([dateStr, dailyEntries]) => {
            const entryDate = dayjs(dateStr);
            if (entryDate.isBetween(ninetyDaysAgo, today, 'day', '[]')) {
                const itemEntry = dailyEntries.find(e => e.trackingItemId === item.id);
                if (itemEntry) {
                    totalValue += itemEntry.value;
                    count++;
                }
            }
        });

        let average = 0;
        if (count > 0) {
            switch (item.goalFrequency) {
                case 'daily':
                    average = totalValue / 90; // Always divide by 90 for daily items
                    break;
                case 'weekly':
                    const weeks = 90 / 7;
                    average = totalValue / weeks;
                    break;
                case 'monthly':
                    const months = 90 / 30;
                    average = totalValue / months;
                    break;
                default:
                    console.error(`Unknown frequency: ${item.goalFrequency}`);
            }
        }

        averages[item.id] = parseFloat(average.toFixed(2));
    });

    return averages;
};

const calculateImprovementDeltas = (entries, items) => {
    const today = dayjs();
    const oneWeekAgo = today.subtract(7, 'day');
    const twoWeeksAgo = today.subtract(14, 'day');
    const deltas = {};

    items.forEach(item => {
        let currentWeekTotal = 0;
        let previousWeekTotal = 0;
        let currentWeekCount = 0;
        let previousWeekCount = 0;

        Object.entries(entries).forEach(([dateStr, dailyEntries]) => {
            const entryDate = dayjs(dateStr);
            const itemEntry = dailyEntries.find(e => e.trackingItemId === item.id);

            if (itemEntry) {
                if (entryDate.isBetween(oneWeekAgo, today, 'day', '[]')) {
                    currentWeekTotal += itemEntry.value;
                    currentWeekCount++;
                } else if (entryDate.isBetween(twoWeeksAgo, oneWeekAgo, 'day', '(]')) {
                    previousWeekTotal += itemEntry.value;
                    previousWeekCount++;
                }
            }
        });

        const currentWeekAverage = currentWeekCount > 0 ? currentWeekTotal / currentWeekCount : 0;
        const previousWeekAverage = previousWeekCount > 0 ? previousWeekTotal / previousWeekCount : 0;

        deltas[item.id] = parseFloat((currentWeekAverage - previousWeekAverage).toFixed(2));
    });

    return deltas;
};

const startDay = dayjs().startOf('month').startOf('week');
const endDay = dayjs().endOf('month').endOf('week');

const TrackerPage = () => {
    const [toggleCardSize, setToggleCardSize] = useState(false);
    const [dailyTrackingEntries, setDailyTrackingEntries] = useState(null);
    const [filteredDailyTrackingEntries, setFilteredDailyTrackingEntries] = useState(null);
    const [filteredTrackingItems, setFilteredTrackingItems] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [sortByKey, setSortByKey] = useState('name');
    const [highlightedTrackingItems, setHighlightedTrackingItems] = useState([]);
    const [habitCorrelator, setHabitCorrelator] = useState('equalsTo');
    const [selectedHabit, setSelectedHabit] = useState('');
    const [selectedHabitType, setSelectedHabitType] = useState('number');
    const [selectedHabitValue, setSelectedHabitValue] = useState(1);
    const [habitCorrelations, setHabitCorrelations] = useState({});
    const [selectedView, setSelectedView] = useState('View');
    const [averages, setAverages] = useState([]);
    const [improvementDeltas, setImprovementDeltas] = useState({})

    const { 
        loading, error, isDemo, setIsDemo,
        trackingItems, addTrackingItem, updateTrackingItem, deleteTrackingItem,
        trackingCategories, addTrackingCategory, updateTrackingCategory, deleteTrackingCategory,
        trackingSubcategories, addTrackingSubcategory, deleteTrackingSubcategory,
        categoryIdToSubcategoryMap, subcategoryToCategoryMap,loadEntries
    } = useTracking();

    // GENERATED DATA
    // const sortedTrackingItems = sortBy(filteredTrackingItems, sortByKey);
    const sortedTrackingItems = useMemo(() => {
        return sortBy(filteredTrackingItems, sortByKey).map(item => ({
            ...item,
            average: averages[item.id] || 0,
            improvementDelta: improvementDeltas[item.id] || 0
        }));
    }, [filteredTrackingItems, sortByKey, averages]);

    const categoryColors = trackingCategories.reduce((acc, category, index) => {
        acc[category.id] = category.color || presetPalettes[Object.keys(presetPalettes)[index % 10]][5];
        return acc;
    }, {});

    const selectedHabitObject = filteredTrackingItems?.find(item => item.name === selectedHabit) || {};
    const trackingItem = (dailyEntryItem) => trackingItems.find(item => item.id === dailyEntryItem.trackingItemId);

    useEffect(() => {
        if (dailyTrackingEntries && Object.keys(dailyTrackingEntries).length > 0) {
            const newAverages = calculateAverages(dailyTrackingEntries, filteredTrackingItems);
            const newImprovementDeltas = calculateImprovementDeltas(dailyTrackingEntries, filteredTrackingItems);
            setAverages(newAverages);
            setImprovementDeltas(newImprovementDeltas);
        }
    }, [dailyTrackingEntries, filteredTrackingItems]);

    

    // Generate tracking entries for each day in the valid range
    useEffect(() => {
        const fetchDailyTaskEntries = async (start, end) => {
            const generatedDailyHabits = await fetchDailyTrackingEntries();
            setAverages(calculateAverages(generatedDailyHabits,sortedTrackingItems));
            setDailyTrackingEntries(generatedDailyHabits);
            setFilteredDailyTrackingEntries(generatedDailyHabits);
        };
        fetchDailyTaskEntries();
    }, [isDemo, updateTrackingItem]);

    useEffect(() => {
        if (!loading) {
            const fetchInitialData = async () => {
                setFilteredTrackingItems(trackingItems);
                setSelectedCategories(trackingCategories.map(category => category.id));
                setSelectedSubcategories(trackingSubcategories);
            };
        
            fetchInitialData();
        }
    }, [loading]);

    // update correlations when selected habit, condition, or value changes
    useEffect(() => {
        if (selectedHabit && selectedHabitValue) {
            const correlations = calculateCorrelations(selectedHabit, habitCorrelator, selectedHabitValue);
            setHabitCorrelations(correlations);
        }
    }, [selectedHabit, habitCorrelator, selectedHabitValue]);

    // Filter habits based on selected categories
    useEffect(() => {
        if (trackingItems && trackingItems.length > 0) {
            filterTrackingItemsByCategoryAndSubcategory();
        }

        if (dailyTrackingEntries && Object.keys(dailyTrackingEntries).length > 0) {
            filterDailyTrackingEntriesByCategory();
        }
    }, [selectedCategories, selectedSubcategories, dailyTrackingEntries]);
    
    const fetchDailyTrackingEntries = useCallback(async () => {
        let generatedDailyTrackingEntries = {};
        
        if(isDemo) {
            const start = dayjs().startOf('month').startOf('week');
            const end = dayjs().endOf('month').endOf('week');
            let current = start;
            
        
            while (current.isBefore(end) || current.isSame(end, 'day')) {
                generatedDailyTrackingEntries[current.format('YYYY-MM-DD')] = randomlyGenerateDailyTrackingEntries();
                current = current.add(1, 'day');
            }
        } else{
            const today = new Date().toISOString();
            const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
            generatedDailyTrackingEntries = await loadEntries(lastMonth, today);
        }

        return generatedDailyTrackingEntries;
    }, [isDemo]);

    const filterDailyTrackingEntriesByCategory = useCallback(() => {
        const filteredDailyEntries = Object.keys(dailyTrackingEntries).reduce((acc, date) => {
            acc[date] = dailyTrackingEntries[date].filter(dailyEntry => 
                selectedCategories.includes(trackingItem(dailyEntry)?.categoryId) && 
                selectedSubcategories.includes(trackingItem(dailyEntry)?.subcategory));
            return acc;
        }, {});
        setFilteredDailyTrackingEntries(filteredDailyEntries);
    }, [dailyTrackingEntries, selectedCategories, selectedSubcategories, trackingItem]);

    const filterTrackingItemsByCategoryAndSubcategory = useCallback(() => {
        const filteredItems = trackingItems.filter(item =>
            selectedCategories.includes(item.categoryId) && selectedSubcategories.includes(item.subcategory));
        setFilteredTrackingItems(filteredItems);
    }, [trackingItems, selectedCategories, selectedSubcategories]);

    const filterTrackingItemsBySearchTerm = (searchTerm) => {
        const filteredItems = trackingItems
            .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredTrackingItems(filteredItems);
        
        const filteredDailyEntries = Object.keys(dailyTrackingEntries).reduce((acc, date) => {
            acc[date] = dailyTrackingEntries[date].filter(dailyEntry => 
                trackingItem(dailyEntry)?.name?.toLowerCase().includes(searchTerm.toLowerCase()));
            return acc;
        }, {});

        setFilteredDailyTrackingEntries(filteredDailyEntries);
    };

    const getColorForSubcategory = (subcategory) => {
        const category = subcategoryToCategoryMap[subcategory];
        return categoryColors[category.id];
    };

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const handleChangeSelectedCategories = (categoryId) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
            setSelectedSubcategories(selectedSubcategories.filter(
                name => !categoryIdToSubcategoryMap[categoryId]?.includes(name)));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
            setSelectedSubcategories([...selectedSubcategories, ...categoryIdToSubcategoryMap[categoryId]]);
        }
    };

    const handleChangeSelectedSubcategories = (subcategory) => {
        const category = subcategoryToCategoryMap[subcategory];
        if(selectedSubcategories.includes(subcategory)) {
            // Remove corresponding category from selected categories if its the last selected subcategory in that category
            const subcategoriesInCategory = selectedSubcategories.filter(item => category?.subcategories?.includes(item));
            if(subcategoriesInCategory.length === 1) {
                setSelectedCategories(selectedCategories.filter(id => id !== category.id));
            }
            setSelectedSubcategories(selectedSubcategories.filter(id => id !== subcategory));
        } else {
            // Add corresponding category to selected categories if its the first selected subcategory in that category
            setSelectedCategories([...selectedCategories, category.id]);
            setSelectedSubcategories([...selectedSubcategories, subcategory]);
        }
    };

    const handleSelectHabit = (habit) => {
        setSelectedHabit(habit.name);
        setSelectedHabitType(habit.type || 'boolean');
    };

    const handleChangeHighlightedItems = (name) => {
        if (highlightedTrackingItems.includes(name)) {
            setHighlightedTrackingItems(highlightedTrackingItems.filter(item => item !== name));
        } else {
            setHighlightedTrackingItems([...highlightedTrackingItems, name]);
        }
    };

    const renderCalendarDay = useCallback((value) => {
        const date = value.format('YYYY-MM-DD');
        const dailyEntry = (filteredDailyTrackingEntries && filteredDailyTrackingEntries[date]) ? filteredDailyTrackingEntries[date] : [];
        return (
          <Avatar.Group maxCount={4} >
            {dailyEntry.map((dailyEntryItem, index) => {
                const item = trackingItem(dailyEntryItem);
                return item && (
                    <Avatar key={index} 
                        style={{
                            backgroundColor:  highlightedTrackingItems.includes(item.id) ? 'yellow' : lightenColor(categoryColors[item.categoryId], 5),
                            border: '1px solid grey',
                            borderColor: darkenColor(categoryColors[item.categoryId], 5),
                            color: darkenColor(categoryColors[item.categoryId]),
                        }}>
                        {item?.subcategory?.charAt(0).toUpperCase() || 'X'}
                    </Avatar>
            )})}
          </Avatar.Group>
        );
    }, [filteredDailyTrackingEntries, trackingItem, highlightedTrackingItems, categoryColors]);

    const dropdownMenu = (
        <Menu value={sortByKey} onClick={(e) => setSortByKey(e.key)}>
            {sortByOptions.map(option => (
                <Menu.Item key={option.key} onClick={() => setSortByKey(option.key)}>
                    {option.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    const listTrackingItemsHeader = (title) => (
        <div>
            <Row>
                <Title level={2}>{title}</Title> 
            </Row>
            <Row gutter={16} style={{marginBottom: 16, alignItems: 'center'}}>
                <Col span={6}>
                    <Text strong>Sort By:</Text>
                </Col>
                <Col span={12}>
                    <Dropdown.Button
                        icon={<DownOutlined />}
                        overlay={dropdownMenu}
                        onClick={() => {}}
                    >
                        {sortByOptions.find(option => option.key === sortByKey).label}
                    </Dropdown.Button>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight: '20px' }}>
                    <Text strong>Shrink</Text>
                    <Switch onChange={() => setToggleCardSize(!toggleCardSize)} />
                </Col>
            </Row>
        </div>
    );

    const listTrackingItemsComponent = (title) => (
        <div>
            {listTrackingItemsHeader(title)}
                <div>
                    {sortedTrackingItems.map(item => (
                        selectedCategories.includes(item.categoryId) &&
                        selectedSubcategories.includes(item.subcategory) &&
                        <TrackerItem 
                            key={item.id}
                            item={item} 
                            itemCategory={trackingCategories.find(category => category.id === item.categoryId)}
                            categoryColors={categoryColors} 
                            progressPercentage={selectedView === 'Correlate' ? habitCorrelations[item.name]?.percentage || Math.random(100).toFixed(0) : getProgress(item.average || Math.random(item.goal || 1).toFixed(1), item.goal || 1)}
                            // progressPercentage={selectedView === 'Correlate' 
                            //     ? habitCorrelations[item.name]?.percentage || 0 
                            //     : progressPercentage}//calculateProgressPercentage(item)}
                            viewType={selectedView === 'Correlate' ? 'correlation' : 'goal'}
                            highlight={highlightedTrackingItems.includes(item.id)}
                            oneLine={toggleCardSize}
                            onClick={handleChangeHighlightedItems.bind(this, item.id)}
                            includeImprovementPercentage={selectedView !== 'Correlate'}
                            isEdit={selectedView === 'Modify'}
                            onDelete={deleteTrackingItem}
                        />
                    ))}
                </div>
        </div>
    );

    const categoryFilterComponent = (
        <div>
            <Flex gap={4} wrap align="center">
                <Title level={5}>Filter by Category:</Title>
                {trackingCategories.map((category) => (
                    <Tag
                        key={category.id}
                        onClick={handleChangeSelectedCategories.bind(this, category.id)}
                        color={selectedCategories.includes(category.id) ? lightenColor(categoryColors[category.id], 12) : 'default'}
                        style={{ 
                            cursor: 'pointer', 
                            border: '1px solid grey', 
                            borderColor: selectedCategories.includes(category.id) ? darkenColor(categoryColors[category.id]) : 'grey',
                            color: selectedCategories.includes(category.id) ? darkenColor(categoryColors[category.id], 50): 'grey',
                        }}
                    >
                        {category.name}
                    </Tag>
                ))}
            </Flex>
            <Flex gap={4} wrap align="center">
                <Title level={5}>Filter by Subcategory:</Title>
                {trackingSubcategories.map((subcategory) => (
                    <Tag
                        key={subcategory}
                        onClick={handleChangeSelectedSubcategories.bind(this, subcategory)}
                        color={selectedSubcategories.includes(subcategory) ? lightenColor(getColorForSubcategory(subcategory), 12) : 'default'}
                        style={{ 
                            cursor: 'pointer', 
                            border: '1px solid grey', 
                            borderColor: selectedSubcategories.includes(subcategory) ? darkenColor(getColorForSubcategory(subcategory)): 'grey',
                            color: selectedSubcategories.includes(subcategory) ? darkenColor(getColorForSubcategory(subcategory), 50): 'grey',
                        }}
                    >
                        {subcategory}
                    </Tag>
                ))}
            </Flex>
        </div>
    );

    const calendarComponent = (
        <div>
            <Calendar 
                onPanelChange={onPanelChange} 
                cellRender={renderCalendarDay} 
                validRange={[startDay, endDay]}
            />
        </div>
    );

    const habitSelectorMenu = useMemo(() => (
        <Menu value={selectedHabit} >
            {sortedTrackingItems.map(item => (
                <Menu.Item key={item.id} onClick={() => handleSelectHabit(item)}>
                    {item.name}
                </Menu.Item>
            ))}
        </Menu>
    ), [filteredTrackingItems, selectedHabit]);

    // dropdown to select a habit to view insights
    const habitSelectorComponent = (
        <div>
            <Dropdown overlay={habitSelectorMenu} trigger={['click']}>
                <Button>
                {selectedHabit || 'Select Habit'} <DownOutlined />
                </Button>
            </Dropdown>
        </div>
    );

    // sets if looking for when a habit is 'equalsTo', 'greaterThan', 'lessThan', etc. a given value
    const habitCorrelatorMenu = (
        <Menu>
            <Menu.Item key="equalsTo" onClick={() => setHabitCorrelator('equalsTo')}>Equals To</Menu.Item>
            <Menu.Item key="greaterThan" onClick={() => setHabitCorrelator('greaterThan')}>Greater Than</Menu.Item>
            <Menu.Item key="lessThan" onClick={() => setHabitCorrelator('lessThan')}>Less Than</Menu.Item>
        </Menu>
    );

    const habitCorrelatorComponent = (
        <div>
            <Dropdown overlay={habitCorrelatorMenu} trigger={['click']}>
                <Button>
                    {correlatorLabels[habitCorrelator]} <DownOutlined />
                </Button>
            </Dropdown>
        </div>
    );

    // Render the input field based on the selected habit's type
     const renderHabitCorrelatorInput = useCallback(() => {
        if (selectedHabitType === 'number' && selectedHabitObject.goalFrequency === 'daily') {
            return (
                <>
                    <Input
                        type="number"
                        value={selectedHabitValue}
                        onChange={(e) => setSelectedHabitValue(e.target.value)}
                        style={{ width: '60px'}}
                    />
                    <Tag style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
                        {selectedHabitObject.unit} / day
                    </Tag>
                </>
            );
        } else if (selectedHabitType === 'string') {
            return <Input placeholder="Enter value" />;
        }
        return (
            <>
                <Tag.CheckableTag 
                    checked={selectedHabitValue === 1}
                    onChange={setSelectedHabitValue.bind(this, 1)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
                    Occurred
                </Tag.CheckableTag>
                <Tag.CheckableTag 
                    checked={selectedHabitValue === 0}
                    onChange={setSelectedHabitValue.bind(this, 0)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px', marginLeft: 0 }}>
                    Did Not Occur
                </Tag.CheckableTag>
            </>
        );
    }, [selectedHabitType, selectedHabitObject, selectedHabitValue]);

    const renderHabitCorrelatorComponents = () => {
        if (!selectedHabit) return null;
        const shouldRenderCorrelatorDropdown = selectedHabitType === 'number' && selectedHabitObject.goalFrequency === 'daily';
        return (
            <>
                {shouldRenderCorrelatorDropdown && habitCorrelatorComponent}
                {renderHabitCorrelatorInput()}
            </>
        );
    };

    const calculateCorrelations = useCallback((selectedHabit, condition, value) => {
        let habitDays = Object.keys(filteredDailyTrackingEntries).filter(date => {
            let habit = filteredDailyTrackingEntries[date].find(h => h.name === selectedHabit);
            if (!habit) return false;

            if (condition === 'equalsTo') return habit.value === value;
            if (condition === 'greaterThan') return habit.value > value;
            if (condition === 'lessThan') return habit.value < value;
            return false;
        });
      
        let correlations = {};
        
        habitDays.forEach(date => {
            filteredDailyTrackingEntries[date].forEach(habit => {
                if (!correlations[habit.name]) {
                    correlations[habit.name] = { count: 0 };
                }
                correlations[habit.name].count += 1;
            });
        });
      
        // Calculate the percentage of correlation
        Object.keys(correlations).forEach(habitName => {
            correlations[habitName].percentage = Math.round(correlations[habitName].count / habitDays.length * 100);
        });
      
        console.log(`Correlations for ${selectedHabit} with ${condition} ${value}:`, correlations);
        return correlations;
    }, [filteredDailyTrackingEntries]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden'}}>
            {/* Demo mode banner */}
            <Alert
                type="info"
                message={
                <>
                    <Switch checked={isDemo} onChange={setIsDemo} />
                    <span style={{marginLeft: '16px'}}>
                        <Text strong>DEMO MODE: </Text>
                        Feel free to try our new upcoming features!{' '}
                        <i>Note: changes will not save during the demo.</i>
                    </span>
                </>
                }
                style={{ marginBottom: '20px', marginRight: '20px', borderRadius: '5px' }}
            />

            {/* Page Title & View Switcher */}
            <Title level={1} style={{ display: 'flex', justifyContent: 'space-between', marginRight: '20px' }}>
                Tracking Dashboard
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '20px' }}>
                    <Radio.Group value={selectedView} onChange={(e) => setSelectedView(e.target.value)}>
                        <Radio.Button value="Modify">Modify</Radio.Button>
                        <Radio.Button value="View">View</Radio.Button>
                        <Radio.Button value="Correlate">Correlate</Radio.Button>
                    </Radio.Group>
                </div>
            </Title>

            {/* Search & Add Tracking Items */}
            <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col span={8}>
                    <TrackingSearchBar onSearch={filterTrackingItemsBySearchTerm} />
                </Col>
                <Col span={16}>
                    <AddTrackingItem
                        onAdd={addTrackingItem}
                        categories={trackingCategories}
                        categoryColors={categoryColors}
                    />
                </Col>
            </Row>

            {/* Conditional Rendering Based on toggleView */}
            <div style={{ display: 'flex', flexDirection: 'row', height: '97%', gap: '40px', marginRight: '20px' }}>
                    <>
                        {/* List Tracking Items */}
                        <div style={{ minWidth: '380px', maxWidth: '25%', flex: '1 1 auto', overflowY: 'auto', maxHeight: 'calc(100% - 160px)'}}>
                            {selectedView === 'Correlate' ? 
                                listTrackingItemsComponent('Correlations') 
                                : selectedView === 'Modify' ?
                                listTrackingItemsComponent('Modify Tracking Items')
                                :
                                listTrackingItemsComponent('My Tracking Items')}
                        </div>

                        {/* Filters & Calendar View */}
                        <div style={{ flex: '1 1 auto', overflowY: 'auto', maxHeight: 'calc(100% - 160px)' }}>
                            {selectedView === 'Correlate' ? (
                                <>
                                    <Title level={4}>Show Me Correlations For...</Title>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', marginBottom: '20px' }}>
                                        {habitSelectorComponent} 
                                        {renderHabitCorrelatorComponents()}
                                    </div>
                                    {calendarComponent}
                                </>
                            ) : selectedView === 'Modify' ? (
                                <>
                                    <div style={{ marginBottom: '20px', position: 'sticky', top: 0, zIndex: 10, background: 'white' }}>
                                        {categoryFilterComponent}
                                    </div>
                                    <div style={{ display: 'flex', height: '68vh', overflow: 'hidden' }}>
                                        {/* Left Column - Fixed */}
                                        <div
                                            style={{
                                                width: '50%',
                                                position: 'sticky',
                                                top: '0px', // Adjust this to align with your header height
                                                height: '65vh', // Ensure it fits the remaining viewport space
                                                overflow: 'hidden', // Prevent scrolling within the left column
                                                paddingRight: '16px',
                                                boxSizing: 'border-box',
                                                zIndex: 5, // Ensure it stays above scrolling content
                                            }}
                                        >
                                            <ModifyTrackingItems 
                                                loading={loading}
                                                trackingItems={filteredTrackingItems} 
                                                categories={trackingCategories}
                                                categoryColors={categoryColors}
                                                highlightedTrackingItems={highlightedTrackingItems}
                                                setHighlightedTrackingItems={setHighlightedTrackingItems}
                                                onEdit={updateTrackingItem}
                                            />
                                        </div>

                                        {/* Right Column - Scrollable Content Below Header */}
                                        <div style={{ width: '50%', paddingLeft: '16px', boxSizing: 'border-box' }}>
                                            {/* The header remains fixed, only content below it scrolls */}
                                            <Title level={4}>Modify Categories and Subcategories</Title>
                                            <AddTrackingCategory
                                                presets={defaultColors}
                                                onAdd={addTrackingCategory}
                                            />
                                            <div
                                                style={{
                                                    height: '65vh', // Adjust based on header/filter height
                                                    overflowY: 'auto', // Enables scrolling below the header
                                                    padding: '8px 0',
                                                }}
                                            >
                                                <ModifyTrackingCategories 
                                                    categories={trackingCategories}
                                                    onEdit={updateTrackingCategory}
                                                    onDelete={deleteTrackingCategory}
                                                    onAddSubcategory={addTrackingSubcategory}
                                                    onDeleteSubcategory={deleteTrackingSubcategory}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {categoryFilterComponent}
                                    {calendarComponent}
                                </>
                            )}
                        </div>
                    </>
            </div>
        </div>
    );
}

export default TrackerPage;