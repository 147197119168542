// demoTaskData.ts
import dayjs from 'dayjs';
import { TaskStatus, Priority } from '../../../models';

const demoTaskData = [
  // Sunday 4/28
  {
    id: 'task-1',
    userId: 'alex-taylor',
    title: 'Prepare Sales Strategy Presentation',
    description: 'Prepare the presentation for the sales strategy meeting on Monday.',
    status: TaskStatus.COMPLETED,
    dueDate: dayjs('2024-04-28T17:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'prep notes',
    start: dayjs('2024-04-28T16:00:00-07:00').toISOString(),
    end: dayjs('2024-04-28T17:00:00-07:00').toISOString(),
    completed: true,
    energyDelta: -10,
  },
  // Monday 4/29
  {
    id: 'task-2',
    userId: 'alex-taylor',
    title: 'Update CRM with New Leads',
    description: 'Enter new leads from last week into the CRM system.',
    status: TaskStatus.COMPLETED,
    dueDate: dayjs('2024-04-29T09:30:00-07:00').toISOString(),
    priority: Priority.Medium,
    duration: 30,
    type: 'Admin',
    subtype: 'update CRM',
    start: dayjs('2024-04-29T09:00:00-07:00').toISOString(),
    end: dayjs('2024-04-29T09:30:00-07:00').toISOString(),
    completed: true,
    energyDelta: -5,
  },
  {
    id: 'task-3',
    userId: 'alex-taylor',
    title: 'Prospect New Clients - Research',
    description: 'Research potential new clients.',
    status: TaskStatus.IN_PROGRESS,
    dueDate: dayjs('2024-04-29T11:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'prospecting',
    start: dayjs('2024-04-29T10:00:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -10,
  },
  {
    id: 'task-4',
    userId: 'alex-taylor',
    title: 'Send Follow-up to ABC Corp with Meeting Notes',
    description: 'Send follow-up email to ABC Corp with notes from the meeting.',
    status: TaskStatus.COMPLETED,
    dueDate: dayjs('2024-04-29T12:00:00-07:00').toISOString(),
    priority: Priority.Medium,
    duration: 30,
    type: 'Admin',
    subtype: 'emails',
    start: dayjs('2024-04-29T11:30:00-07:00').toISOString(),
    end: dayjs('2024-04-29T12:00:00-07:00').toISOString(),
    completed: true,
    energyDelta: -5,
  },
  {
    id: 'task-5',
    userId: 'alex-taylor',
    title: 'Prepare Notes for Client Meeting with DEF Ltd',
    description: 'Prepare notes for the client meeting with DEF Ltd.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-04-29T14:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'prep notes',
    start: dayjs('2024-04-29T13:00:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -10,
  },
  {
    id: 'task-6',
    userId: 'alex-taylor',
    title: 'Prospect New Clients - Outreach',
    description: 'Reach out to potential new clients identified during research.',
    status: TaskStatus.IN_PROGRESS,
    dueDate: dayjs('2024-04-29T16:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'prospecting',
    start: dayjs('2024-04-29T15:00:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -10,
  },
  {
    id: 'task-7',
    userId: 'alex-taylor',
    title: 'Prepare Product Demo for XYZ Inc',
    description: 'Prepare the product demo materials and presentation for XYZ Inc.',
    status: TaskStatus.IN_PROGRESS,
    dueDate: dayjs('2024-04-30T10:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 90,
    type: 'Admin',
    subtype: 'prep notes',
    start: dayjs('2024-04-29T16:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -15,
  },
  // Tuesday 4/30
  {
    id: 'task-8',
    userId: 'alex-taylor',
    title: 'Send Follow-up to DEF Ltd with Meeting Notes',
    description: 'Send follow-up email to DEF Ltd with notes from the meeting.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-04-30T11:00:00-07:00').toISOString(),
    priority: Priority.Medium,
    duration: 30,
    type: 'Admin',
    subtype: 'emails',
    start: dayjs('2024-04-30T10:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -5,
  },
  {
    id: 'task-9',
    userId: 'alex-taylor',
    title: 'Prospect New Clients - Outreach',
    description: 'Reach out to potential new clients identified during research.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-04-30T14:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'prospecting',
    start: dayjs('2024-04-30T13:00:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -10,
  },
  {
    id: 'task-10',
    userId: 'alex-taylor',
    title: 'Follow-up Call with ABC Corp',
    description: 'Call ABC Corp to discuss next steps.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-04-30T16:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 30,
    type: 'Admin',
    subtype: 'calls',
    start: dayjs('2024-04-30T15:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -5,
  },
  {
    id: 'task-11',
    userId: 'alex-taylor',
    title: 'Send Follow-up to GHI Enterprises with Meeting Notes',
    description: 'Send follow-up email to GHI Enterprises with notes from the meeting.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-04-30T17:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 30,
    type: 'Admin',
    subtype: 'emails',
    start: dayjs('2024-04-30T16:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -5,
  },
  // Wednesday 5/1
  {
    id: 'task-12',
    userId: 'alex-taylor',
    title: 'Prepare for Product Demo with XYZ Inc',
    description: 'Final preparations for the product demo with XYZ Inc.',
    status: TaskStatus.IN_PROGRESS,
    dueDate: dayjs('2024-05-01T09:30:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'prep notes',
    start: dayjs('2024-05-01T08:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -10,
  },
  {
    id: 'task-13',
    userId: 'alex-taylor',
    title: 'Send Follow-up to XYZ Inc with Meeting Notes',
    description: 'Send follow-up email to XYZ Inc with notes from the meeting.',
    status: TaskStatus.IN_PROGRESS,
    dueDate: dayjs('2024-05-01T11:30:00-07:00').toISOString(),
    priority: Priority.Medium,
    duration: 30,
    type: 'Admin',
    subtype: 'emails',
    start: dayjs('2024-05-01T11:00:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -5,
  },
  {
    id: 'task-14',
    userId: 'alex-taylor',
    title: 'Sales Prospecting - Research',
    description: 'Research potential new clients.',
    status: TaskStatus.IN_PROGRESS,
    dueDate: dayjs('2024-05-01T12:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'prospecting',
    start: dayjs('2024-05-01T11:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -10,
  },
  {
    id: 'task-15',
    userId: 'alex-taylor',
    title: 'Follow-up Call with DEF Ltd',
    description: 'Call DEF Ltd to discuss next steps.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-05-01T14:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 30,
    type: 'Admin',
    subtype: 'calls',
    start: dayjs('2024-05-01T13:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -5,
  },
  {
    id: 'task-16',
    userId: 'alex-taylor',
    title: 'Prepare Quarterly Sales Report',
    description: 'Compile and prepare the quarterly sales report.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-05-01T17:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 90,
    type: 'Admin',
    subtype: 'reporting',
    start: dayjs('2024-05-01T15:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -15,
  },
  // Thursday 5/2
  {
    id: 'task-17',
    userId: 'alex-taylor',
    title: 'Follow-up Call with ABC Corp',
    description: 'Call ABC Corp to discuss next steps.',
    status: TaskStatus.PENDING_APPROVAL,
    dueDate: dayjs('2024-05-02T14:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 30,
    type: 'Admin',
    subtype: 'calls',
    start: dayjs('2024-05-02T13:30:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -5,
  },
  {
    id: 'task-18',
    userId: 'alex-taylor',
    title: 'Complete Sales Strategy Document',
    description: 'Finalize the sales strategy document for the team.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-05-02T17:00:00-07:00').toISOString(),
    priority: Priority.High,
    duration: 60,
    type: 'Admin',
    subtype: 'document',
    start: dayjs('2024-05-02T16:00:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -10,
  },
  {
    id: 'task-19',
    userId: 'alex-taylor',
    title: 'Send Follow-up to PQR Ltd with Meeting Notes',
    description: 'Send follow-up email to PQR Ltd with notes from the meeting.',
    status: TaskStatus.NOT_STARTED,
    dueDate: dayjs('2024-05-02T17:30:00-07:00').toISOString(),
    priority: Priority.Medium,
    duration: 30,
    type: 'Admin',
    subtype: 'emails',
    start: dayjs('2024-05-02T17:00:00-07:00').toISOString(),
    end: undefined,
    completed: false,
    energyDelta: -5,
  },
];

export default demoTaskData;
