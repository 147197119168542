import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectNameOptions, setAssigneeOptions, setUser, fetchProjectPlanOptions } from './store/actions';
import { Authenticator } from '@aws-amplify/ui-react';

import routes from './app/routes';
import MainLayout from './app/layouts/MainLayout';
import AuthComplete from './app/account/components/AuthComplete';
import { TrackingProvider } from './features/tracker/hooks/TrackingContext';
import { getUsersInGroup } from './app/utils';
import type {UserData} from './Types'
import type { RootState, AppDispatch } from './store';


export default function App() {

  const dispatch: AppDispatch = useDispatch();
  const { theme, isAdmin } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    const fetchData = async () => {
      const user = await checkUser();

      if (user?.isAdmin) {
        const adminUsers = await getUsersInGroup('Admin');
        dispatch(setAssigneeOptions(adminUsers));
        dispatch(fetchProjectPlanOptions() as any);
      } else {
        dispatch(setAssigneeOptions([{
          value: user?.userId,
          label: user?.username,
        }]));
        dispatch(setProjectNameOptions([{
          id: '1',
          name: 'My Project',
          feature: 'My Taskgroup',
        }]));
      }
    }
    fetchData();
  }, [dispatch]);

  async function checkUser(): Promise<any> {
    try {
      const session: CognitoUserSession = await Auth.currentSession();
      const idToken: string = session.getIdToken().getJwtToken();
      const isDemoMode = session.getIdToken().payload.sub === '73ec5ccf-f055-4a23-9607-79a1f5e10484';
      const userData: UserData = {
        userId: session.getIdToken().payload.sub,
        username: session.getIdToken().payload.name,
        email: session.getIdToken().payload.email,
        idToken: idToken,
        isAdmin: session.getIdToken().payload['cognito:groups']?.includes('Admin'),
        isDemoMode: isDemoMode
      };
      dispatch(setUser(userData));
      return userData;
    } catch (error) {
      console.error('Error fetching user', error);
    }
  }

  // Filter routes based on user role
  const validRoutes = routes.filter((route) => !route.admin || (route.admin === isAdmin));

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.primaryColor,
          colorBgBase: theme.backgroundColor,
          colorTextBase: theme.textColor,
        },
      }}
    >
      <Authenticator>
        {/* TODO: move this to redux for longer-term state management option */}
        <TrackingProvider>
        {/* Different routes for the app */}
          <Router>
            <Routes>
              <Route path="/auth-complete" element={<AuthComplete />} />
              {
                validRoutes
                  .map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <MainLayout>
                          {route.component}
                        </MainLayout>
                      }
                    />
              ))}
            </Routes>
          </Router>
        </TrackingProvider>
      </Authenticator>
    </ConfigProvider>
  );
}
