// src/store/reducers/appReducer.js
import { themes } from '../../app/themes/themes';
import type { Calendar, CalendarEvent } from '../actions/index';
import type {Theme} from '../../Types'
import type {Task} from '../../models'

import type {ActionType, ProjectNameOption} from '../actions/index'
// import type {AppDispatch} from '../index'


import {
  SET_CHAT_OPEN,
  SET_USER,
  SET_SELECTED_MENU_ITEM,
  SET_THEME,
  SET_CHAT_WIDTH,
  SET_MESSAGES,
  SET_IS_LOADING,
  TOGGLE_CHAT,
  SET_HIGHLIGHTED_TASKS,
  SET_ACTIVE_PROJECT_PLAN,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  SET_TASKS,
  // FETCH_ASSIGNEE_OPTIONS,
  SET_ASSIGNEE_OPTIONS,
  SET_PROJECT_NAME_OPTIONS,
  SET_EVENTS,
  SET_CALENDARS,
  TOGGLE_CALENDAR_VISIBILITY,
  // UPDATE_CALENDAR_COLOR,
} from '../actions';

interface InitialState {
  data: any[];
  userId: string | null;
  username: string | null;
  userEmail?: string;
  userIdToken: string | null;
  isAdmin: boolean;
  isDemoMode: boolean;
  selectedMenuItem: string;
  theme: Theme;
  themeName: string;
  chatWidth: number;
  chatOpen: boolean;
  messages: any[];
  isLoading: boolean;
  highlightedTasksIds: string[];
  activeProjectPlanId: string | null;
  tasks: Task[];
  taskLoading: boolean;
  taskError: string | null;
  assigneeOptions: any[];
  assigneeOptionsLoading: boolean;
  projectNamesOptions: ProjectNameOption[];
  events: CalendarEvent[];
  calendars: Calendar[];
}

const initialState: InitialState = {
  data: [],
  userId: null,
  username: null,
  userIdToken: null,
  isAdmin: false,
  isDemoMode: false,
  selectedMenuItem: 'Home',
  theme: themes['light'],
  themeName: 'light',
  chatWidth: 0,
  chatOpen: false,
  messages: [],
  isLoading: true,
  highlightedTasksIds: [],
  activeProjectPlanId: null,
  tasks: [],
  taskLoading: false,
  taskError: null,
  assigneeOptions: [],
  assigneeOptionsLoading: false,
  projectNamesOptions: [],
  events: [],
  calendars: [],
};

const appReducer = (state: InitialState = initialState, action: ActionType): InitialState => {
  switch (action.type) {
    case SET_USER:
      return { ...state,
        userId: action.payload?.userId,
        username: action.payload?.username,
        userIdToken: action.payload?.idToken,
        userEmail: action.payload?.email,
        isAdmin: action.payload?.isAdmin,
        isDemoMode: action.payload?.isDemoMode,
      };
    case SET_SELECTED_MENU_ITEM:
      return { ...state, selectedMenuItem: action.payload };
    case SET_THEME:
      return { ...state, theme: themes[action.payload], themeName: action.payload};
    case SET_CHAT_WIDTH:
      return { ...state, chatWidth: action.payload };
    case SET_MESSAGES:
      return { ...state, messages: action.payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case TOGGLE_CHAT:
      return { ...state, chatOpen: !state.chatOpen };
      // eo: setHighlightedTasks + setActiveProjectPlan are unused in codebase; un-comment if otherwise

    // case SET_HIGHLIGHTED_TASKS:
    //   return { ...state, highlightedTasksIds: action.payload };
    // case SET_ACTIVE_PROJECT_PLAN:
    //   return { ...state, activeProjectPlanId: action.payload };
    case SET_CHAT_OPEN:
      return {
       ...state,
        chatOpen: action.payload,
      };
    case FETCH_TASKS_REQUEST:
    case UPDATE_TASK_REQUEST:
      return { ...state, taskLoading: true, taskError: null };
    case FETCH_TASKS_SUCCESS:
      return { ...state, taskLoading: false, tasks: action.payload };
    case FETCH_TASKS_FAILURE:
    case UPDATE_TASK_FAILURE:
      return { ...state, taskLoading: false, taskError: action.payload };
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        taskLoading: false,
        tasks: state.tasks.map((task) =>
          task.id === action.payload.id ? action.payload : task
        ),
      };
    case SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    // eo: unused action
    // case FETCH_ASSIGNEE_OPTIONS:
    //   return {
    //    ...state,
    //     assigneeOptionsLoading: true,
    //   };
    case SET_ASSIGNEE_OPTIONS:
      return {
       ...state,
        assigneeOptions: action.payload,
        assigneeOptionsLoading: false,
    };

    case SET_PROJECT_NAME_OPTIONS:
      return {
       ...state,
        projectNamesOptions: action.payload,
      };
    case SET_EVENTS:
      return { ...state, events: action.payload };

    case SET_CALENDARS:
      return {...state, calendars: action.payload };

    case TOGGLE_CALENDAR_VISIBILITY:
      return {
      ...state,
      calendars: state.calendars.map(calendar =>
        calendar.id === action.payload
          ? { ...calendar, visible: !calendar.visible }
          : calendar
      ),
    };

    // eo: updateCalendarColor is unused
    // case UPDATE_CALENDAR_COLOR:
    //   return {
    //     calendars: state.calendars.map(calendar =>
    //       calendar.id === action.payload.calendarId
    //        ? {...calendar, color: action.payload.color }
    //         : calendar
    //     ),
    //   };
      // Add more cases as needed...
    default:
      return state;
  }
};

export default appReducer;
