import { Affix, Button, Card, Col, Empty, Flex, Progress, Row, Statistic, Tag, Tooltip } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Typography } from 'antd';
import { darkenColor, lightenColor } from "../../../app/utils";
import './TrackerItem.css';

const { Title } = Typography;

const TrackerItem = ({ loading, item, itemCategory, categoryColors, progressPercentage, onClick = () => {}, isEdit = false, 
    onDelete = () => {}, includeImprovementPercentage = false, viewType='goal', highlight = false, oneLine = false 
}) => {    

    // human-friendly format: (Mon) Sep 13, 2021
    const formatDateString = (date) => {
        return new Date(date).toLocaleDateString('en-US', 
            { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
    };

    const getProgressColor = () => {
        if (item.value === 0) {
            return '#f56c6c';
        }

        if (progressPercentage <= 30) {
            return '#f56c6c';
        } else if (progressPercentage < 60 ) {
            return '#e6a23c';
        } else if (progressPercentage < 80) {
            // blue
            return '#409eff';
        } else {
            return '#67c23a';
        }
    };

    const lastLoggedFooter = (
        <p style={{ fontSize: 12 }}>
            <i><b>Last Logged:</b> {formatDateString(item.lastUpdated || item.lastLogged)}</i>
        </p>
    );

    const correlationTag = (correlation) => {
        if (correlation > 70) {
            return <Tag color="green">Strong</Tag>;
        } else if (correlation > 30) {
            return <Tag color="blue">Moderate</Tag>;
        }
        return <Tag color="orange">Weak</Tag>;
    }

    const variationTag = (variation) => {
        if (variation > 80) {
            return <Tag color="red">Significant</Tag>;
        } else if (variation > 50) {
            return <Tag color="orange">Moderate</Tag>;
        } else if (variation > 20) {
            return <Tag color="blue">Minor</Tag>;
        }
        return <Tag color="green">Stable</Tag>;
    }

    const shortenedFrequency = (frequency) => {
        switch(frequency) {
            case 'daily':
                return 'day';
            case 'weekly':
                return 'wk';
            case 'monthly':
                return 'mo';
            default:
                return '';
        }
    };

    const renderNormalContent = () => {
        switch(viewType) {
            case 'correlation':
                return (
                    <>
                        <p style={{ padding: 0, margin: 0 }}>
                            <b>Correlation:</b> {correlationTag(progressPercentage) || 'Strong'}
                        </p>
                        <p>
                            <b>Variation:</b> {variationTag(item.improvementDelta)|| 'Stable'}
                        </p>
                        {lastLoggedFooter}
                    </>
                );
            default:
                return (
                    <>
                        <p style={{ padding: 0, margin: 0 }}>
                            <b>Goal:</b> 
                            {item.goal ? <span>{item.goal} {item.goalUnit} / {shortenedFrequency(item.goalFrequency)}</span> : 'Not Set'}
                        </p>
                        <p>
                            <b>Average:</b><span>{item.average 
                                ? item.average 
                                : (Math.random() * (item.goal||1)).toFixed(1)} {item.goalUnit || 'times'} / {shortenedFrequency(item.goalFrequency) || 'wk'}</span>
                        </p>
                        {lastLoggedFooter}
                    </>
                );
        }
    };

    const handleDelete = (deleteItem) => {
        onDelete(deleteItem);
    };

    const normalCards = item && itemCategory && (
        <Flex wrap gap={0} style={{ position: 'relative' }}>
            {/* Side tab category label */}
            <>
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                    textAlign: 'center',
                    padding: '4px 5px',
                    backgroundColor: categoryColors[item.categoryId] || 'lightgrey',
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    borderColor: 'lightgray',
                    borderWidth: 'thin',
                    borderStyle: 'solid',
                    position: 'absolute', // Attach to the parent Flex container
                    left: 0,
                    top: 0,
                    bottom: 0,
                    marginBottom: 8,
                }}
                >
                    {itemCategory.name}
                </div>
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                    textAlign: 'center',
                    padding: '1px 1px',
                    backgroundColor: lightenColor(categoryColors[item.categoryId]) || 'lightgrey',
                    borderColor: 'lightgray',
                    borderWidth: 'thin',
                    borderStyle: 'solid',
                    position: 'absolute', // Attach to the parent Flex container
                    left: 30,
                    top: 0,
                    bottom: 0,
                    marginBottom: 8,
                }}
                >
                    {item.subcategory}
                </div>
            </>
            {/* Main card content */}
            <Flex wrap="middle" gap="middle" style={{ marginLeft: 55, flexGrow: 1 }}>
            <Card hoverable size="small" onClick={onClick} style={{ 
                marginBottom: 8, 
                minWidth: 320, 
                flexGrow: 1, 
                width: '100%', 
                borderTopRightRadius: 10, 
                borderBottomRightRadius: 10, 
                borderColor: 'lightgray', 
                borderWidth: 'thin',
                backgroundColor: highlight ? 'lightyellow' : 'white',
                cursor: 'pointer',
            }}>
                <Row gutter={8} style={{ alignItems: 'center' }}>
                    {/* Title and Progress */}
                    <Col span={8}>
                        <Title level={5}>{item.name}</Title>
                        <Progress
                            type="dashboard"
                            steps={10}
                            percent={progressPercentage}
                            trailColor="rgba(0, 0, 0, 0.06)"
                            strokeColor={getProgressColor()}
                            strokeWidth={20}
                            size="small"
                        />
                    </Col>
                    {/* Goal, Average, Last Logged */}
                    <Col span={16}>
                        {renderNormalContent()}
                    </Col>
                    {/* Improvement Statistic (since previous week, etc.) */}
                    {!isEdit && includeImprovementPercentage &&
                    <Affix offsetTop={0} style={{ position: 'absolute', top: 8, right: 8 }}>
                        <Tooltip title="Change since last week">
                        <Statistic
                            value={item.improvementDelta}
                            precision={1}
                            valueStyle={{
                                color: item.improvement ? '#3f8600' : '#cf1322',
                                fontSize: 16,
                            }}
                            prefix={item.improvement ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            suffix="%"
                            style={{ textAlign: 'center' }}
                        />
                        </Tooltip>
                    </Affix>
                    }
                    {/* Edit & Delete buttons */}
                    {isEdit &&
                    <Affix offsetTop={0} style={{ position: 'absolute', top: 8, right: 8 }}>
                        <Tooltip title="Delete">
                            <Button type="text" onClick={() => handleDelete(item)}
                                style={{ 
                                    padding: 5,
                                    marginRight: 8, 
                                    backgroundColor: (categoryColors[item.categoryId]), 
                                    borderColor: darkenColor(categoryColors[item.categoryId]),
                                }}>
                                <DeleteOutlined 
                                    style={{ 
                                        color: darkenColor(categoryColors[item.categoryId], 50),
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    </Affix>
                    }
                </Row>
            </Card>
            </Flex>
        </Flex>
    );

    const onelineCards = item && itemCategory && (
        <Flex wrap gap={0} className="oneline-card" style={{ position: 'relative'}}>
            {/* Side tab category label */}
            <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '4px 8px',
                backgroundColor: categoryColors[item.categoryId] || 'lightgrey',
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                borderColor: 'lightgray',
                borderWidth: 'thin',
                borderStyle: 'solid',
                position: 'absolute', // Attach to the parent Flex container
                left: 0,
                top: 0,
                bottom: 0,
                marginBottom: 8,
            }}
            >
                {itemCategory.name?.charAt(0).toUpperCase()}
            </div>
            <Flex wrap="middle" gap="middle" style={{ marginLeft: 23, flexGrow: 1 }}>
                <Card size="small" onClick={onClick} style={{ 
                    marginBottom: 8, 
                    flexGrow: 1, 
                    width: '100%',
                    borderTopRightRadius: 10, 
                    borderBottomRightRadius: 10, 
                    borderColor: 'lightgray', 
                    borderWidth: 'thin',
                    backgroundColor: highlight ? 'lightyellow' : 'white',
                    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer', 
                    }}>
                    <Row gutter={8} align="middle" style={{ alignItems: 'center' }}>
                    {/* Title */}
                    <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <Title level={5} style={{ margin: 0 }}>{item.name}</Title>
                    </Col>
                    {/* Progress */}
                    <Col span={10} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Progress
                        percent={progressPercentage}
                        strokeColor={getProgressColor()}
                        size="large"
                        style={{ margin: 0}}
                        />
                    </Col>
                    <Col span={5}>
                    {/* Improvement Delta */}
                    <Statistic
                            value={Math.round(item.improvementDelta)}
                            precision={0}
                            valueStyle={{
                                color: item.improvement ? '#3f8600' : '#cf1322',
                                fontSize: 14,
                            }}
                            prefix={item.improvement ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            suffix="%"
                            style={{ textAlign: 'center' }}
                        />
                    </Col>
                    </Row>
                </Card>
            </Flex>
        </Flex>
    );

    return oneLine ?  onelineCards : normalCards
}
export default TrackerItem;