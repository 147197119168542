import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { DatePicker, Row, Col, Card, Typography, Spin, Tabs } from 'antd';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import DailyActivityComponent from './components/DailyActivityComponent';
import WeeklyDashboard from './components/WeeklyDashboard';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import taskData from './mocks/mockTaskData';
import demoTaskData from '../tasks/mocks/taskData';
import demoSleepData from './mocks/sleepData.json';
import demoActivityData from './mocks/activityData.json';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const HealthDashboard = () => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_HEALTH_ENDPOINT}`,
  });

  const SleepDashboard = () => <div>Hello World: Sleep Dashboard</div>;

  const [sleepData, setSleepData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([dayjs().subtract(1, 'week'), dayjs()]);

  const [sleepAverages, setSleepAverages] = useState([
    { name: 'Total Sleep', value: 0 },
    { name: 'Deep Sleep', value: 0 },
    { name: 'Light Sleep', value: 0 },
    { name: 'REM Sleep', value: 0 },
  ]);
  const [summaryAverages, setSummaryAverages] = useState({
    avgTotalSleep: 0,
    avgTotalSteps: 0,
    avgTotalCalories: 0,
  });  

  useEffect(() => {
    // Fetch data for the demo user if in demo mode
    if(isDemoMode) {
      handleDateChange([dayjs('2024-05-01'), dayjs('2024-06-11')]);
    // Fetch data for the last week by default
    } else if (dateRange[0] && dateRange[1]) {
      handleDateChange(dateRange);
    }
  }, []);
  
  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (dates) {
      fetchData(dates[0], dates[1]);
    }
  };

  const isDemoMode = Auth.user.attributes.sub === '73ec5ccf-f055-4a23-9607-79a1f5e10484';

  const filterDataByDateRange = (data, startDate, endDate) => {
    return data.filter((entry) => {
      const entryDate = dayjs(entry.date, 'YYYY-MM-DD').tz('America/Los_Angeles');
      return entryDate.isBetween(startDate, endDate, 'day', '[]');});
  };

  const fetchData = async (startDate, endDate) => {
    // Use demo data if the user is the demo user
    setLoading(true);
    if(isDemoMode) {
      console.log('Using demo data');
      const mockSleepData = filterDataByDateRange(demoSleepData, startDate, endDate);
      setSleepData(mockSleepData);
      calculateSleepDataAverages(mockSleepData);

      const mockActivityData = filterDataByDateRange(demoActivityData, startDate, endDate);
      setActivityData(mockActivityData);
      calculateActivityDataAverages(mockActivityData);
    } else {
      // Fetch data from the API otherwise
      console.log(`Fetching data for date range: ${startDate} to ${endDate}`);
      const startDateFormatted = startDate.format('YYYY-MM-DD');
      const endDateFormatted = endDate.format('YYYY-MM-DD');
      await Promise.all([ // Fetch sleep and activity data in parallel
        fetchSleepData(startDateFormatted, endDateFormatted), 
        fetchActivityData(startDateFormatted, endDateFormatted)
      ]);
    }
    setLoading(false);
  };

  const fetchSleepData = async (startDate, endDate) => {
    setLoading(true);
    const params = { provider: 'Oura', startDate, endDate };
    const paramsString = new URLSearchParams(params).toString();
  
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const response = await api.get(`/health/daily/sleep?${paramsString}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      const data = response.data.message;
      setSleepData(data);
      calculateSleepDataAverages(data);
    } catch (error) {
      console.error('Error fetching sleep data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchActivityData = async (startDate, endDate) => {
    const params = { provider: 'Oura', startDate, endDate };
    const paramsString = new URLSearchParams(params).toString();
  
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const response = await api.get(`/health/daily/activity?${paramsString}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      const data = response.data.message;
      setActivityData(data);
      calculateActivityDataAverages(data);
    } catch (error) {
      console.error('Error fetching activity data:', error);
    }
  };  

  const calculateSleepDataAverages = (data) => {
    if(!data) return;
    const totalEntries = data.length;
    console.log('Total Sleep Entries:', totalEntries);
    const totalSleepSum = data.reduce((acc, entry) => acc + entry.totalSleep, 0);
    const deepSleepSum = data.reduce((acc, entry) => acc + entry.deepSleep, 0);
    const lightSleepSum = data.reduce((acc, entry) => acc + entry.lightSleep, 0);
    const remSleepSum = data.reduce((acc, entry) => acc + entry.remSleep, 0);
  
    const avgData = [
      { name: 'Total Sleep', value: totalSleepSum / totalEntries },
      { name: 'Deep Sleep', value: deepSleepSum / totalEntries },
      { name: 'Light Sleep', value: lightSleepSum / totalEntries },
      { name: 'REM Sleep', value: remSleepSum / totalEntries },
    ];

    avgData.avgTotalSleep = totalSleepSum / totalEntries;
  
    console.log('Sleep Averages:', avgData);
    setSleepAverages(avgData);
  };

  const calculateActivityDataAverages = (data) => {
    if (!data) return;
    const totalEntries = data.length;
    const totalStepsSum = data.reduce((acc, entry) => acc + entry.totalSteps, 0);
    const totalCaloriesSum = data.reduce((acc, entry) => acc + entry.totalCalories, 0);
  
    const averages = {
      avgTotalSteps: totalStepsSum / totalEntries || 0,
      avgTotalCalories: totalCaloriesSum / totalEntries || 0,
    };

    console.log('Summary Averages:', averages);
    setSummaryAverages(averages);
  };  

  const formatTooltipValue = (value) => {
    return value.toFixed(2);
  };

  const formatTooltipLabel = (label) => {
    return dayjs(label)
      .tz('America/Los_Angeles')
      .format('MMMM Do, YYYY (dddd)');
  };

  // returns the time as '8 Hours, 30 Minutes'
  const formatTimeDisplayHourAndMinutes = (hours) => {
    const totalMinutes = hours * 60;
    const hoursValue = Math.floor(totalMinutes / 60);
    const minutesValue = totalMinutes % 60;
    return `${hoursValue} Hours ${minutesValue.toFixed(0)} Minutes`;
  }

  const formatNumberDisplayToHaveCommas = (number) => {
    return number.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  const formatTick = (tick) => {
    return dayjs(tick).format('MMM Do');
  };   

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Main" key="1">
          {/* Header */}
          <Col justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Row>
              <Title level={2}>Health Dashboard</Title>
            </Row>
            <Row>
              <RangePicker 
                onChange={handleDateChange} 
                value={dateRange}
                // human friendly date format
                format="MMMM Do, YYYY (ddd)"
                placeholder={['Start Date', 'End Date']}
              />
            </Row>
          </Col>

          {/* Summary Cards */}
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={8}>
              <Card title="Average Daily Steps" bordered={false}>
                {loading ? <Spin /> : <Typography.Title level={3}>{formatNumberDisplayToHaveCommas(summaryAverages.avgTotalSteps)} Steps</Typography.Title>}
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Average Daily Calories" bordered={false}>
                {loading ? <Spin /> : <Typography.Title level={3}>{formatNumberDisplayToHaveCommas(summaryAverages.avgTotalCalories)} Calories</Typography.Title>}
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Average Daily Sleep" bordered={false}>
                {loading ? <Spin /> : <Typography.Title level={3}>{formatTimeDisplayHourAndMinutes(sleepAverages.filter(avg => avg.name === 'Total Sleep')[0].value)}</Typography.Title>}
              </Card>
            </Col>
          </Row>

          {/* Graphs and Charts */}
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={12}>
              <Card title="Sleep Stage Graph" style={{ width: '100%' }}>
                {loading ? (
                  <Spin />
                ) : (
                  <LineChart
                    width={800}
                    height={400}
                    data={sleepData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      label={{ value: 'Date', position: 'insideBottomLeft', offset: -5 }}
                      tickFormatter={formatTick} // Apply the custom formatter
                    />     
                    <YAxis label={{ value: 'Sleep Duration by Stage (Hours)', angle: -90, position: 'outsideLeft' }} />           
                    <Tooltip formatter={formatTooltipValue} labelFormatter={formatTooltipLabel} />
                    <Legend />
                    <Line type="monotone" name="Total Sleep" dataKey="totalSleep" stroke="#8884d8" />
                    <Line type="monotone" name="Deep Sleep" dataKey="deepSleep" stroke="#82ca9d" />
                    <Line type="monotone" name="Light Sleep" dataKey="lightSleep" stroke="#ffc658" />
                    <Line type="monotone" name="REM Sleep" dataKey="remSleep" stroke="#ff7300" />
                  </LineChart>
                )}
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Sleep Duration Averages">
              {loading ? (
                  <Spin />
                ) : (
                  <BarChart 
                    width={600} 
                    height={400} 
                    data={sleepAverages}
                    margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" label={{ value: 'Sleep Type', position: 'insideBottom', offset: -5 }} />
                    <YAxis label={{ value: 'Average Sleep Duration (hours)', angle: -90, position: 'outsideLeft' }} />
                    <Tooltip formatter={formatTooltipValue} />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                )}
              </Card>
            </Col>
          </Row>

          {/* Detailed Metrics */}
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={24}>
              <Card title="Detailed Metrics">
                {/* Placeholder for Detailed Metrics */}
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Weekly" key="2">
          <WeeklyDashboard sleepData={sleepData} activityData={activityData} taskData={isDemoMode ? demoTaskData : taskData} />
        </TabPane>
        { !isDemoMode && (
          <>
            <TabPane tab="Sleep" key="3">
              <SleepDashboard />
            </TabPane>
            <TabPane tab="Activity" key="4">
              <DailyActivityComponent activityData={activityData} />
            </TabPane>
          </>
        )}
      </Tabs>
    </div>
  );
};

export default HealthDashboard;
