import React from "react";
import classes from "./coach-styles/TextFieldBar.module.css";

interface Props {
  pushNewMessage: (content: string) => void;
  setTextAreaHeight: React.Dispatch<React.SetStateAction<number>>;
}

function TextFieldBar({ pushNewMessage, setTextAreaHeight }: Props) {
  const [content, setContent] = React.useState<string>("");

  React.useEffect(() => {
    const textarea = document.getElementById("auto-expand-textarea");
    if (!textarea) return;
    setTextAreaHeight(textarea.offsetHeight);
  }, []);

  // useEffect -- auto-scroll down textarea
  React.useEffect(() => {
    const textarea = document.getElementById("auto-expand-textarea");
    if (!textarea) return;
    textarea.scrollTop = textarea.scrollHeight;
  }, [content]);

  const sendMessage = (event?: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (content.length === 0) return;
    // if event is defined, check for
    if (event && event.key !== "Enter") return;
    if (event) {
      event.preventDefault();
    }
    pushNewMessage(content);

    setContent("");
  };

  return (
    <div className={classes.sendBar}>
      <textarea
        className={classes.sendTextField}
        placeholder="Ask your coach..."
        id="auto-expand-textarea"
        onChange={(e) => {
          setContent(e.target.value);
        }}
        onKeyDown={sendMessage}
        value={content}
      ></textarea>
      <button onClick={() => sendMessage()}>Send</button>
    </div>
  );
}

export default TextFieldBar;
