import React from "react";
import classes from "./coach-styles/NewCoachPane.module.css";
import type { Coach, Thread } from "./coach-utils/corpath-types";
import { coachNameMapping } from "./coach-utils/corpath-types";
import { coaches } from "./coach-utils/corpath-types";
import { useSelector } from 'react-redux';
import type { RootState } from '../../../store'

import * as api from './coach-utils/api'

interface Props {
  setThreads: React.Dispatch<React.SetStateAction<Thread[]>>;
}

function NewCoachPane({ setThreads }: Props) {
  const {userId} = useSelector((state: RootState) => state.app);

  function addNewThread(coach: Coach) {
    api.createThread(userId!, coach.type)
      .then((res) => {
        if(res.status === 'error') return new Error()

        const newThread: Thread = res.savedThread;
        setThreads((prev) => [newThread, ...prev]);
      })
      .catch((err) => console.error(err));
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Start a New Conversation</h2>
      <div className={classes.coachTiles}>
        {coaches.map((coach) => {
          return (
            <div
              key={coach.name}
              className={classes.tile}
              onClick={() => addNewThread(coach)}
              style={{ backgroundColor: coach.color }}
            >
              <div className={classes.imgBox}>
                <img src={coach.img} alt='profile-image'/>
              </div>
              <h3 className={classes.coachName}>{coachNameMapping[coach.type]}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NewCoachPane;
