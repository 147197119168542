import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import isEqual from 'lodash/isEqual';
import { LinkedAccount, ServiceType, ProviderType } from '../../../models'; // Assuming ProviderType is defined in the models
import { FaGoogle, FaMicrosoft, FaSlack, FaApple } from 'react-icons/fa';
import { Modal, List, Button, Menu, Dropdown, Tooltip, Checkbox, Alert, Spin } from 'antd';
import {
  DownOutlined,
  MailOutlined,
  CalendarOutlined,
  MessageOutlined,
  VideoCameraOutlined,
  HeartOutlined,
  ContactsOutlined,
  HarmonyOSOutlined
} from '@ant-design/icons';

const LinkedAccountsComponent = ({ userId, visible, onClose }) => {
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [previousLinkedAccounts, setPreviousLinkedAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAuth, setLoadingAuth] = useState(false); // State for managing loading spinner during OAuth window loading
  const [serviceSelectionVisible, setServiceSelectionVisible] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [successBannerVisible, setSuccessBannerVisible] = useState(false); // State for success banner
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Prevent showing successful link account banner on initial load
  const [unlinkConfirmVisible, setUnlinkConfirmVisible] = useState(false);
  const [accountToUnlink, setAccountToUnlink] = useState(null);

  useEffect(() => {
    if (visible) {
      fetchLinkedAccounts();
    }
  }, [visible]);

  const isDemoMode = Auth.user.attributes.sub === '73ec5ccf-f055-4a23-9607-79a1f5e10484';

  let demoLinkedAccounts = [
    {
      id: '1',
      userId: '73ec5ccf-f055-4a23-9607-79a1f5e10484',
      provicerId: 'testUserGoogleId',
      providerType: ProviderType.GOOGLE,
      userEmail: 'corpathtestuser@gmail.com',
      serviceTypes: [ServiceType.EMAIL, ServiceType.CALENDAR],
    },
    {
      id: '2',
      userId: '73ec5ccf-f055-4a23-9607-79a1f5e10484',
      providerId: 'testUserOuraId',
      providerType: ProviderType.OURA,
      userEmail: 'corpathtestuser-personal@gmail.com',
      serviceTypes: [ServiceType.HEALTH]
    },
  ];

  const fetchLinkedAccounts = async () => {
    try {
      const accounts = isDemoMode ? demoLinkedAccounts : await DataStore.query(LinkedAccount, (c) => c.userId.eq(userId));
  
      // Check if the linked accounts have changed using deep comparison
      if (!isEqual(accounts, previousLinkedAccounts)) {
        setPreviousLinkedAccounts(accounts);
        if (!isInitialLoad) {
          showSuccessBanner('Linked accounts updated successfully.');
        }
        setIsInitialLoad(false); // Set initial load to false after the first load
      }
      
      setLinkedAccounts(accounts);
    } catch (error) {
      console.error('Failed to fetch linked accounts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnlinkAccount = (account) => {
    setAccountToUnlink(account);
    setUnlinkConfirmVisible(true);
  };

  const confirmUnlink = async () => {
    if (!accountToUnlink) return;

    if (isDemoMode) {
      setLinkedAccounts(prevAccounts => prevAccounts.filter(account => account.id !== accountToUnlink.id));
      showSuccessBanner('Account unlinked successfully.');
      setUnlinkConfirmVisible(false);
      setAccountToUnlink(null);
      return;
    }

    try {
      const accountToDelete = await DataStore.query(LinkedAccount, accountToUnlink.id);

      if (!accountToDelete) {
        throw new Error('Account not found');
      }

      await DataStore.delete(accountToDelete);

      setLinkedAccounts(prevAccounts => prevAccounts.filter(account => account.id !== accountToUnlink.id));
      showSuccessBanner('Account unlinked successfully.');
      fetchLinkedAccounts();
    } catch (error) {
      console.error('Error unlinking account:', error);
      // Optionally show an error message to the user
    } finally {
      setUnlinkConfirmVisible(false);
      setAccountToUnlink(null);
    }
  };

  const handleLinkAccount = (providerType) => {
    setSelectedProvider(providerType);
    setSelectedServices(getSupportedServices(providerType));
    setServiceSelectionVisible(true);
  };

  const handleServiceSelectionChange = (service, checked) => {
    setLoadingAuth(true); // Show spinner while loading the OAuth window
    setSelectedServices(prevServices =>
      checked
        ? [...prevServices, service]
        : prevServices.filter(s => s !== service)
    );
  };

  const showSuccessBanner = (message) => {
    setSuccessMessage(message);
    setSuccessBannerVisible(true);
    setTimeout(() => {
      setSuccessBannerVisible(false);
    }, 5000); // Hide the banner after 5 seconds
  };  

  const handleServiceSelectionContinue = async () => {
    if(isDemoMode) {
      // In demo mode, we don't have OAuth flow, so we can skip the 3rd party auth and just show the AuthComplete page instead
      const authCompleteUrl = `${window.location.origin}/auth-complete`;
      const authCompleteWindow = openCenteredWindow(authCompleteUrl, 'authCompleteWindow', 700, 800);
      const checkWindowClosed = setInterval(() => {
        if (authCompleteWindow.closed) {
          clearInterval(checkWindowClosed);
          setServiceSelectionVisible(false);
          setLoadingAuth(false);
          showSuccessBanner(`Successfully linked ${selectedProvider} account`);
        }
      }, 1000); // Check every second if the window is closed
      return;
    }

    const requestUrl = `${process.env.REACT_APP_AUTH_ENDPOINT}/auth`;
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
            // include Coginito tokens in the request headers
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ provider: selectedProvider, services: selectedServices }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to initiate OAuth flow');
      }
  
      const responseData = await response.json();
      const oauthUrl = responseData.oauthUrl;
      const oauthWindow = openCenteredWindow(oauthUrl, 'oauthWindow', 700, 800);

      window.addEventListener('message', (event) => {
        if (event.origin === window.location.origin && event.data === 'oauth-complete') {
          showSuccessBanner(`Successfully linked ${selectedProvider} account`);
          oauthWindow.close();
          setLoadingAuth(false);
        } // Add an event listener to listen for the 'oauth-complete' message when the OAuth flow is complete
      });
  
      const checkWindowClosed = setInterval(() => {
        if (oauthWindow.closed) {
          clearInterval(checkWindowClosed);
          fetchLinkedAccounts();
          setServiceSelectionVisible(false);
          setLoadingAuth(false);
        }
      }, 1000); // Check every second if the window is closed
    } catch (error) {
      console.error('Error initiating OAuth flow:', error);
    }
  };

  const openCenteredWindow = (url, title, width, height) => {
    // Calculate the position of the window so that it centers on the screen
    const left = (window.screen.width - width) / 2;
    // Top of the screen
    const top = 0;
  
    // Open the new window
    const newWindow = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
  
    // Return the new window object
    return newWindow;
  };

  const serviceConfig = {
    [ServiceType.CONTACTS]: {
        icon: <ContactsOutlined />,
        name: "Contacts",
        description: "Auto-fill when typing email addresses, and suggest teammates."
    },
    [ServiceType.EMAIL]: {
      icon: <MailOutlined />,
      name: "Email",
      description: "Send templated emails to clients, and send reminders before booked meetings."
    },
    [ServiceType.CALENDAR]: {
      icon: <CalendarOutlined />,
      name: "Calendar",
      description: "Schedule and move tasks, create meetings, share availabilities, sync events, and block out times."
    },
    [ServiceType.CHAT]: {
      icon: <MessageOutlined />,
      name: "Chat",
      description: "Send messages and communicate with your team."
    },
    [ServiceType.VIDEO]: {
      icon: <VideoCameraOutlined />,
      name: "Video",
      description: "Join video calls and meetings."
    },
    [ServiceType.HEALTH]: {
      icon: <HeartOutlined />,
      name: "Health",
      description: "Sync health data and track your fitness."
    },
  };

  /**
   * CONTROLS USER ACCOUNT LINKING OPTIONS
   * This needs to stay in alignment with providers and services
   * supported by the backend - this can be made into a service call.
   */
  const providerConfig = {
    [ProviderType.GOOGLE]: {
      name: 'Google',
      icon: <FaGoogle />,
      supportedServices: [ServiceType.EMAIL, ServiceType.CALENDAR],
    },
    [ProviderType.MICROSOFT]: {
      name: 'Microsoft',
      icon: <FaMicrosoft />,
      supportedServices: [ServiceType.CONTACTS, ServiceType.EMAIL, ServiceType.CALENDAR, ServiceType.CHAT, ServiceType.VIDEO],
    },
    [ProviderType.OURA]: {
        name: 'Oura Ring',
        icon: <HarmonyOSOutlined />,
        supportedServices: [ServiceType.HEALTH],
    },
    [ProviderType.SLACK]: {
      name: 'Slack',
      icon: <FaSlack />,
      supportedServices: [ServiceType.CHAT],
    },
    [ProviderType.APPLE]: {
      name: 'Apple',
      icon: <FaApple />,
      supportedServices: [ServiceType.CONTACTS, ServiceType.CALENDAR, ServiceType.HEALTH],
    },
  };

  const getProviderIcon = (providerType) => {
    if (!providerType) return null;
    return providerConfig[providerType]?.icon || null;
  };

  const getSupportedServices = (providerType) => {
    if (!providerType) return [];
    const supportedServices = providerConfig[providerType]?.supportedServices || [];
    return supportedServices;
  };

  const getServiceIcon = (serviceType) => {
    return serviceConfig[serviceType]?.icon || null;
  };

  // Dynamically generate the menu based on the providerConfig
  const menu = (
    <Menu>
        {Object.keys(providerConfig).map((providerType) => (
            <Menu.Item key={providerType} onClick={() => handleLinkAccount(providerType)}>
                <Tooltip title={providerConfig[providerType].name}>
                    <span style={{ marginRight: 8 }}>{getProviderIcon(providerType)}</span>
                </Tooltip>
                <span>{providerConfig[providerType].name}</span>
            </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div style={{paddingTop: 0}}>
    {successBannerVisible && (
        <Alert message={successMessage} type="success" showIcon style={{ marginBottom: '16px' }} />
    )}
    {loading ? (
        <p>Loading...</p>
      ) : (
        <Spin spinning={loadingAuth} style={{paddingTop: 0}}>
          <List
              itemLayout="horizontal"
              dataSource={linkedAccounts}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button 
                      type="text" 
                      danger 
                      onClick={() => handleUnlinkAccount(item)}
                    >
                      Unlink
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    description={
                      <span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title={item.providerType}>
                            <span style={{ marginRight: 8 }}>{getProviderIcon(item.providerType)}</span>
                          </Tooltip>
                          <b>{item.userEmail}</b>
                          {item.serviceTypes.map((type) => (
                            <Tooltip key={type} title={serviceConfig[type]?.name || type}>
                              <span style={{ marginLeft: 8 }}>{getServiceIcon(type)}</span>
                            </Tooltip>
                          ))}
                        </div>
                      </span>
                    }
                  />
                </List.Item>
              )}
              // renderItem={(item) => (
              //     <List.Item >
              //       <List.Item.Meta
              //           description={
              //               <span>
              //                   <div style={{ display: 'flex', alignItems: 'center' }}>
              //                       <Tooltip title={item.providerType}>
              //                         <span style={{ marginRight: 8 }}>{getProviderIcon(item.providerType)}</span>
              //                       </Tooltip>
              //                       <b>{item.userEmail}</b>
              //                       {item.serviceTypes.map((type) => (
              //                           <Tooltip key={type} title={serviceConfig[type]?.name || type}>
              //                               <span style={{ marginLeft: 8 }}>{getServiceIcon(type)}</span>
              //                           </Tooltip>
              //                       ))}
              //                   </div>
              //               </span>
              //           }
              //           />
              //     </List.Item>
              // )}
          />
        </Spin>
      )}
    <Spin spinning={loadingAuth}>
      {/* Align the Dropdown to the right */}
      <Dropdown overlay={menu} menu={menu} trigger={['click']}>
        <Button type="primary" style={{ marginTop: 16 }}>
          + Add Account <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        title={`Select Services to Enable for ${selectedProvider}`}
        open={serviceSelectionVisible}
        onCancel={() => setServiceSelectionVisible(false)}
        onOk={handleServiceSelectionContinue}
        okText="Continue"
        destroyOnClose
        >
        <List
            dataSource={getSupportedServices(selectedProvider || '')}
            renderItem={(service) => (
            service && (
                <List.Item style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    checked={selectedServices.includes(service)} // Check if the service is in the selectedServices list
                    style={{ marginRight: 16 }}
                    onChange={(e) => handleServiceSelectionChange(service, e.target.checked)}
                />
                <Tooltip title={serviceConfig[service]?.name || service}>
                    <span style={{ marginRight: 8 }}>{getServiceIcon(service)}</span>
                </Tooltip>
                <div style={{ flex: 1 }}>
                    <span><b>{serviceConfig[service]?.name || service}</b></span>
                    <p style={{ margin: 0 }}>{serviceConfig[service]?.description || ''}</p>
                </div>
                </List.Item>
            )
            )}
        />
        </Modal>

        <Modal
          title="Confirm Unlink"
          visible={unlinkConfirmVisible}
          onOk={confirmUnlink}
          onCancel={() => {
            setUnlinkConfirmVisible(false);
            setAccountToUnlink(null);
          }}
          okText="Unlink"
          cancelText="Cancel"
        >
          <p>Are you sure you want to unlink this account?</p>
          {accountToUnlink && (
            <p>
              <b>{accountToUnlink.userEmail}</b> ({accountToUnlink.providerType})
            </p>
          )}
        </Modal>
      </Spin>
    </div>
  );
};

export default LinkedAccountsComponent;
