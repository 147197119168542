// Core React Imports
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTasks, setTasks } from '../../store/actions';
import { DataStore } from 'aws-amplify';
import TaskTable from './components/TaskTable';
import TaskModal from './components/TaskModal'; // Import TaskModal
import { uniqueId } from 'lodash';
import { Button, Switch } from 'antd';
import {
  // SourceType,
  Task,
} from '../../models';
import KanbanBoard from './components/KanbanBoard';

const TaskListMain = () => {
    const dispatch = useDispatch();
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [isKanbanView, setIsKanbanView] = useState(false); // New state for view toggle
    const { theme, userId, isDemoMode, tasks, taskLoading } = useSelector((state) => state.app);
    
    // Fetch tasks from API
    useEffect(() => {
        dispatch(fetchTasks(userId));
    }, [dispatch, userId]);

    const handleOpenModal = () => {
        setIsTaskModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsTaskModalOpen(false);
    };

   const createNewTask = async (taskData) => {
    if(isDemoMode) {
      console.info('Demo mode: Database task creation disabled');
      dispatch(setTasks([...tasks, {...taskData, userId, id: uniqueId()}]));
      return;
    }

    try {
      const newTask = new Task({
        ...taskData, // Spread the taskData to include title, content, status,priority, energydelta, description
        userId,
        // sourceType: SourceType.USER,
      });

      await DataStore.save(newTask);
      dispatch(fetchTasks(userId));
    } catch (error) {
      console.error('Error creating new task:', error);
    }
  };

  const handleViewToggle = (checked) => {
      setIsKanbanView(checked);
  };

    return (
        <div style={{ padding: '24px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <Button 
                    type="primary" 
                    onClick={handleOpenModal} 
                    style={{ backgroundColor: theme.buttonBackgroundColor, color: theme.buttonTextColor }}
                >
                    Add New Task
                </Button>
                <div>
                    <span style={{ marginRight: '8px', color: theme.textColor }}>Table</span>
                    <Switch 
                        checked={isKanbanView}
                        onChange={handleViewToggle}
                    />
                    <span style={{ marginLeft: '8px', color: theme.textColor }}>Kanban</span>
                </div>
            </div>
            
            {isKanbanView ? (
                <KanbanBoard tasks={tasks} setTasks={setTasks} loading={taskLoading} />
            ) : (
                <TaskTable tasks={tasks} setTasks={setTasks} loading={taskLoading} />
            )}

            <TaskModal
                isVisible={isTaskModalOpen}
                onCancel={handleCloseModal}
                onSubmit={createNewTask}
                isEditMode={false}
            />
        </div>
    );
};

export default TaskListMain;
