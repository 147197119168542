import { DataStore } from '@aws-amplify/datastore';
import {Thread as ModelThread, CoachingCategory, Sender, Message as ModelMessage} from '../../../../../src/models'

import type {Thread, Message, GPTCallBody} from '../coach-utils/corpath-types'
import {API} from 'aws-amplify'


export async function gptCall(body: GPTCallBody) {

  return API.post('chatGPT', '/chat', {
    body,
  })
  .then(res => {
    console.log("/chat response\n");
    console.log(res.gptResponse);
    return res.gptResponse
  })
  .catch(err => {
    return Promise.reject(err?.response?.data)
  })
}

export async function createThread(userId: string, threadType: CoachingCategory): Promise<{status: 'ok', savedThread: Thread} | {status: 'error', error: any}> {
  const newThread = new ModelThread({
    userId,
    threadType,
  });

  try {
    const savedThread = await DataStore.save(newThread);
    return {status: 'ok', savedThread: convertToPlainThreads([savedThread])[0]}
  } catch (error) {
    // return JSON.stringify(error);
    return {status: 'error', error}
  }
}

export async function updateThread(threadId: string, updatedData: {summarizedLastQ: string; lastR: string}): Promise<{status: 'ok', savedThread: Thread} | {status: 'error', error: any}> {
  try {
      // Fetch the existing post
      const originalThread = await DataStore.query(ModelThread, threadId);

      if (originalThread) {
          // Create an updated post object
          const updatedThread = ModelThread.copyOf(originalThread, (draft) => {
              draft.lastQuestion = updatedData.summarizedLastQ; // Update title
              draft.lastResponse = updatedData.lastR.slice(0,40); // Update content
              // Update other fields as necessary
          });

          // Save the updated post
          const savedThread = await DataStore.save(updatedThread);
          console.log('Thread updated successfully:', updatedThread);
          return {status: "ok",  savedThread: convertToPlainThreads([savedThread])[0]}
      } else {
        return Promise.reject({status: "error", error: "Post not found"})
      }
  } catch (error) {
    console.error('Error updating post:', error);
    return Promise.reject({status: "error", error})
  }
}

export async function getThreadsByUserId(userId: string): Promise<{status: 'ok', threads: Thread[]} | {status: 'error', error: any}> {
  try {
    const threads = await DataStore.query(ModelThread, t => t.userId.eq(userId));
    console.log(`All Threads with userId ${userId}:\n`, threads);
    return {status: 'ok', threads: threads.length === 0 ? [] : convertToPlainThreads(threads)};
  } catch (error) {
    console.error("Error retrieving threads by userId:", error);
    return {status: 'error', error}
  }
}
export async function getThreadById(threadId: string): Promise<{status: 'ok', thread: Thread} | {status: 'error', error: any}> {
  try {
    const thread = await DataStore.query(ModelThread, t => t.id.eq(threadId));
    return {status: 'ok', thread: convertToPlainThreads([thread[0]])[0]};
    // return thread[0]
  } catch (error) {
    return {status: 'error', error};
  }
}
export async function getAllThreads(): Promise<{status: 'ok', threads: Thread[]} | {status: 'error', error: any}> {
  try {
    const threads = await DataStore.query(ModelThread);
    return {status: 'ok', threads: convertToPlainThreads(threads)};
  } catch (error) {
    console.error("Error retrieving all threads:", error);
    return {status: 'error', error}
  }
}

export async function clearAllThreads(): Promise<{status: 'ok', message: string} | {status: 'error', error: any}> {
  try {
    const threads = await DataStore.query(ModelThread);
    console.log('threads to delete:', threads);
      // Delete each record
      for (const thread of threads) {
        await deleteMessagesByThreadId(thread.id);
        await DataStore.delete(thread);
      }
      return {status: 'ok', message: 'deleted all successfully'};
  } catch (error) {
    return {status: 'error', error};
  }
}
export async function deleteThreadById(threadId: string): Promise<{status: 'ok', message: string} | {status: 'error', message: string, error: any}> {
  try {
    const thread = await DataStore.query(ModelThread, t => t.id.eq(threadId));
    await deleteMessagesByThreadId(threadId);
    await DataStore.delete(ModelThread, t => t.id.eq(thread[0].id));

    return {status: 'ok', message: 'deleted thread + messages successfully'};
  } catch (error) {
    return Promise.reject({status: 'error', message: "Error deleting all threads or messages OF threads", error});
  }
}


export async function createMessage({content, sender, threadID}:{content: string, sender: Sender, threadID: string}): Promise<{status: 'ok', savedMessage: Message} | {status: 'error', error: any}> {
  const newMessage = new ModelMessage({
    content,
    sender,
    threadID
  });

  try {
    const savedMessage = await DataStore.save(newMessage);
    console.log("Message created successfully:", savedMessage);
    return {status: 'ok', savedMessage: convertToPlainMessages([savedMessage])[0]};
  } catch (error) {
    console.error("Error saving Message:", error);
    return {status: 'error', error};
  }
}

export async function getAllMessages(): Promise<{status: 'ok', messages: Message[]} | {status: 'error', error: any}> {
  try {
    const messages = await DataStore.query(ModelMessage);
    return {status: 'ok', messages: convertToPlainMessages(messages)};
  } catch (error) {
    console.error("Error retrieving Messages:", error);
    return {status: 'error', error}
  }
}
export async function clearAllMessages(): Promise<{status: 'ok', message: string} | {status: 'error', error: any}> {
  try {
    const messages = await DataStore.query(ModelMessage);
    console.log('threads to delete:', messages);
      // Delete each record
      for (const message of messages) {
        await DataStore.delete(message);
      }
      return {status: 'ok', message: 'deleted all messages successfully'};
  } catch (error) {
    return {status: 'error', error};
  }
}

export async function getMessagesByThreadId(threadId: string): Promise<{status: 'ok', messages: Message[]} | {status: 'error', error: any}> {
  try {
    const messages = await DataStore.query(ModelMessage, m => m.threadID.eq(threadId));
    return {status: 'ok', messages: messages.length === 0 ? [] : convertToPlainMessages(messages)};
  } catch (error) {
    console.error("Error retrieving messages by threadId:", error);
    return {status: 'error', error}
  }
}

function convertToPlainThreads(threads: ModelThread[]): Thread[] {
  return threads.map(t => {
    return {
      userId: t.userId,
      id: t.id,
      createdAt: t.createdAt ?? undefined,
      updatedAt: t.updatedAt ?? undefined,
      threadType: t.threadType,
      lastQuestion: t.lastQuestion ?? '',
      lastResponse: t.lastResponse ?? ''
    }
  })
}
function convertToPlainMessages(messages: ModelMessage[]): Message[] {
  return messages.map(m => {
    return {
      threadID: m.threadID,
      id: m.id,
      createdAt: m.createdAt ?? undefined,
      content: m.content ?? '',
      sender: m.sender === Sender.AI_COACH ? Sender.AI_COACH : Sender.USER,
    }
  })
}

export async function deleteMessagesByThreadId(threadId: string): Promise<{status: 'ok', message: string} | {status: 'error', error: any}> {
  try {
    const threadMessages = await DataStore.query(ModelMessage, m => m.threadID.eq(threadId));
      // Delete each record
      for (const record of threadMessages) {
        await DataStore.delete(record);
      }
      return {status: 'ok', message: `deleted all messages for threadId ${threadId} successfully`};
  } catch (error) {
    return {status: 'error', error};
  }
}


