import { EnergyImpact, Priority, TaskStatus } from '../../models';
import { formatTimeDisplayHourAndMinutes } from '../../app/utils';

export const taskStatusLabels = {
    [TaskStatus.NOT_STARTED]: 'Not Started',
    [TaskStatus.IN_PROGRESS]: 'In Progress',
    [TaskStatus.COMPLETED]: 'Completed',
    [TaskStatus.BLOCKED]: 'Blocked',
    [TaskStatus.PENDING_APPROVAL]: 'Pending Approval',
    [TaskStatus.DRAFT]: 'Draft',
  };

// sorting order for task status
export const statusOrder = [
    TaskStatus.PENDING_APPROVAL,  // show these first
    'Not Set',
    TaskStatus.BLOCKED,
    TaskStatus.IN_PROGRESS,
    TaskStatus.NOT_STARTED,
    TaskStatus.DRAFT,
    TaskStatus.COMPLETED,
];

// sorting order for task priority
export const priorityOrder = [
    'Not Set',
    Priority.HIGH,
    Priority.MEDIUM,
    Priority.LOW
];

// sorting order for energy impact
export const energyImpactOrder = [
    EnergyImpact.RECHARGING,
    EnergyImpact.NO_DIFFERENCE,
    EnergyImpact.SLIGHTLY_DRAINING,
    EnergyImpact.VERY_DRAINING,
    'Not Set'
];

export const formatStatusName = (key) => {
    return key.toLowerCase().replace(/_/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
};

export const getTaskSizeColor = (size) => {
    const colors = {
      XS: 'blue',
      S: 'green',
      M: 'orange',
      L: 'red',
      XL: 'purple',
    };
    return colors[size];
};

export const getTaskStatusColor = (status) => {
    switch (status) {
        case TaskStatus.PENDING_APPROVAL:
            return '#ffa35f';   // orange
        case 'QUEUED':  // purple
            return '#00bcd4';
        case TaskStatus.IN_PROGRESS:
            return '#9c27b0';   // cyan
        case TaskStatus.COMPLETED:
            return '#00c9ac';   // green
        case TaskStatus.NOT_STARTED:
            return '#007bff';   // blue
        case TaskStatus.BLOCKED:
            return '#ff4d4f';   // red
        case TaskStatus.DRAFT:
            return '#333333E0'; // dark gray
        default:
            return '#000000)'; // Default color if status doesn't match
    }
};

export const sortTasks = (tasks, criterion, order, showNotSetLast = false) => {
    return [...tasks].sort((a, b) => {
      let result = 0;
      switch (criterion) {
        case 'priority':
          // count no priority set as lowest
          if(showNotSetLast) {
            if(!a.priority && b.priority) {
                result = 1;
                break;
            } else if (a.priority && !b.priority) {
                result = -1;
                break;
            } 
          }
          result = priorityOrder.indexOf(a.priority) - priorityOrder.indexOf(b.priority);
          break;
        case 'dueDate':
          const dateA = new Date(a.dueDate).getTime() || new Date(0).getTime();
          const dateB = new Date(b.dueDate).getTime() || new Date(0).getTime();
          result = dateA - dateB;
          break;
        case 'duration':
          result = a.duration - b.duration;
          break;
        case 'energyImpact':
          result = energyImpactOrder.indexOf(a.energyImpact) - energyImpactOrder.indexOf(b.energyImpact);
          break;
        case 'source':
          result = a.source - b.source;
          break;
        default:
          result = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
          break;
      }
      return order === 'ascend' ? result : -result;
    });
};

export const groupTasks = (tasks, criterion, projectNamesOptions=[]) => {
    const grouped = tasks.reduce((acc, task) => {
      let key;
      switch (criterion) {
        case 'priority':
          key = task.priority || 'Not Set';
          break;
        case 'dueDate':
          key = new Date(task.dueDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            weekday: 'short',
          });
          break;
        case 'duration':
          key = task.duration ? formatTimeDisplayHourAndMinutes(task.duration) : 'Not Set';
          break;
        case 'energyImpact':
          key = task.energyImpact || 'Not Set';
          break;
        case 'source':
          key = task.source || 'Not Set';
          break;
        case 'feature':
          key = getFeatureName(projectNamesOptions, task.taskGroupId) || 'Not Set';
          break;
        case 'size':
          key = task.sizeEstimate || 'Not Set';
          break;
        default:
          key = task.status || 'Not Set';
          break;
      }
      if (!acc[key]) acc[key] = [];
      acc[key].push(task);
      return acc;
    }, {});
    return grouped;
};

export const handleDragEnd = (event, setColumns) => {
    const { active, over } = event;
    if (active.id !== over.id) {
        setColumns((items) => {
            const oldIndex = items.findIndex((item) => item.key === active.id);
            const newIndex = items.findIndex((item) => item.key === over.id);
            const newItems = [...items];
            const [reorderedItem] = newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, reorderedItem);
            return newItems;
        });
    }
};

export const formatDuration = (minutes) => {
    if (!minutes) return null;
    if (minutes >= 1440) return `${Math.floor(minutes / 1440)} days`;
    if (minutes >= 60) return `${Math.floor(minutes / 60)} hours`;
    return `${minutes} minutes`;
};

export const getFeatureName = (projectNamesOptions, taskGroupId) => {
    return projectNamesOptions.find(p => p.feature.some(f => f.id === taskGroupId))?.feature.find(f => f.id === taskGroupId)?.name || '';
}