import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const AuthComplete = () => {
  useEffect(() => {
    const isDemoMode = Auth.user.attributes.sub === '73ec5ccf-f055-4a23-9607-79a1f5e10484';
    if(isDemoMode) {
      return;
    }

    // Notify the parent window that the auth process is complete
    if (window.opener) {
      window.opener.postMessage('oauth-complete', window.location.origin);
      console.log('Sending oauth-complete message to parent window');
      setTimeout(() => {
        window.close();
      }, 1000); // Delay the window close to ensure the message is sent
    }
  }, []);

  return <div>Authentication Complete. Your account was succesfully linked. You can close this window.</div>;
};

export default AuthComplete;
